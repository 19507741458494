@charset "UTF-8";
.dropdown-toggle::after {
  display: none !important;
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-orange: #ffa400;
  --bs-red: #ff5959;
  --bs-green: #00c4b3;
  --bs-dark-gray: #4d4d4d;
  --bs-orange-dark: #b07203;
  --bs-orange-deep: #dc8d00;
  --bs-orange-fade: #fcbd4d;
  --bs-orange-light: #fef2e1;
  --bs-red-dark: #ab191a;
  --bs-red-deep: #dd3e3f;
  --bs-red-fade: #fb8384;
  --bs-red-light: #f6e8e9;
  --bs-green-dark: #074746;
  --bs-green-deep: #098a7f;
  --bs-green-fade: #3addcf;
  --bs-green-light: #e0ebeb;
  --bs-blue-dark: #0b2e44;
  --bs-blue-deep: #2e6587;
  --bs-blue-fade: #79b8e8;
  --bs-blue-light: #c9e8ff;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #4990c6;
  --bs-secondary: #6c757d;
  --bs-success: #00c4b3;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #ff5959;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 73, 144, 198;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 0, 196, 179;
  --bs-info-rgb: 23, 162, 184;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 255, 89, 89;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #1d3a4f;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #004e48;
  --bs-info-text-emphasis: #09414a;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #662424;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #dbe9f4;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #ccf3f0;
  --bs-info-bg-subtle: #d1ecf1;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #ffdede;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #b6d3e8;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #99e7e1;
  --bs-info-border-subtle: #a2dae3;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #ffbdbd;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 18px;
  --bs-body-font-family: "Noto Sans TC", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #4d4d4d;
  --bs-body-color-rgb: 77, 77, 77;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(77, 77, 77, 0.75);
  --bs-secondary-color-rgb: 77, 77, 77;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(77, 77, 77, 0.5);
  --bs-tertiary-color-rgb: 77, 77, 77;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #4d4d4d;
  --bs-link-color-rgb: 77, 77, 77;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #ffa400;
  --bs-link-hover-color-rgb: 255, 164, 0;
  --bs-code-color: #d63384;
  --bs-highlight-color: #4d4d4d;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(73, 144, 198, 0.25);
  --bs-form-valid-color: #00c4b3;
  --bs-form-valid-border-color: #00c4b3;
  --bs-form-invalid-color: #ff5959;
  --bs-form-invalid-border-color: #ff5959;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #92bcdd;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #66dcd1;
  --bs-info-text-emphasis: #74c7d4;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ff9b9b;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #0f1d28;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #002724;
  --bs-info-bg-subtle: #052025;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #331212;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #2c5677;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #00766b;
  --bs-info-border-subtle: #0e616e;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #993535;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #92bcdd;
  --bs-link-hover-color: #a8c9e4;
  --bs-link-color-rgb: 146, 188, 221;
  --bs-link-hover-color-rgb: 168, 201, 228;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #66dcd1;
  --bs-form-valid-border-color: #66dcd1;
  --bs-form-invalid-color: #ff9b9b;
  --bs-form-invalid-border-color: #ff9b9b;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 10px 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0px;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 10px;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 5px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 5px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 10px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 10px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 20px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 20px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 30px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 30px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 40px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 40px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 80px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 80px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 5px;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 5px;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 10px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 10px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 20px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 20px;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 30px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 30px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 40px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 40px;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 80px;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 5px;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 5px;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 10px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 10px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 20px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 20px;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 30px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 30px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 40px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 40px;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 80px;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 5px;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 5px;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 10px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 10px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 20px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 20px;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 30px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 30px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 40px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 40px;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 80px;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 5px;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 5px;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 10px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 10px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 20px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 20px;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 30px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 30px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 80px;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 5px;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 5px;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 10px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 10px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 20px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 20px;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 30px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 30px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 80px;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 80px;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 10px;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #dbe9f4;
  --bs-table-border-color: #afbac3;
  --bs-table-striped-bg: #d0dde8;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c5d2dc;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cbd8e2;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #ccf3f0;
  --bs-table-border-color: #a3c2c0;
  --bs-table-striped-bg: #c2e7e4;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8dbd8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bde1de;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #d1ecf1;
  --bs-table-border-color: #a7bdc1;
  --bs-table-striped-bg: #c7e0e5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcd4d9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1dadf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #ffdede;
  --bs-table-border-color: #ccb2b2;
  --bs-table-striped-bg: #f2d3d3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6c8c8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #eccdcd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292929;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid #D8D8D8;
  border-radius: var(--bs-border-radius);
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #292929;
  background-color: var(--bs-body-bg);
  border-color: #ffa400;
  outline: 0;
  box-shadow: none, rgba(5, 41, 85, 0.062745098) 0px 0px 6px;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #D8D8D8;
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #292929;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(1px * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(1px * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(1px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(1px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(1px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(1px * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(1px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(1px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292929;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #D8D8D8;
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #ffa400;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 1px rgba(73, 144, 198, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #292929;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #ffa400;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(73, 144, 198, 0.25);
}
.form-check-input:checked {
  background-color: #4990c6;
  border-color: #4990c6;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #4990c6;
  border-color: #4990c6;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffa400'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, rgba(5, 41, 85, 0.062745098) 0px 0px 6px;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, rgba(5, 41, 85, 0.062745098) 0px 0px 6px;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #4990c6;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c8deee;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #4990c6;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c8deee;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(1px * 2));
  min-height: calc(3.5rem + calc(1px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292929;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid #D8D8D8;
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 2.5px 5px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c4b3' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: none, 0 0 0 1px rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c4b3' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 1px rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 1px rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 2.5px 5px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5959'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5959' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: none, 0 0 0 1px rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5959'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5959' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 1px rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 1px rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 15px;
  --bs-btn-padding-y: 6px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0px rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #4990c6;
  --bs-btn-border-color: #4990c6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #64a1cf;
  --bs-btn-hover-border-color: #5b9bcc;
  --bs-btn-focus-shadow-rgb: 62, 122, 168;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #6da6d1;
  --bs-btn-active-border-color: #5b9bcc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #4990c6;
  --bs-btn-disabled-border-color: #4990c6;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #00c4b3;
  --bs-btn-border-color: #00c4b3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #26cdbe;
  --bs-btn-hover-border-color: #1acabb;
  --bs-btn-focus-shadow-rgb: 0, 167, 152;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #33d0c2;
  --bs-btn-active-border-color: #1acabb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #00c4b3;
  --bs-btn-disabled-border-color: #00c4b3;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3ab0c3;
  --bs-btn-hover-border-color: #2eabbf;
  --bs-btn-focus-shadow-rgb: 20, 138, 156;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #45b5c6;
  --bs-btn-active-border-color: #2eabbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #17a2b8;
  --bs-btn-disabled-border-color: #17a2b8;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ff5959;
  --bs-btn-border-color: #ff5959;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ff7272;
  --bs-btn-hover-border-color: #ff6a6a;
  --bs-btn-focus-shadow-rgb: 217, 76, 76;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ff7a7a;
  --bs-btn-active-border-color: #ff6a6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ff5959;
  --bs-btn-disabled-border-color: #ff5959;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #4990c6;
  --bs-btn-border-color: #4990c6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #4990c6;
  --bs-btn-hover-border-color: #4990c6;
  --bs-btn-focus-shadow-rgb: 73, 144, 198;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4990c6;
  --bs-btn-active-border-color: #4990c6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4990c6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4990c6;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #00c4b3;
  --bs-btn-border-color: #00c4b3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #00c4b3;
  --bs-btn-hover-border-color: #00c4b3;
  --bs-btn-focus-shadow-rgb: 0, 196, 179;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #00c4b3;
  --bs-btn-active-border-color: #00c4b3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00c4b3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00c4b3;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #17a2b8;
  --bs-btn-hover-border-color: #17a2b8;
  --bs-btn-focus-shadow-rgb: 23, 162, 184;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #17a2b8;
  --bs-btn-active-border-color: #17a2b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #17a2b8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17a2b8;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff5959;
  --bs-btn-border-color: #ff5959;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ff5959;
  --bs-btn-hover-border-color: #ff5959;
  --bs-btn-focus-shadow-rgb: 255, 89, 89;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ff5959;
  --bs-btn-active-border-color: #ff5959;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff5959;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff5959;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 104, 104, 104;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 6px;
  --bs-btn-padding-x: 15px;
  --bs-btn-font-size: calc(1.275rem + 0.3vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.5rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 3px;
  --bs-btn-padding-x: 12px;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: 0px;
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - 0px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 5px;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-link-hover-bg: #ffa400;
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #ffa400;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 10px;
  --bs-dropdown-item-padding-y: 5px;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 10px;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #ffa400;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 11.25px;
  padding-left: 11.25px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 11.25px;
  padding-left: 11.25px;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(73, 144, 198, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #4990c6;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 5px;
  --bs-navbar-color: #4d4d4d;
  --bs-navbar-hover-color: #4d4d4d;
  --bs-navbar-disabled-color: #4d4d4d;
  --bs-navbar-active-color: #4d4d4d;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #4d4d4d;
  --bs-navbar-brand-hover-color: #4d4d4d;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2877, 77, 77, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #4d4d4d;
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0px;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 10px;
  --bs-card-spacer-x: 20px;
  --bs-card-title-spacer-y: 20px;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 0);
  --bs-card-cap-padding-y: 10px;
  --bs-card-cap-padding-x: 10px;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: 100%;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 10px;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay, .img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%234d4d4d' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%231d3a4f' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(73, 144, 198, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2392bcdd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2392bcdd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(73, 144, 198, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #4990c6;
  --bs-pagination-active-border-color: #4990c6;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 10px;
  --bs-alert-padding-y: 10px;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 30px;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 12.5px 10px;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #4990c6;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 10px;
  --bs-list-group-item-padding-y: 5px;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #4990c6;
  --bs-list-group-active-border-color: #4990c6;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(73, 144, 198, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 10px;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 10px;
  --bs-modal-header-padding-y: 10px;
  --bs-modal-header-padding: 10px 10px;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 5px;
  --bs-tooltip-padding-y: 2.5px;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Noto Sans TC", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 10px;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 10px;
  --bs-popover-body-padding-y: 10px;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Noto Sans TC", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 10px;
  --bs-offcanvas-padding-y: 10px;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000000 !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(109, 166, 209, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(109, 166, 209, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(51, 208, 194, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 208, 194, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(69, 181, 198, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 181, 198, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(255, 122, 122, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 122, 122, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.m-3 {
  margin: 20px !important;
}

.m-4 {
  margin: 30px !important;
}

.m-5 {
  margin: 40px !important;
}

.m-6 {
  margin: 80px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-2 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-3 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-4 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-5 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-6 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-3 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-4 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-6 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 5px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-3 {
  margin-top: 20px !important;
}

.mt-4 {
  margin-top: 30px !important;
}

.mt-5 {
  margin-top: 40px !important;
}

.mt-6 {
  margin-top: 80px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 5px !important;
}

.me-2 {
  margin-right: 10px !important;
}

.me-3 {
  margin-right: 20px !important;
}

.me-4 {
  margin-right: 30px !important;
}

.me-5 {
  margin-right: 40px !important;
}

.me-6 {
  margin-right: 80px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.mb-3 {
  margin-bottom: 20px !important;
}

.mb-4 {
  margin-bottom: 30px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}

.mb-6 {
  margin-bottom: 80px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 5px !important;
}

.ms-2 {
  margin-left: 10px !important;
}

.ms-3 {
  margin-left: 20px !important;
}

.ms-4 {
  margin-left: 30px !important;
}

.ms-5 {
  margin-left: 40px !important;
}

.ms-6 {
  margin-left: 80px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.p-3 {
  padding: 20px !important;
}

.p-4 {
  padding: 30px !important;
}

.p-5 {
  padding: 40px !important;
}

.p-6 {
  padding: 80px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.px-2 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-3 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-4 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.px-5 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-6 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-3 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-4 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-6 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 5px !important;
}

.pt-2 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 20px !important;
}

.pt-4 {
  padding-top: 30px !important;
}

.pt-5 {
  padding-top: 40px !important;
}

.pt-6 {
  padding-top: 80px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 5px !important;
}

.pe-2 {
  padding-right: 10px !important;
}

.pe-3 {
  padding-right: 20px !important;
}

.pe-4 {
  padding-right: 30px !important;
}

.pe-5 {
  padding-right: 40px !important;
}

.pe-6 {
  padding-right: 80px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.pb-2 {
  padding-bottom: 10px !important;
}

.pb-3 {
  padding-bottom: 20px !important;
}

.pb-4 {
  padding-bottom: 30px !important;
}

.pb-5 {
  padding-bottom: 40px !important;
}

.pb-6 {
  padding-bottom: 80px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 5px !important;
}

.ps-2 {
  padding-left: 10px !important;
}

.ps-3 {
  padding-left: 20px !important;
}

.ps-4 {
  padding-left: 30px !important;
}

.ps-5 {
  padding-left: 40px !important;
}

.ps-6 {
  padding-left: 80px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 5px !important;
}

.gap-2 {
  gap: 10px !important;
}

.gap-3 {
  gap: 20px !important;
}

.gap-4 {
  gap: 30px !important;
}

.gap-5 {
  gap: 40px !important;
}

.gap-6 {
  gap: 80px !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 5px !important;
}

.row-gap-2 {
  row-gap: 10px !important;
}

.row-gap-3 {
  row-gap: 20px !important;
}

.row-gap-4 {
  row-gap: 30px !important;
}

.row-gap-5 {
  row-gap: 40px !important;
}

.row-gap-6 {
  row-gap: 80px !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 5px !important;
}

.column-gap-2 {
  column-gap: 10px !important;
}

.column-gap-3 {
  column-gap: 20px !important;
}

.column-gap-4 {
  column-gap: 30px !important;
}

.column-gap-5 {
  column-gap: 40px !important;
}

.column-gap-6 {
  column-gap: 80px !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 5px !important;
  }
  .m-sm-2 {
    margin: 10px !important;
  }
  .m-sm-3 {
    margin: 20px !important;
  }
  .m-sm-4 {
    margin: 30px !important;
  }
  .m-sm-5 {
    margin: 40px !important;
  }
  .m-sm-6 {
    margin: 80px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-sm-2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-sm-3 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-sm-4 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-sm-5 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-sm-6 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-sm-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-sm-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-sm-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-sm-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-sm-6 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 5px !important;
  }
  .mt-sm-2 {
    margin-top: 10px !important;
  }
  .mt-sm-3 {
    margin-top: 20px !important;
  }
  .mt-sm-4 {
    margin-top: 30px !important;
  }
  .mt-sm-5 {
    margin-top: 40px !important;
  }
  .mt-sm-6 {
    margin-top: 80px !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 5px !important;
  }
  .me-sm-2 {
    margin-right: 10px !important;
  }
  .me-sm-3 {
    margin-right: 20px !important;
  }
  .me-sm-4 {
    margin-right: 30px !important;
  }
  .me-sm-5 {
    margin-right: 40px !important;
  }
  .me-sm-6 {
    margin-right: 80px !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 5px !important;
  }
  .mb-sm-2 {
    margin-bottom: 10px !important;
  }
  .mb-sm-3 {
    margin-bottom: 20px !important;
  }
  .mb-sm-4 {
    margin-bottom: 30px !important;
  }
  .mb-sm-5 {
    margin-bottom: 40px !important;
  }
  .mb-sm-6 {
    margin-bottom: 80px !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 5px !important;
  }
  .ms-sm-2 {
    margin-left: 10px !important;
  }
  .ms-sm-3 {
    margin-left: 20px !important;
  }
  .ms-sm-4 {
    margin-left: 30px !important;
  }
  .ms-sm-5 {
    margin-left: 40px !important;
  }
  .ms-sm-6 {
    margin-left: 80px !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 5px !important;
  }
  .p-sm-2 {
    padding: 10px !important;
  }
  .p-sm-3 {
    padding: 20px !important;
  }
  .p-sm-4 {
    padding: 30px !important;
  }
  .p-sm-5 {
    padding: 40px !important;
  }
  .p-sm-6 {
    padding: 80px !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-sm-2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-sm-3 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-sm-4 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-sm-5 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-sm-6 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-sm-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-sm-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-sm-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-sm-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-sm-6 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 5px !important;
  }
  .pt-sm-2 {
    padding-top: 10px !important;
  }
  .pt-sm-3 {
    padding-top: 20px !important;
  }
  .pt-sm-4 {
    padding-top: 30px !important;
  }
  .pt-sm-5 {
    padding-top: 40px !important;
  }
  .pt-sm-6 {
    padding-top: 80px !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 5px !important;
  }
  .pe-sm-2 {
    padding-right: 10px !important;
  }
  .pe-sm-3 {
    padding-right: 20px !important;
  }
  .pe-sm-4 {
    padding-right: 30px !important;
  }
  .pe-sm-5 {
    padding-right: 40px !important;
  }
  .pe-sm-6 {
    padding-right: 80px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 5px !important;
  }
  .pb-sm-2 {
    padding-bottom: 10px !important;
  }
  .pb-sm-3 {
    padding-bottom: 20px !important;
  }
  .pb-sm-4 {
    padding-bottom: 30px !important;
  }
  .pb-sm-5 {
    padding-bottom: 40px !important;
  }
  .pb-sm-6 {
    padding-bottom: 80px !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 5px !important;
  }
  .ps-sm-2 {
    padding-left: 10px !important;
  }
  .ps-sm-3 {
    padding-left: 20px !important;
  }
  .ps-sm-4 {
    padding-left: 30px !important;
  }
  .ps-sm-5 {
    padding-left: 40px !important;
  }
  .ps-sm-6 {
    padding-left: 80px !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 5px !important;
  }
  .gap-sm-2 {
    gap: 10px !important;
  }
  .gap-sm-3 {
    gap: 20px !important;
  }
  .gap-sm-4 {
    gap: 30px !important;
  }
  .gap-sm-5 {
    gap: 40px !important;
  }
  .gap-sm-6 {
    gap: 80px !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 5px !important;
  }
  .row-gap-sm-2 {
    row-gap: 10px !important;
  }
  .row-gap-sm-3 {
    row-gap: 20px !important;
  }
  .row-gap-sm-4 {
    row-gap: 30px !important;
  }
  .row-gap-sm-5 {
    row-gap: 40px !important;
  }
  .row-gap-sm-6 {
    row-gap: 80px !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 5px !important;
  }
  .column-gap-sm-2 {
    column-gap: 10px !important;
  }
  .column-gap-sm-3 {
    column-gap: 20px !important;
  }
  .column-gap-sm-4 {
    column-gap: 30px !important;
  }
  .column-gap-sm-5 {
    column-gap: 40px !important;
  }
  .column-gap-sm-6 {
    column-gap: 80px !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 5px !important;
  }
  .m-md-2 {
    margin: 10px !important;
  }
  .m-md-3 {
    margin: 20px !important;
  }
  .m-md-4 {
    margin: 30px !important;
  }
  .m-md-5 {
    margin: 40px !important;
  }
  .m-md-6 {
    margin: 80px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-md-2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-md-3 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-md-4 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-md-5 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-md-6 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-md-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-md-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-md-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-md-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-md-6 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 5px !important;
  }
  .mt-md-2 {
    margin-top: 10px !important;
  }
  .mt-md-3 {
    margin-top: 20px !important;
  }
  .mt-md-4 {
    margin-top: 30px !important;
  }
  .mt-md-5 {
    margin-top: 40px !important;
  }
  .mt-md-6 {
    margin-top: 80px !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 5px !important;
  }
  .me-md-2 {
    margin-right: 10px !important;
  }
  .me-md-3 {
    margin-right: 20px !important;
  }
  .me-md-4 {
    margin-right: 30px !important;
  }
  .me-md-5 {
    margin-right: 40px !important;
  }
  .me-md-6 {
    margin-right: 80px !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 5px !important;
  }
  .mb-md-2 {
    margin-bottom: 10px !important;
  }
  .mb-md-3 {
    margin-bottom: 20px !important;
  }
  .mb-md-4 {
    margin-bottom: 30px !important;
  }
  .mb-md-5 {
    margin-bottom: 40px !important;
  }
  .mb-md-6 {
    margin-bottom: 80px !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 5px !important;
  }
  .ms-md-2 {
    margin-left: 10px !important;
  }
  .ms-md-3 {
    margin-left: 20px !important;
  }
  .ms-md-4 {
    margin-left: 30px !important;
  }
  .ms-md-5 {
    margin-left: 40px !important;
  }
  .ms-md-6 {
    margin-left: 80px !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 5px !important;
  }
  .p-md-2 {
    padding: 10px !important;
  }
  .p-md-3 {
    padding: 20px !important;
  }
  .p-md-4 {
    padding: 30px !important;
  }
  .p-md-5 {
    padding: 40px !important;
  }
  .p-md-6 {
    padding: 80px !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-md-2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-md-3 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-md-4 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-md-5 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-md-6 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-md-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-md-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-md-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-md-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-md-6 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 5px !important;
  }
  .pt-md-2 {
    padding-top: 10px !important;
  }
  .pt-md-3 {
    padding-top: 20px !important;
  }
  .pt-md-4 {
    padding-top: 30px !important;
  }
  .pt-md-5 {
    padding-top: 40px !important;
  }
  .pt-md-6 {
    padding-top: 80px !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 5px !important;
  }
  .pe-md-2 {
    padding-right: 10px !important;
  }
  .pe-md-3 {
    padding-right: 20px !important;
  }
  .pe-md-4 {
    padding-right: 30px !important;
  }
  .pe-md-5 {
    padding-right: 40px !important;
  }
  .pe-md-6 {
    padding-right: 80px !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 5px !important;
  }
  .pb-md-2 {
    padding-bottom: 10px !important;
  }
  .pb-md-3 {
    padding-bottom: 20px !important;
  }
  .pb-md-4 {
    padding-bottom: 30px !important;
  }
  .pb-md-5 {
    padding-bottom: 40px !important;
  }
  .pb-md-6 {
    padding-bottom: 80px !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 5px !important;
  }
  .ps-md-2 {
    padding-left: 10px !important;
  }
  .ps-md-3 {
    padding-left: 20px !important;
  }
  .ps-md-4 {
    padding-left: 30px !important;
  }
  .ps-md-5 {
    padding-left: 40px !important;
  }
  .ps-md-6 {
    padding-left: 80px !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 5px !important;
  }
  .gap-md-2 {
    gap: 10px !important;
  }
  .gap-md-3 {
    gap: 20px !important;
  }
  .gap-md-4 {
    gap: 30px !important;
  }
  .gap-md-5 {
    gap: 40px !important;
  }
  .gap-md-6 {
    gap: 80px !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 5px !important;
  }
  .row-gap-md-2 {
    row-gap: 10px !important;
  }
  .row-gap-md-3 {
    row-gap: 20px !important;
  }
  .row-gap-md-4 {
    row-gap: 30px !important;
  }
  .row-gap-md-5 {
    row-gap: 40px !important;
  }
  .row-gap-md-6 {
    row-gap: 80px !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 5px !important;
  }
  .column-gap-md-2 {
    column-gap: 10px !important;
  }
  .column-gap-md-3 {
    column-gap: 20px !important;
  }
  .column-gap-md-4 {
    column-gap: 30px !important;
  }
  .column-gap-md-5 {
    column-gap: 40px !important;
  }
  .column-gap-md-6 {
    column-gap: 80px !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 5px !important;
  }
  .m-lg-2 {
    margin: 10px !important;
  }
  .m-lg-3 {
    margin: 20px !important;
  }
  .m-lg-4 {
    margin: 30px !important;
  }
  .m-lg-5 {
    margin: 40px !important;
  }
  .m-lg-6 {
    margin: 80px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-lg-2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-lg-3 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-lg-4 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-lg-5 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-lg-6 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-lg-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-lg-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-lg-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-lg-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-lg-6 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 5px !important;
  }
  .mt-lg-2 {
    margin-top: 10px !important;
  }
  .mt-lg-3 {
    margin-top: 20px !important;
  }
  .mt-lg-4 {
    margin-top: 30px !important;
  }
  .mt-lg-5 {
    margin-top: 40px !important;
  }
  .mt-lg-6 {
    margin-top: 80px !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 5px !important;
  }
  .me-lg-2 {
    margin-right: 10px !important;
  }
  .me-lg-3 {
    margin-right: 20px !important;
  }
  .me-lg-4 {
    margin-right: 30px !important;
  }
  .me-lg-5 {
    margin-right: 40px !important;
  }
  .me-lg-6 {
    margin-right: 80px !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 5px !important;
  }
  .mb-lg-2 {
    margin-bottom: 10px !important;
  }
  .mb-lg-3 {
    margin-bottom: 20px !important;
  }
  .mb-lg-4 {
    margin-bottom: 30px !important;
  }
  .mb-lg-5 {
    margin-bottom: 40px !important;
  }
  .mb-lg-6 {
    margin-bottom: 80px !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 5px !important;
  }
  .ms-lg-2 {
    margin-left: 10px !important;
  }
  .ms-lg-3 {
    margin-left: 20px !important;
  }
  .ms-lg-4 {
    margin-left: 30px !important;
  }
  .ms-lg-5 {
    margin-left: 40px !important;
  }
  .ms-lg-6 {
    margin-left: 80px !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 5px !important;
  }
  .p-lg-2 {
    padding: 10px !important;
  }
  .p-lg-3 {
    padding: 20px !important;
  }
  .p-lg-4 {
    padding: 30px !important;
  }
  .p-lg-5 {
    padding: 40px !important;
  }
  .p-lg-6 {
    padding: 80px !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-lg-2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-lg-3 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-lg-4 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-lg-5 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-lg-6 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-lg-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-lg-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-lg-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-lg-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-lg-6 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 5px !important;
  }
  .pt-lg-2 {
    padding-top: 10px !important;
  }
  .pt-lg-3 {
    padding-top: 20px !important;
  }
  .pt-lg-4 {
    padding-top: 30px !important;
  }
  .pt-lg-5 {
    padding-top: 40px !important;
  }
  .pt-lg-6 {
    padding-top: 80px !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 5px !important;
  }
  .pe-lg-2 {
    padding-right: 10px !important;
  }
  .pe-lg-3 {
    padding-right: 20px !important;
  }
  .pe-lg-4 {
    padding-right: 30px !important;
  }
  .pe-lg-5 {
    padding-right: 40px !important;
  }
  .pe-lg-6 {
    padding-right: 80px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 5px !important;
  }
  .pb-lg-2 {
    padding-bottom: 10px !important;
  }
  .pb-lg-3 {
    padding-bottom: 20px !important;
  }
  .pb-lg-4 {
    padding-bottom: 30px !important;
  }
  .pb-lg-5 {
    padding-bottom: 40px !important;
  }
  .pb-lg-6 {
    padding-bottom: 80px !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 5px !important;
  }
  .ps-lg-2 {
    padding-left: 10px !important;
  }
  .ps-lg-3 {
    padding-left: 20px !important;
  }
  .ps-lg-4 {
    padding-left: 30px !important;
  }
  .ps-lg-5 {
    padding-left: 40px !important;
  }
  .ps-lg-6 {
    padding-left: 80px !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 5px !important;
  }
  .gap-lg-2 {
    gap: 10px !important;
  }
  .gap-lg-3 {
    gap: 20px !important;
  }
  .gap-lg-4 {
    gap: 30px !important;
  }
  .gap-lg-5 {
    gap: 40px !important;
  }
  .gap-lg-6 {
    gap: 80px !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 5px !important;
  }
  .row-gap-lg-2 {
    row-gap: 10px !important;
  }
  .row-gap-lg-3 {
    row-gap: 20px !important;
  }
  .row-gap-lg-4 {
    row-gap: 30px !important;
  }
  .row-gap-lg-5 {
    row-gap: 40px !important;
  }
  .row-gap-lg-6 {
    row-gap: 80px !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 5px !important;
  }
  .column-gap-lg-2 {
    column-gap: 10px !important;
  }
  .column-gap-lg-3 {
    column-gap: 20px !important;
  }
  .column-gap-lg-4 {
    column-gap: 30px !important;
  }
  .column-gap-lg-5 {
    column-gap: 40px !important;
  }
  .column-gap-lg-6 {
    column-gap: 80px !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 5px !important;
  }
  .m-xl-2 {
    margin: 10px !important;
  }
  .m-xl-3 {
    margin: 20px !important;
  }
  .m-xl-4 {
    margin: 30px !important;
  }
  .m-xl-5 {
    margin: 40px !important;
  }
  .m-xl-6 {
    margin: 80px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xl-2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-xl-3 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-xl-4 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xl-5 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-xl-6 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xl-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-xl-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-xl-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xl-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-xl-6 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 5px !important;
  }
  .mt-xl-2 {
    margin-top: 10px !important;
  }
  .mt-xl-3 {
    margin-top: 20px !important;
  }
  .mt-xl-4 {
    margin-top: 30px !important;
  }
  .mt-xl-5 {
    margin-top: 40px !important;
  }
  .mt-xl-6 {
    margin-top: 80px !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 5px !important;
  }
  .me-xl-2 {
    margin-right: 10px !important;
  }
  .me-xl-3 {
    margin-right: 20px !important;
  }
  .me-xl-4 {
    margin-right: 30px !important;
  }
  .me-xl-5 {
    margin-right: 40px !important;
  }
  .me-xl-6 {
    margin-right: 80px !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 5px !important;
  }
  .mb-xl-2 {
    margin-bottom: 10px !important;
  }
  .mb-xl-3 {
    margin-bottom: 20px !important;
  }
  .mb-xl-4 {
    margin-bottom: 30px !important;
  }
  .mb-xl-5 {
    margin-bottom: 40px !important;
  }
  .mb-xl-6 {
    margin-bottom: 80px !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 5px !important;
  }
  .ms-xl-2 {
    margin-left: 10px !important;
  }
  .ms-xl-3 {
    margin-left: 20px !important;
  }
  .ms-xl-4 {
    margin-left: 30px !important;
  }
  .ms-xl-5 {
    margin-left: 40px !important;
  }
  .ms-xl-6 {
    margin-left: 80px !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 5px !important;
  }
  .p-xl-2 {
    padding: 10px !important;
  }
  .p-xl-3 {
    padding: 20px !important;
  }
  .p-xl-4 {
    padding: 30px !important;
  }
  .p-xl-5 {
    padding: 40px !important;
  }
  .p-xl-6 {
    padding: 80px !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-xl-2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-xl-3 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-xl-4 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xl-5 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-xl-6 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-xl-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-xl-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-xl-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xl-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-xl-6 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 5px !important;
  }
  .pt-xl-2 {
    padding-top: 10px !important;
  }
  .pt-xl-3 {
    padding-top: 20px !important;
  }
  .pt-xl-4 {
    padding-top: 30px !important;
  }
  .pt-xl-5 {
    padding-top: 40px !important;
  }
  .pt-xl-6 {
    padding-top: 80px !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 5px !important;
  }
  .pe-xl-2 {
    padding-right: 10px !important;
  }
  .pe-xl-3 {
    padding-right: 20px !important;
  }
  .pe-xl-4 {
    padding-right: 30px !important;
  }
  .pe-xl-5 {
    padding-right: 40px !important;
  }
  .pe-xl-6 {
    padding-right: 80px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 5px !important;
  }
  .pb-xl-2 {
    padding-bottom: 10px !important;
  }
  .pb-xl-3 {
    padding-bottom: 20px !important;
  }
  .pb-xl-4 {
    padding-bottom: 30px !important;
  }
  .pb-xl-5 {
    padding-bottom: 40px !important;
  }
  .pb-xl-6 {
    padding-bottom: 80px !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 5px !important;
  }
  .ps-xl-2 {
    padding-left: 10px !important;
  }
  .ps-xl-3 {
    padding-left: 20px !important;
  }
  .ps-xl-4 {
    padding-left: 30px !important;
  }
  .ps-xl-5 {
    padding-left: 40px !important;
  }
  .ps-xl-6 {
    padding-left: 80px !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 5px !important;
  }
  .gap-xl-2 {
    gap: 10px !important;
  }
  .gap-xl-3 {
    gap: 20px !important;
  }
  .gap-xl-4 {
    gap: 30px !important;
  }
  .gap-xl-5 {
    gap: 40px !important;
  }
  .gap-xl-6 {
    gap: 80px !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 5px !important;
  }
  .row-gap-xl-2 {
    row-gap: 10px !important;
  }
  .row-gap-xl-3 {
    row-gap: 20px !important;
  }
  .row-gap-xl-4 {
    row-gap: 30px !important;
  }
  .row-gap-xl-5 {
    row-gap: 40px !important;
  }
  .row-gap-xl-6 {
    row-gap: 80px !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 5px !important;
  }
  .column-gap-xl-2 {
    column-gap: 10px !important;
  }
  .column-gap-xl-3 {
    column-gap: 20px !important;
  }
  .column-gap-xl-4 {
    column-gap: 30px !important;
  }
  .column-gap-xl-5 {
    column-gap: 40px !important;
  }
  .column-gap-xl-6 {
    column-gap: 80px !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 5px !important;
  }
  .m-xxl-2 {
    margin: 10px !important;
  }
  .m-xxl-3 {
    margin: 20px !important;
  }
  .m-xxl-4 {
    margin: 30px !important;
  }
  .m-xxl-5 {
    margin: 40px !important;
  }
  .m-xxl-6 {
    margin: 80px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .mx-xxl-2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .mx-xxl-3 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-xxl-4 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .mx-xxl-5 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-xxl-6 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .my-xxl-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .my-xxl-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-xxl-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .my-xxl-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-xxl-6 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 5px !important;
  }
  .mt-xxl-2 {
    margin-top: 10px !important;
  }
  .mt-xxl-3 {
    margin-top: 20px !important;
  }
  .mt-xxl-4 {
    margin-top: 30px !important;
  }
  .mt-xxl-5 {
    margin-top: 40px !important;
  }
  .mt-xxl-6 {
    margin-top: 80px !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 5px !important;
  }
  .me-xxl-2 {
    margin-right: 10px !important;
  }
  .me-xxl-3 {
    margin-right: 20px !important;
  }
  .me-xxl-4 {
    margin-right: 30px !important;
  }
  .me-xxl-5 {
    margin-right: 40px !important;
  }
  .me-xxl-6 {
    margin-right: 80px !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 5px !important;
  }
  .mb-xxl-2 {
    margin-bottom: 10px !important;
  }
  .mb-xxl-3 {
    margin-bottom: 20px !important;
  }
  .mb-xxl-4 {
    margin-bottom: 30px !important;
  }
  .mb-xxl-5 {
    margin-bottom: 40px !important;
  }
  .mb-xxl-6 {
    margin-bottom: 80px !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 5px !important;
  }
  .ms-xxl-2 {
    margin-left: 10px !important;
  }
  .ms-xxl-3 {
    margin-left: 20px !important;
  }
  .ms-xxl-4 {
    margin-left: 30px !important;
  }
  .ms-xxl-5 {
    margin-left: 40px !important;
  }
  .ms-xxl-6 {
    margin-left: 80px !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 5px !important;
  }
  .p-xxl-2 {
    padding: 10px !important;
  }
  .p-xxl-3 {
    padding: 20px !important;
  }
  .p-xxl-4 {
    padding: 30px !important;
  }
  .p-xxl-5 {
    padding: 40px !important;
  }
  .p-xxl-6 {
    padding: 80px !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .px-xxl-2 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .px-xxl-3 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-xxl-4 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .px-xxl-5 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-xxl-6 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .py-xxl-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .py-xxl-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-xxl-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .py-xxl-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-xxl-6 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 5px !important;
  }
  .pt-xxl-2 {
    padding-top: 10px !important;
  }
  .pt-xxl-3 {
    padding-top: 20px !important;
  }
  .pt-xxl-4 {
    padding-top: 30px !important;
  }
  .pt-xxl-5 {
    padding-top: 40px !important;
  }
  .pt-xxl-6 {
    padding-top: 80px !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 5px !important;
  }
  .pe-xxl-2 {
    padding-right: 10px !important;
  }
  .pe-xxl-3 {
    padding-right: 20px !important;
  }
  .pe-xxl-4 {
    padding-right: 30px !important;
  }
  .pe-xxl-5 {
    padding-right: 40px !important;
  }
  .pe-xxl-6 {
    padding-right: 80px !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 5px !important;
  }
  .pb-xxl-2 {
    padding-bottom: 10px !important;
  }
  .pb-xxl-3 {
    padding-bottom: 20px !important;
  }
  .pb-xxl-4 {
    padding-bottom: 30px !important;
  }
  .pb-xxl-5 {
    padding-bottom: 40px !important;
  }
  .pb-xxl-6 {
    padding-bottom: 80px !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 5px !important;
  }
  .ps-xxl-2 {
    padding-left: 10px !important;
  }
  .ps-xxl-3 {
    padding-left: 20px !important;
  }
  .ps-xxl-4 {
    padding-left: 30px !important;
  }
  .ps-xxl-5 {
    padding-left: 40px !important;
  }
  .ps-xxl-6 {
    padding-left: 80px !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 5px !important;
  }
  .gap-xxl-2 {
    gap: 10px !important;
  }
  .gap-xxl-3 {
    gap: 20px !important;
  }
  .gap-xxl-4 {
    gap: 30px !important;
  }
  .gap-xxl-5 {
    gap: 40px !important;
  }
  .gap-xxl-6 {
    gap: 80px !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 5px !important;
  }
  .row-gap-xxl-2 {
    row-gap: 10px !important;
  }
  .row-gap-xxl-3 {
    row-gap: 20px !important;
  }
  .row-gap-xxl-4 {
    row-gap: 30px !important;
  }
  .row-gap-xxl-5 {
    row-gap: 40px !important;
  }
  .row-gap-xxl-6 {
    row-gap: 80px !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 5px !important;
  }
  .column-gap-xxl-2 {
    column-gap: 10px !important;
  }
  .column-gap-xxl-3 {
    column-gap: 20px !important;
  }
  .column-gap-xxl-4 {
    column-gap: 30px !important;
  }
  .column-gap-xxl-5 {
    column-gap: 40px !important;
  }
  .column-gap-xxl-6 {
    column-gap: 80px !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 1.5rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.img-overlay {
  padding: 0;
}

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h1, .h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
}

h2, .h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}

h3, .h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

p {
  font-size: 18px;
  line-height: 28px;
}

.caption {
  font-size: 14px;
  line-height: 28px;
}

@media (max-width: 575.98px) {
  h1, .h1 {
    font-size: 36px;
    line-height: 48px;
  }
  h2, .h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3, .h3 {
    font-size: 20px;
    line-height: 26px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
  .caption {
    font-size: 14px;
    line-height: 28px;
  }
}
.card-circle-tag {
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 14px;
  transform: translate(0, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 50%;
}

@media (max-width: 575.98px) {
  .circle-tag {
    top: 15px;
    right: 15px;
    transform: translate(0, 0);
  }
}
table.schedule {
  width: 100%;
}
table.schedule tbody {
  border-bottom: 4px solid #dee2e6;
  vertical-align: top;
}
table.schedule td, table.schedule th {
  border: 2px solid #dee2e6;
  padding: 10px;
}
table.schedule td strong, table.schedule th strong {
  color: #0b2e44;
}
table.schedule tr td:first-child {
  width: 110px;
  vertical-align: top;
}
table.schedule-mobile td[colspan="2"] {
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #e9e9ea;
}
table.schedule-desktop td[colspan="4"] {
  vertical-align: middle;
  text-align: center;
}
table.schedule-desktop th {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  background-color: #e9e9ea;
}

table.oa-table {
  width: 100%;
}
table.oa-table th {
  border-width: 2px 0px 2px 0px;
  border-color: #ffa400;
  border-style: solid;
}
table.oa-table td {
  border-width: 1px 0px 0px 0px;
  border-color: #cccccc;
  border-style: solid;
}
table.oa-table td, table.oa-table th {
  padding: 10px;
}

.oa-label.required {
  position: relative;
}
.oa-label.required:after {
  content: "*";
  color: red;
  font-size: 1.3rem;
  position: absolute;
  right: -10px;
  top: -6px;
}

/* [0] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.0.woff2) format("woff2");
  unicode-range: U+1f921-1f930, U+1f932-1f935, U+1f937-1f939, U+1f940-1f944, U+1f947-1f94a, U+1f950-1f95f, U+1f962-1f967, U+1f969-1f96a, U+1f980-1f981, U+1f984-1f98d, U+1f990-1f992, U+1f994-1f996, U+1f9c0, U+1f9d0, U+1f9d2, U+1f9d4, U+1f9d6, U+1f9d8, U+1f9da, U+1f9dc-1f9dd, U+1f9df-1f9e2, U+1f9e5-1f9e6, U+20024, U+20487, U+20779, U+20c41, U+20c78, U+20d71, U+20e98, U+20ef9, U+2107b, U+210c1, U+22c51, U+233b4, U+24a12, U+2512b, U+2546e, U+25683, U+267cc, U+269f2, U+27657, U+282e2, U+2898d, U+29d5a, U+f0001-f0005, U+f0019, U+f009b, U+f0101-f0104, U+f012b, U+f01ba, U+f01d6, U+f0209, U+f0217, U+f0223-f0224, U+fc355, U+fe327, U+fe517, U+feb97, U+fffb4;
}
/* [6] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.6.woff2) format("woff2");
  unicode-range: U+ff78-ff7e, U+ff80-ff86, U+ff89-ff94, U+ff97-ff9e, U+ffb9, U+ffe0-ffe3, U+ffe9, U+ffeb, U+ffed, U+fffc, U+1d7c7, U+1f004, U+1f0cf, U+1f141-1f142, U+1f150, U+1f154, U+1f158, U+1f15b, U+1f15d-1f15e, U+1f162-1f163, U+1f170-1f171, U+1f174, U+1f177-1f178, U+1f17d-1f17f, U+1f192-1f195, U+1f197-1f19a, U+1f1e6-1f1f5, U+1f1f7-1f1ff, U+1f21a, U+1f22f, U+1f232-1f237, U+1f239-1f23a, U+1f250-1f251, U+1f300, U+1f302-1f319;
}
/* [7] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.7.woff2) format("woff2");
  unicode-range: U+fa0a, U+fa0c, U+fa11, U+fa17, U+fa19, U+fa1b, U+fa1d, U+fa26, U+fa2c, U+fb01, U+fdfc, U+fe0e, U+fe33-fe36, U+fe38-fe44, U+fe49-fe51, U+fe54, U+fe56-fe57, U+fe59-fe5c, U+fe5f-fe6a, U+fe8e, U+fe92-fe93, U+feae, U+fecb-fecc, U+fee0, U+feec, U+fef3, U+ff04, U+ff07, U+ff26-ff2c, U+ff31-ff32, U+ff35-ff37, U+ff39-ff3a, U+ff3c, U+ff3e-ff5b, U+ff5d, U+ff61-ff65, U+ff67-ff68, U+ff6a, U+ff6c-ff77;
}
/* [8] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.8.woff2) format("woff2");
  unicode-range: U+f78a-f78c, U+f78e, U+f792-f796, U+f798, U+f79c, U+f7f5, U+f812, U+f815, U+f876, U+f8f5, U+f8f8, U+f8ff, U+f901-f902, U+f904, U+f906, U+f909-f90a, U+f90f, U+f914, U+f918-f919, U+f91b, U+f91d, U+f91f, U+f923, U+f925, U+f92d-f92f, U+f934, U+f937-f938, U+f93d, U+f93f, U+f941, U+f949, U+f94c, U+f94e-f94f, U+f95a, U+f95d-f95e, U+f961-f963, U+f965-f970, U+f974, U+f976-f97a, U+f97c, U+f97e-f97f, U+f981, U+f983, U+f988, U+f98a, U+f98c, U+f98e, U+f996-f997, U+f999-f99a, U+f99c, U+f99f-f9a0, U+f9a3, U+f9a8, U+f9ad, U+f9b2-f9b6, U+f9b9-f9ba, U+f9bd-f9be, U+f9c1, U+f9c4, U+f9c7, U+f9ca, U+f9cd, U+f9d0-f9d1, U+f9d3-f9d4, U+f9d7-f9d8, U+f9dc-f9dd, U+f9df-f9e1, U+f9e4, U+f9e8-f9ea, U+f9f4, U+f9f6-f9f7, U+f9f9-f9fa, U+f9fc-fa01, U+fa03-fa04, U+fa06, U+fa08-fa09;
}
/* [19] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.19.woff2) format("woff2");
  unicode-range: U+9eb9-9eba, U+9ebe-9ebf, U+9ecc-9ecd, U+9ed0, U+9ed2, U+9ed4, U+9ed9-9eda, U+9edc-9edd, U+9edf-9ee0, U+9ee2, U+9ee5, U+9ee7, U+9eee, U+9ef3-9ef4, U+9ef6-9ef7, U+9ef9, U+9efb-9efd, U+9eff, U+9f07-9f09, U+9f10, U+9f14-9f15, U+9f19, U+9f22, U+9f29, U+9f2c, U+9f2f, U+9f31, U+9f34, U+9f37, U+9f39, U+9f3d-9f3e, U+9f41, U+9f4c-9f50, U+9f54, U+9f57, U+9f59, U+9f5c, U+9f5f-9f60, U+9f62-9f63, U+9f66-9f67, U+9f6a, U+9f6c, U+9f72, U+9f76-9f77, U+9f7f, U+9f84-9f85, U+9f88, U+9f8e, U+9f91, U+9f94-9f96, U+9f98, U+9f9a-9f9b, U+9f9f-9fa0, U+9fa2, U+9fa4, U+a1f4, U+a4b0-a4b1, U+a4b3, U+a9c1-a9c2, U+aa31, U+ab34, U+ac00-ac01, U+ac04, U+ac08, U+ac10-ac11, U+ac13-ac16, U+ac19, U+ac1c-ac1d, U+ac24, U+ac70-ac71, U+ac74, U+ac77-ac78, U+ac80-ac81, U+ac83, U+ac8c, U+ac90, U+aca0, U+aca8-aca9, U+acac, U+acb0, U+acb8-acb9, U+acbc-acbd, U+acc1, U+acc4, U+ace0-ace1, U+ace4, U+ace8, U+acf0-acf1, U+acf3, U+acf5, U+acfc;
}
/* [20] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.20.woff2) format("woff2");
  unicode-range: U+9d34, U+9d37, U+9d3d, U+9d42, U+9d50, U+9d52-9d53, U+9d59, U+9d5c, U+9d5e-9d61, U+9d6a, U+9d6f-9d70, U+9d77, U+9d7a, U+9d7e, U+9d87, U+9d89, U+9d8f, U+9d91-9d93, U+9d96, U+9d98, U+9d9a, U+9da1-9da2, U+9da5, U+9da9, U+9dab-9dac, U+9db1-9db2, U+9db5, U+9db9-9dbc, U+9dbf-9dc2, U+9dc4, U+9dc7-9dc9, U+9dd3, U+9dd6, U+9dd9-9dda, U+9dde-9ddf, U+9de2, U+9de5-9de6, U+9de8, U+9def-9df0, U+9df2-9df4, U+9df8, U+9dfa, U+9dfd, U+9dff, U+9e02, U+9e07, U+9e0a, U+9e11, U+9e15, U+9e18, U+9e1a-9e1b, U+9e1d-9e1e, U+9e20-9e23, U+9e25-9e26, U+9e2d, U+9e2f, U+9e33, U+9e35, U+9e3d-9e3f, U+9e42-9e43, U+9e45, U+9e48-9e4a, U+9e4c, U+9e4f, U+9e51, U+9e55, U+9e64, U+9e66, U+9e6b, U+9e6d-9e6e, U+9e70, U+9e73, U+9e75, U+9e78, U+9e80-9e83, U+9e87-9e88, U+9e8b-9e8c, U+9e90-9e91, U+9e93, U+9e96, U+9e9d, U+9ea1, U+9ea4, U+9ea6, U+9ea9-9eab, U+9ead, U+9eaf, U+9eb4, U+9eb7-9eb8;
}
/* [21] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.21.woff2) format("woff2");
  unicode-range: U+9bd3-9bd7, U+9bd9, U+9bdb, U+9bdd, U+9be1-9be2, U+9be4-9be5, U+9be7, U+9bea, U+9bed-9bee, U+9bf0-9bf1, U+9bf5, U+9bf7, U+9bf9, U+9bfd, U+9bff, U+9c02, U+9c06, U+9c08-9c0a, U+9c0d, U+9c10, U+9c12-9c13, U+9c15, U+9c1c, U+9c1f, U+9c21, U+9c23-9c25, U+9c28-9c29, U+9c2d-9c2e, U+9c31-9c33, U+9c35-9c37, U+9c39-9c3b, U+9c3d-9c3e, U+9c40, U+9c42, U+9c45-9c49, U+9c52, U+9c54, U+9c56, U+9c58-9c5a, U+9c5d, U+9c5f-9c60, U+9c63, U+9c67-9c68, U+9c72, U+9c75, U+9c78, U+9c7a-9c7c, U+9c7f-9c81, U+9c87-9c88, U+9c8d, U+9c91, U+9c94, U+9c97, U+9c9b, U+9ca4, U+9ca8, U+9cab, U+9cad, U+9cb1-9cb3, U+9cb6-9cb8, U+9cc4-9cc5, U+9ccc-9ccd, U+9cd5-9cd7, U+9cdd-9cdf, U+9ce7, U+9ce9, U+9cee-9cf0, U+9cf2, U+9cfc-9cfe, U+9d03, U+9d06-9d08, U+9d0e, U+9d10, U+9d12, U+9d15, U+9d17, U+9d1d-9d1f, U+9d23, U+9d2b, U+9d2f-9d30;
}
/* [22] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.22.woff2) format("woff2");
  unicode-range: U+9a6f-9a71, U+9a73-9a74, U+9a76, U+9a78-9a79, U+9a7b-9a7c, U+9a7e-9a7f, U+9a81-9a82, U+9a84-9a87, U+9a8a-9a8b, U+9a8f-9a91, U+9a97, U+9a9a, U+9a9e, U+9aa0-9aa1, U+9aa4-9aa5, U+9aaf-9ab1, U+9ab6, U+9ab9-9aba, U+9abe, U+9ac0-9ac5, U+9ac8, U+9acb-9acc, U+9ace-9acf, U+9ad1, U+9ad5-9ad7, U+9ad9, U+9adf, U+9ae1, U+9ae3, U+9aea-9aeb, U+9aed, U+9aef, U+9af2, U+9af4, U+9af9, U+9afb, U+9afd, U+9b03-9b04, U+9b08, U+9b13, U+9b18, U+9b1f, U+9b22-9b23, U+9b28-9b2a, U+9b2c-9b30, U+9b32, U+9b3b, U+9b43, U+9b46-9b49, U+9b4b-9b4e, U+9b51, U+9b55, U+9b58, U+9b5b, U+9b5e-9b60, U+9b63, U+9b68-9b69, U+9b74, U+9b7d, U+9b80-9b81, U+9b83-9b84, U+9b87-9b88, U+9b8a-9b8b, U+9b8d-9b8e, U+9b90, U+9b92-9b95, U+9b97, U+9b9f-9ba0, U+9ba2-9ba3, U+9ba8, U+9bab, U+9bb0, U+9bb8, U+9bc0-9bc1, U+9bc3, U+9bc6-9bc8;
}
/* [23] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.23.woff2) format("woff2");
  unicode-range: U+9912, U+9914, U+9916-9917, U+991b-991c, U+991e, U+9920, U+9927, U+992b-992c, U+992e, U+9931-9933, U+9938, U+993a, U+993c-9940, U+9943-9944, U+9948-994a, U+994c-994e, U+9951, U+9954, U+995c, U+995e-995f, U+9961-9962, U+9965, U+9968, U+996a, U+996d-9972, U+9975-9976, U+997a, U+997c, U+997f-9980, U+9984-9985, U+9988, U+998b, U+998d, U+998f, U+9992, U+9994-9995, U+9997-9998, U+999c, U+999e, U+99a0-99a1, U+99ab, U+99af, U+99b1, U+99b4, U+99b9, U+99c4-99c6, U+99cf, U+99d1-99d2, U+99d4, U+99d6, U+99d8-99d9, U+99df, U+99e1-99e2, U+99e9, U+99ee, U+99f0, U+99f8, U+99fb, U+9a01-9a05, U+9a0c, U+9a0f-9a13, U+9a16, U+9a1b-9a1c, U+9a20, U+9a24, U+9a28, U+9a2b, U+9a2d-9a2e, U+9a34-9a36, U+9a38, U+9a3e, U+9a40-9a44, U+9a4a, U+9a4c-9a4e, U+9a52, U+9a56, U+9a62-9a65, U+9a69-9a6b, U+9a6d;
}
/* [24] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.24.woff2) format("woff2");
  unicode-range: U+97c6, U+97c9, U+97cd, U+97d6, U+97d8-97d9, U+97dc-97de, U+97e1, U+97e6-97e7, U+97ec-97ee, U+97f0-97f1, U+97f5, U+97f9-97fa, U+97fe, U+9804, U+9807, U+980a, U+980e-980f, U+9814, U+9816, U+981a, U+981c, U+981e, U+9820-9821, U+9823, U+9826, U+9828, U+982a-982c, U+9832, U+9834-9835, U+9837, U+9839, U+983c-983d, U+9845, U+9848-9849, U+984e, U+9852-9857, U+9859-985a, U+9862-9863, U+9865, U+9870-9871, U+9873-9874, U+9877, U+987a-987f, U+9881-9882, U+9885, U+9887-988a, U+988c-988d, U+9890, U+9893, U+9896-9897, U+989a, U+989c-989e, U+98a0, U+98a4, U+98a6-98a7, U+98a9, U+98ae-98af, U+98b2-98b3, U+98b6-98b8, U+98ba-98bd, U+98bf, U+98c7-98c8, U+98ca, U+98d2-98d3, U+98d8-98da, U+98dc, U+98de, U+98e0-98e1, U+98e3, U+98e5, U+98e7-98e9, U+98eb-98ee, U+98f1, U+98f4, U+9901, U+9908-9909, U+990c, U+9911;
}
/* [25] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.25.woff2) format("woff2");
  unicode-range: U+964e-964f, U+9651, U+9653-9655, U+9658, U+965b-965f, U+9661, U+9665, U+9668-9669, U+966c, U+9672, U+9674, U+967a, U+9682-9685, U+9688-9689, U+968b, U+968d, U+9695, U+9697-9698, U+969e, U+96a0-96a4, U+96a9, U+96ac, U+96ae, U+96b0, U+96b2-96b4, U+96b6-96b7, U+96b9, U+96bc-96be, U+96c3, U+96c9-96cb, U+96ce-96cf, U+96d1-96d2, U+96d8, U+96dd, U+96e9, U+96eb, U+96f0-96f1, U+96f3-96f4, U+96f9, U+96fe-96ff, U+9701-9702, U+9705, U+9708, U+970a, U+970e-9711, U+9719, U+971d, U+971f-9720, U+9728, U+972a, U+972d, U+9730, U+9733, U+973a, U+973d, U+9744, U+9746-9747, U+9749, U+9750-9751, U+9753, U+9758-9759, U+975b, U+975d, U+9763, U+9765-9766, U+9768, U+976c-976d, U+9771, U+9773, U+9776, U+977a, U+977c, U+9780, U+9784-9786, U+9788, U+978e-978f, U+9798, U+979d-979e, U+97a3, U+97a5-97a6, U+97a8, U+97ab-97ac, U+97ae, U+97b6-97b7, U+97b9, U+97bf, U+97c1, U+97c3;
}
/* [26] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.26.woff2) format("woff2");
  unicode-range: U+950c, U+950f-9510, U+9519-951b, U+951d, U+951f, U+9521-9526, U+952d-9530, U+9535, U+953a-953b, U+9540-9542, U+9547, U+9549-954a, U+954d, U+9550-9551, U+9554-9556, U+955c, U+956c-956d, U+956f-9570, U+9573, U+9576, U+9578, U+9582, U+9586, U+9588, U+958e-958f, U+9599, U+959c, U+959e-959f, U+95a1-95a2, U+95a4, U+95a6-95a7, U+95aa-95ae, U+95b0, U+95b2, U+95b6, U+95b9-95bf, U+95c2-95c4, U+95c7-95c9, U+95cb-95cd, U+95d0, U+95d3-95d5, U+95d7-95d8, U+95da, U+95de, U+95e0-95e1, U+95e4-95e5, U+95ea-95eb, U+95ef-95f0, U+95f2-95f3, U+95f5, U+95f7-95fa, U+95fd, U+9600-9602, U+9607-9609, U+960e, U+9610-9611, U+9614, U+9616, U+9619-961a, U+961c-961d, U+961f, U+9621, U+9624, U+9628, U+962f, U+9633-9636, U+963c, U+9641-9643, U+9645, U+9647-9648;
}
/* [27] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.27.woff2) format("woff2");
  unicode-range: U+93e4, U+93e8, U+93ee, U+93f0, U+93f5, U+93f7-93f9, U+93fb, U+9403, U+9407, U+940f-9410, U+9413-9414, U+9417, U+9419-941a, U+941c, U+941e, U+9420-9425, U+9427-942b, U+942e, U+9432-9433, U+9436, U+9438, U+943a, U+943d, U+943f-9440, U+9445, U+944a, U+944c, U+9454-9455, U+945a-945b, U+945e, U+9460, U+9462-9463, U+9468, U+946a, U+946d, U+946f, U+9471, U+9474-9477, U+9479, U+947e-9481, U+9488-948a, U+948e, U+9492-9493, U+9497, U+9499, U+949b-94a3, U+94a5-94a9, U+94ac, U+94ae-94b1, U+94b3-94b5, U+94bb-94bc, U+94be-94c3, U+94c5-94c6, U+94c9, U+94ce-94d0, U+94db-94dd, U+94e0, U+94e2-94e3, U+94e8, U+94ec-94ee, U+94f0, U+94f2, U+94f5-94f6, U+94f8, U+94fa, U+94ff-9502, U+9504-9506, U+9508, U+950b;
}
/* [28] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.28.woff2) format("woff2");
  unicode-range: U+92e5-92e6, U+92e8-92e9, U+92ed-92ef, U+92f1-92f3, U+92f6, U+92f9, U+92fb, U+9300-9302, U+9306, U+930b, U+930f, U+9312, U+9315, U+9319-931b, U+931d-931f, U+9321, U+9323-9325, U+9327-932a, U+932c-932e, U+9331-9333, U+9335, U+9338, U+933c, U+9340-9341, U+9345-9349, U+934f-9352, U+9354, U+9356-935a, U+935c-9360, U+9363-9367, U+9369-936a, U+936c, U+936e, U+9370-9371, U+9373, U+9376, U+9379-937a, U+937c, U+9385, U+9387, U+938c, U+938f, U+9394, U+9397-9398, U+939a-939b, U+939d-939e, U+93a1-93a3, U+93a6-93a7, U+93a9-93aa, U+93ac-93ad, U+93af-93b0, U+93b3-93bb, U+93bd-93be, U+93c0-93c4, U+93c7, U+93ca-93cd, U+93d0-93d1, U+93d6-93d8, U+93dc-93de, U+93e0;
}
/* [29] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.29.woff2) format("woff2");
  unicode-range: U+91f0-91f1, U+91f5-91fa, U+91fd, U+91ff-9201, U+9203-920a, U+920d-920e, U+9210-9211, U+9217, U+9219, U+921c, U+9221, U+9224-9225, U+9227, U+922a, U+922d-922e, U+9230-9231, U+9233, U+9235-9239, U+923b, U+923d-9241, U+9244, U+9246, U+9248-9249, U+924b-9251, U+9253, U+925a, U+925d-925e, U+9262, U+9265-9267, U+926b-926d, U+926f, U+9271-9272, U+9274, U+9276, U+9278, U+927a-927c, U+927e-927f, U+9282-9283, U+9286, U+9288, U+928a, U+928d-928e, U+9291, U+9295-9296, U+9299-929b, U+929d, U+92a0-92ae, U+92b2, U+92b5-92b6, U+92bb-92bc, U+92c2-92c3, U+92c6-92cd, U+92cf-92d1, U+92d5, U+92d7, U+92d9, U+92dd, U+92df, U+92e4;
}
/* [30] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.30.woff2) format("woff2");
  unicode-range: U+90bb, U+90bd-90be, U+90c3-90c5, U+90c7, U+90d1, U+90d4-90d5, U+90d7, U+90db-90df, U+90e2-90e4, U+90ea-90eb, U+90ef, U+90f4, U+90f7-90f8, U+90fc, U+90fe-9100, U+9102, U+9104, U+9106, U+9112, U+9114-911a, U+911c, U+911e, U+9120, U+9122-9123, U+9129, U+912b, U+912f, U+9131-9132, U+9134, U+9136, U+9139-913a, U+9143, U+9146, U+9148-914a, U+914e-9150, U+9154, U+9156-9157, U+9159-915a, U+915d-915e, U+9161-9164, U+916b, U+916e, U+9170-9172, U+9174, U+9176, U+9179-917a, U+917f, U+9181-9182, U+9184-9186, U+918c-918e, U+9190-9191, U+9196, U+919a-919b, U+919e, U+91a1-91a4, U+91a7, U+91aa, U+91ae-91b2, U+91b4-91b5, U+91b8, U+91bd-91be, U+91c1, U+91c3, U+91c5-91c6, U+91c8-91ca, U+91d2-91d5, U+91d7, U+91d9, U+91e1, U+91e4, U+91e6-91e9, U+91ec-91ed;
}
/* [31] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.31.woff2) format("woff2");
  unicode-range: U+8f68-8f69, U+8f6d-8f72, U+8f74, U+8f76, U+8f7b-8f7c, U+8f7f, U+8f83-8f86, U+8f88-8f8a, U+8f8d, U+8f90, U+8f93, U+8f95-8f97, U+8f99, U+8f9e-8f9f, U+8fa2, U+8fa7, U+8fa9, U+8fab, U+8fae, U+8fb3, U+8fb5-8fb6, U+8fba-8fbd, U+8fbf, U+8fc1-8fc2, U+8fc6, U+8fc8, U+8fcc-8fcd, U+8fd2-8fd3, U+8fd5, U+8fdc-8fdd, U+8fdf, U+8fe2-8fe5, U+8fe8-8fe9, U+8fed-8fee, U+8ff3, U+8ff5, U+8ff8, U+8ffa-8ffc, U+8ffe, U+9002, U+9004, U+9008, U+900a-900b, U+9011-9013, U+9016, U+901e, U+9021, U+9024, U+902d, U+902f-9030, U+9034-9036, U+9039-903b, U+9041, U+9044-9045, U+904f-9052, U+9057-9058, U+905b, U+905d, U+9061-9062, U+9065, U+9068, U+906f, U+9074, U+9079, U+907d, U+9083, U+9085, U+9087-9089, U+908b, U+9090, U+9093, U+9095, U+9097, U+9099, U+909b, U+909d-909e, U+90a0-90a2, U+90ac, U+90af-90b0, U+90b2-90b4, U+90b6, U+90b9;
}
/* [32] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.32.woff2) format("woff2");
  unicode-range: U+8e18, U+8e1d-8e21, U+8e23, U+8e26-8e28, U+8e2a-8e2b, U+8e2d-8e2e, U+8e30-8e31, U+8e35, U+8e39, U+8e3c-8e3d, U+8e40-8e42, U+8e47, U+8e49-8e4e, U+8e50, U+8e53-8e55, U+8e59-8e5b, U+8e60, U+8e62-8e63, U+8e67, U+8e69, U+8e6c-8e6d, U+8e6f, U+8e74, U+8e76, U+8e7a-8e7c, U+8e82, U+8e84-8e85, U+8e87, U+8e89-8e8b, U+8e8f-8e95, U+8e99-8e9a, U+8e9d-8e9e, U+8ea1, U+8ea3, U+8ea5-8ea6, U+8eaa, U+8eac-8ead, U+8eaf-8eb1, U+8eb9, U+8ebc, U+8ebe, U+8ec6, U+8ecb, U+8ecf, U+8ed1, U+8ed4, U+8ed7, U+8eda-8edb, U+8ee2, U+8ee8, U+8eeb, U+8ef2, U+8ef9-8efe, U+8f05, U+8f07-8f08, U+8f0a-8f0c, U+8f12-8f13, U+8f17, U+8f19-8f1a, U+8f1c, U+8f1e-8f1f, U+8f25-8f26, U+8f2d, U+8f30, U+8f33, U+8f36, U+8f3e, U+8f40-8f42, U+8f45-8f47, U+8f4d, U+8f54-8f55, U+8f5d, U+8f61-8f62, U+8f64, U+8f67;
}
/* [33] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.33.woff2) format("woff2");
  unicode-range: U+8cc1-8cc2, U+8cc4-8cc5, U+8ccf-8cd2, U+8cd4-8cd5, U+8cd9-8cdb, U+8ce1, U+8ce8, U+8ceb, U+8cf0, U+8cf8, U+8cfb, U+8cfe, U+8d04, U+8d07, U+8d0b, U+8d0d, U+8d10, U+8d12-8d14, U+8d17, U+8d1b-8d1f, U+8d21-8d26, U+8d29-8d2c, U+8d2e-8d32, U+8d34-8d35, U+8d37-8d38, U+8d3a-8d3c, U+8d3e-8d3f, U+8d41-8d43, U+8d48, U+8d4b-8d4c, U+8d4e-8d50, U+8d54, U+8d56, U+8d58, U+8d5a-8d5b, U+8d5f-8d60, U+8d62-8d63, U+8d66-8d69, U+8d6c-8d6e, U+8d73, U+8d75-8d76, U+8d7b, U+8d7d, U+8d84, U+8d8b, U+8d90-8d91, U+8d94, U+8d96, U+8d9c, U+8dab, U+8daf, U+8db2, U+8db5, U+8db7, U+8dba, U+8dbc, U+8dbf, U+8dc2-8dc3, U+8dc6, U+8dcb, U+8dce-8dd0, U+8dd6-8dd7, U+8dda-8ddb, U+8de3-8de4, U+8de9, U+8deb-8dec, U+8df1, U+8df5-8df7, U+8dfa-8dfd, U+8e05, U+8e08-8e0a, U+8e0e, U+8e14, U+8e16;
}
/* [34] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.34.woff2) format("woff2");
  unicode-range: U+8b6c-8b6d, U+8b72, U+8b7e, U+8b83, U+8b89, U+8b8c, U+8b8e, U+8b90, U+8b92, U+8b95-8b96, U+8b99, U+8b9c, U+8b9e-8b9f, U+8ba3, U+8ba5, U+8ba7, U+8baa, U+8bad, U+8bb2-8bb4, U+8bb6-8bb9, U+8bbc-8bbd, U+8bbf-8bc0, U+8bc3, U+8bc5, U+8bc8-8bcb, U+8bcf, U+8bd1, U+8bd7-8bdc, U+8bde-8be1, U+8be3, U+8be7, U+8be9, U+8beb-8bec, U+8bef, U+8bf1-8bf2, U+8bf5-8bf6, U+8bf8, U+8bfa, U+8bfd-8bfe, U+8c01-8c02, U+8c05, U+8c08, U+8c0a-8c11, U+8c13-8c15, U+8c18-8c1c, U+8c1f, U+8c23-8c29, U+8c2c-8c2d, U+8c31, U+8c34, U+8c36, U+8c39, U+8c3f, U+8c47, U+8c49-8c4c, U+8c4f, U+8c51, U+8c55, U+8c62, U+8c68, U+8c73, U+8c78, U+8c7a-8c7c, U+8c82, U+8c85, U+8c89-8c8a, U+8c8d-8c8e, U+8c90, U+8c94, U+8c98-8c99, U+8ca3-8ca4, U+8cad-8cb0, U+8cb2, U+8cb9-8cba, U+8cbd;
}
/* [35] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.35.woff2) format("woff2");
  unicode-range: U+89f6, U+89ff, U+8a01, U+8a03, U+8a07, U+8a09, U+8a0c, U+8a0f-8a12, U+8a16, U+8a1b, U+8a22, U+8a25, U+8a27, U+8a2b, U+8a33, U+8a36, U+8a38, U+8a3d-8a3e, U+8a41, U+8a45-8a46, U+8a48, U+8a4e, U+8a51-8a52, U+8a54, U+8a56-8a58, U+8a5b, U+8a5d, U+8a61, U+8a63, U+8a67, U+8a6a-8a6c, U+8a70, U+8a74-8a76, U+8a7a-8a7c, U+8a82, U+8a84-8a86, U+8a89, U+8a8f-8a92, U+8a94, U+8a9a, U+8aa1, U+8aa3, U+8aa5, U+8aa7-8aa8, U+8aad, U+8ab1, U+8ab6, U+8abe, U+8ac2, U+8ac4, U+8ac6, U+8ac9, U+8acc-8acf, U+8ad1, U+8adb, U+8add-8ae2, U+8ae4, U+8ae6, U+8aea-8aeb, U+8aed, U+8af1-8af6, U+8afa, U+8afc, U+8b01, U+8b04-8b05, U+8b07, U+8b0b-8b0d, U+8b0f-8b10, U+8b13-8b14, U+8b16, U+8b1a, U+8b1c, U+8b21-8b22, U+8b26, U+8b28, U+8b2b, U+8b2e, U+8b33, U+8b41, U+8b46, U+8b4c-8b4f, U+8b53-8b54, U+8b56, U+8b59, U+8b5e-8b60, U+8b64, U+8b6a-8b6b;
}
/* [36] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.36.woff2) format("woff2");
  unicode-range: U+889b-889c, U+889e, U+88a2, U+88a4, U+88a8, U+88aa, U+88ad-88ae, U+88b1, U+88b4, U+88b7-88b8, U+88bc-88be, U+88c0, U+88c4, U+88c6-88c7, U+88c9-88cc, U+88ce, U+88d2, U+88d6, U+88d8, U+88db, U+88df, U+88e4, U+88e8-88e9, U+88ec, U+88ef-88f1, U+88f3-88f5, U+88fc, U+88fe, U+8900, U+8902, U+8906, U+890a-890c, U+8912-8915, U+8918-891a, U+891f, U+8921, U+8923, U+8925, U+892a-892b, U+892d, U+8930, U+8933, U+8935-8936, U+8938, U+893d, U+8941-8943, U+8946-8947, U+8949, U+894c-894d, U+8956-8957, U+8959, U+895c, U+895e-8960, U+8964, U+8966, U+896c, U+8971, U+8974, U+897b, U+897e, U+8980, U+8982-8983, U+8987-8988, U+898a, U+898c, U+8991, U+8994-8995, U+8997-8998, U+899a, U+899c, U+89a1, U+89a4-89a7, U+89a9, U+89ac, U+89af, U+89b2-89b3, U+89b7, U+89bb, U+89bf, U+89c5, U+89c9-89ca, U+89d1, U+89d4-89d5, U+89da, U+89dc-89de, U+89e5-89e7, U+89ed, U+89f1, U+89f3-89f4;
}
/* [37] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.37.woff2) format("woff2");
  unicode-range: U+874c, U+874e-874f, U+8753, U+8757-8758, U+875d, U+875f, U+8761-8765, U+8768, U+876a, U+876c-8770, U+8772, U+8777, U+877a-877b, U+877d, U+8781, U+8784-8785, U+8788, U+878b, U+8793, U+8797-8798, U+879f, U+87a3, U+87a8, U+87ab-87ad, U+87af, U+87b3, U+87b5, U+87b9, U+87bb, U+87bd-87c0, U+87c4-87c8, U+87ca-87cc, U+87ce, U+87d2, U+87db-87dc, U+87e0-87e1, U+87e3, U+87e5, U+87e7, U+87ea-87eb, U+87ee-87ef, U+87f3-87f4, U+87f6-87f7, U+87fe, U+8802-8806, U+880a-880b, U+880e, U+8810-8811, U+8813, U+8815-8816, U+881b, U+8821, U+8823, U+8827, U+8831-8832, U+8835, U+8839-883a, U+883c, U+8844-8846, U+884a, U+884e, U+8852, U+8854-8856, U+8859-885a, U+885e, U+8862, U+8864-8865, U+8869-886a, U+886c-886e, U+8871-8872, U+8875, U+8879, U+887d-887f, U+8882, U+8884-8885, U+8888, U+8890, U+8892-8893, U+8897-8898, U+889a;
}
/* [38] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.38.woff2) format("woff2");
  unicode-range: U+8602, U+8604-8605, U+8610, U+8616-8618, U+861a, U+861e, U+8621-8622, U+8627, U+8629-862a, U+8634-8636, U+8638, U+863a, U+863c, U+8640, U+8642, U+8646, U+864c-864d, U+864f, U+8651-8654, U+8657, U+8659-865a, U+865c, U+8662, U+866b-866c, U+866f-8671, U+8673, U+8677, U+867a-867b, U+867d-867e, U+8680-8682, U+868b-868d, U+8693-8696, U+869a, U+869c-869d, U+86a1, U+86a3-86a4, U+86a7-86aa, U+86af-86b1, U+86b3-86b4, U+86b6, U+86b9-86ba, U+86c0-86c2, U+86c4, U+86c6, U+86c9-86ca, U+86cc-86ce, U+86d0, U+86d3-86d4, U+86de-86df, U+86e9, U+86ed-86f0, U+86f3, U+86f8-86fc, U+86fe, U+8703, U+8706-870a, U+870d-870e, U+8711-8712, U+8715, U+8717, U+8719-871a, U+871e, U+8721-8723, U+8725, U+8728-8729, U+872e, U+8731, U+8734, U+8737, U+873a, U+873e-8740, U+8742, U+8747, U+8749, U+874b;
}
/* [39] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.39.woff2) format("woff2");
  unicode-range: U+84b1, U+84b4, U+84b9-84bb, U+84bd-84c0, U+84c2, U+84c6-84c7, U+84ca, U+84cd-84d2, U+84d6, U+84dd, U+84df, U+84e2, U+84e6-84e8, U+84ea, U+84ef-84f0, U+84f3-84f4, U+84f7, U+84fa, U+84fc-84fd, U+84ff-8500, U+8506, U+850c, U+8511, U+8515, U+8517, U+851d-851f, U+8524, U+852b, U+852f, U+8532, U+8534-8535, U+8537-8538, U+853a, U+853c, U+853e, U+8541-8542, U+8545, U+8548, U+854b, U+854d-854e, U+8552-8553, U+8555-8558, U+855a, U+855e, U+8561-8565, U+8568, U+856a-856c, U+856f, U+8574, U+8577-857b, U+8580-8581, U+8585-8586, U+858a, U+858c, U+858f-8590, U+8594, U+8597-8599, U+859c, U+859f, U+85a1-85a2, U+85a4, U+85a8, U+85ab-85ac, U+85ae, U+85b3-85b4, U+85b7, U+85b9-85ba, U+85bd-85be, U+85c1-85c2, U+85cb, U+85ce, U+85d0, U+85d3, U+85d5, U+85dc, U+85e0, U+85e6, U+85e8, U+85ea, U+85ed, U+85f4, U+85f6-85f7, U+85f9-85fa, U+85ff;
}
/* [40] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.40.woff2) format("woff2");
  unicode-range: U+8378, U+837a-8380, U+8383, U+8385-8386, U+8392, U+8394-8395, U+8398-8399, U+839b-839c, U+83a0, U+83a2, U+83a4, U+83a7-83aa, U+83af-83b5, U+83b7, U+83b9-83ba, U+83bc-83c0, U+83c2, U+83c4-83c5, U+83c8-83c9, U+83cb, U+83ce-83cf, U+83d1, U+83d4-83d6, U+83d8, U+83dd, U+83df, U+83e1-83e2, U+83e5, U+83ea-83eb, U+83f0, U+83f3-83f4, U+83f9, U+83fb-83fe, U+8406-8407, U+840b, U+840f, U+8411, U+8413, U+8418, U+841b-841d, U+8420-8421, U+8423-8424, U+8426-8429, U+842b, U+842d-842e, U+8432-8433, U+8435, U+8437-8439, U+843b-843c, U+843e, U+8445-8447, U+844e, U+8451-8452, U+8456, U+8459-845a, U+845c, U+845f, U+8462, U+8466-8467, U+846d, U+846f-8471, U+8473-8474, U+8476-8478, U+847a, U+8484, U+848b, U+848d-848e, U+8493-8494, U+8497, U+849d, U+849f, U+84a1, U+84a8, U+84af;
}
/* [41] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.41.woff2) format("woff2");
  unicode-range: U+826e, U+8270, U+8273, U+8276, U+8278-8279, U+827d, U+827f, U+8283-8284, U+8288-828a, U+828d-8291, U+8293-8294, U+8298, U+829a-829c, U+829e-82a4, U+82a6-82ab, U+82ae, U+82b0, U+82b4, U+82b6, U+82ba-82bc, U+82be, U+82c1, U+82c4-82c5, U+82c7, U+82cb, U+82cd, U+82cf-82d0, U+82d2, U+82d5-82d6, U+82d9, U+82db-82dc, U+82de-82e1, U+82e3-82e4, U+82e7, U+82ea-82eb, U+82ef-82f0, U+82f3-82f4, U+82f6-82f7, U+82f9-82fc, U+82fe-8301, U+8306-8308, U+830b-830e, U+8316, U+8318, U+831a-831b, U+831d-831e, U+8327, U+832a, U+832c-832d, U+832f, U+8331, U+8333-8334, U+8337, U+833a-833c, U+833f-8340, U+8342, U+8344-8347, U+834b-834c, U+834f, U+8351, U+8356-8358, U+835a, U+835e-8361, U+8363-8364, U+8367-8368, U+836b, U+836f, U+8373, U+8375;
}
/* [42] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.42.woff2) format("woff2");
  unicode-range: U+8113, U+8115, U+8117-8118, U+811a-811b, U+811e, U+8121-8127, U+8129, U+812c, U+812f-8134, U+8137-8138, U+813a, U+813d, U+8144, U+8146, U+8148, U+814a, U+814c-814d, U+8151, U+8153, U+8156, U+8158, U+815a, U+8160, U+8167, U+8169, U+816d, U+816f, U+8171, U+8174, U+817b-817c, U+817e, U+8182, U+8188, U+818a, U+8194-8195, U+8198, U+819b, U+819e, U+81a3, U+81a6-81a7, U+81ab, U+81af-81b0, U+81b5-81b6, U+81b8, U+81ba-81bb, U+81be-81bf, U+81c3, U+81c6, U+81ca, U+81cc, U+81cf, U+81d1-81d3, U+81d6-81d7, U+81d9-81da, U+81dd-81de, U+81e0-81e2, U+81e7, U+81ec, U+81ef, U+81fc, U+81fe, U+8200-8202, U+8204-8206, U+820b, U+820e, U+8210, U+8215, U+8217-8218, U+821b, U+821d, U+8221-8222, U+8224, U+8228-8229, U+822b, U+822f-8234, U+8236-8238, U+823a, U+823e, U+8240, U+8244-8245, U+8249, U+824b, U+824e-824f, U+8257, U+825a, U+825f, U+8264, U+8268, U+826b;
}
/* [43] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.43.woff2) format("woff2");
  unicode-range: U+7fb1-7fb2, U+7fb4, U+7fb6, U+7fb8, U+7fbc, U+7fbf-7fc0, U+7fc3, U+7fcb-7fcc, U+7fce-7fcf, U+7fd1, U+7fd5, U+7fd8, U+7fdb, U+7fde-7fdf, U+7fe5-7fe6, U+7fe9, U+7feb-7fec, U+7fee, U+7ff2-7ff3, U+7ffa, U+7ffd-7ffe, U+8002, U+8004, U+8006, U+800b, U+800e, U+8011-8012, U+8014, U+8016, U+8018-8019, U+801c, U+8024, U+8026, U+8028, U+802c, U+8030, U+8035, U+8037-8039, U+803b, U+8042-8043, U+804b-804c, U+8052, U+8061, U+8068, U+806a, U+806e, U+8071, U+8073-8076, U+8079, U+807c, U+807e-807f, U+8083-8084, U+808f, U+8093, U+8095, U+8098, U+809c, U+809f-80a0, U+80a4, U+80a7, U+80ab, U+80ad-80ae, U+80b0-80b1, U+80b4-80b6, U+80b8, U+80bc-80c2, U+80c4, U+80c6-80c7, U+80cb, U+80cd, U+80cf, U+80d4, U+80d7, U+80d9, U+80db-80dd, U+80e0, U+80e3-80e5, U+80e7, U+80eb-80ed, U+80ef-80f1, U+80f3-80f4, U+80f6-80f7, U+80fc, U+80fe-80ff, U+8101, U+8107, U+8109, U+810f-8112;
}
/* [44] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.44.woff2) format("woff2");
  unicode-range: U+7eaf, U+7eb1-7eb3, U+7eb5-7eba, U+7ebd-7ebe, U+7ec0-7ec1, U+7ec3, U+7ec5, U+7ec7-7eca, U+7ecd-7ece, U+7ed1-7ed2, U+7ed4-7ed5, U+7ed7-7ed8, U+7eda-7edb, U+7edd-7ede, U+7ee2-7ee3, U+7ee5, U+7ee7, U+7ee9-7eeb, U+7eee-7ef0, U+7ef3, U+7ef5, U+7ef7-7ef8, U+7efd-7f01, U+7f03, U+7f05-7f09, U+7f0e, U+7f10, U+7f13-7f15, U+7f18-7f1a, U+7f1c-7f1d, U+7f20, U+7f24-7f25, U+7f28-7f2a, U+7f2d-7f2e, U+7f30, U+7f34, U+7f36-7f37, U+7f3d, U+7f42-7f45, U+7f47-7f4e, U+7f52, U+7f54, U+7f58, U+7f5a, U+7f5d, U+7f5f-7f63, U+7f65, U+7f68, U+7f6b, U+7f78, U+7f7d-7f7e, U+7f81-7f83, U+7f86-7f87, U+7f8b-7f8d, U+7f91, U+7f93-7f95, U+7f97, U+7f99-7f9a, U+7f9d, U+7f9f, U+7fa1-7fa3, U+7fa5, U+7fa7, U+7fad-7fb0;
}
/* [45] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.45.woff2) format("woff2");
  unicode-range: U+7d89, U+7d8b-7d8f, U+7d91, U+7d95-7d96, U+7d98-7d9a, U+7d9d-7d9e, U+7da2-7da3, U+7da6, U+7daa, U+7dac, U+7dae-7db0, U+7db3, U+7db5, U+7db7, U+7db9, U+7dbd, U+7dc1, U+7dc3-7dc7, U+7dcc-7dcf, U+7dd1, U+7dd6-7dd9, U+7ddb-7ddc, U+7de1-7de2, U+7de6, U+7df0-7df3, U+7df6, U+7dfe, U+7e01-7e02, U+7e04, U+7e08-7e0b, U+7e10-7e11, U+7e13, U+7e15, U+7e1d-7e20, U+7e22, U+7e25-7e27, U+7e29, U+7e2d, U+7e2f-7e30, U+7e32-7e37, U+7e39, U+7e3b, U+7e44-7e45, U+7e47-7e48, U+7e4a-7e4b, U+7e4d, U+7e50-7e52, U+7e56, U+7e58-7e5b, U+7e62, U+7e68, U+7e6d-7e70, U+7e76, U+7e78, U+7e7b, U+7e7e, U+7e81-7e82, U+7e86-7e88, U+7e8a, U+7e8d-7e8e, U+7e92-7e94, U+7e98-7e9b, U+7e9e, U+7ea0, U+7ea3-7ea4, U+7ea8, U+7eaa-7eae;
}
/* [46] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.46.woff2) format("woff2");
  unicode-range: U+7c20, U+7c23, U+7c25-7c26, U+7c2a-7c2b, U+7c37-7c39, U+7c40-7c41, U+7c48-7c49, U+7c50, U+7c53-7c54, U+7c56-7c57, U+7c59-7c5c, U+7c5f, U+7c63, U+7c65, U+7c69, U+7c6c-7c6e, U+7c74-7c75, U+7c79, U+7c7c, U+7c7e, U+7c84, U+7c8b, U+7c8d, U+7c91, U+7c94-7c95, U+7c9b, U+7c9f, U+7ca2, U+7ca4, U+7ca6, U+7ca8-7caa, U+7cae, U+7cb1-7cb3, U+7cba, U+7cbc, U+7cbf-7cc0, U+7cc5, U+7cc8-7cc9, U+7ccc-7cce, U+7cd7, U+7cdc-7cdd, U+7ce0, U+7ce2, U+7ce8, U+7cea, U+7ced, U+7cf2, U+7cf4, U+7cf6, U+7cf8-7cfa, U+7cfc, U+7d02, U+7d06-7d0a, U+7d0f, U+7d11-7d12, U+7d15, U+7d18, U+7d1c-7d1e, U+7d25, U+7d27, U+7d29, U+7d2c, U+7d31-7d32, U+7d35, U+7d38, U+7d3a, U+7d3c, U+7d3e-7d41, U+7d43, U+7d45, U+7d4c, U+7d4e-7d4f, U+7d53-7d54, U+7d56, U+7d5b-7d5d, U+7d5f, U+7d63, U+7d67, U+7d6a, U+7d6d, U+7d70, U+7d73, U+7d75, U+7d79-7d7b, U+7d7d, U+7d80, U+7d83-7d84, U+7d86-7d88;
}
/* [47] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.47.woff2) format("woff2");
  unicode-range: U+7afb, U+7afd-7afe, U+7b01-7b06, U+7b09-7b0b, U+7b0e-7b10, U+7b14, U+7b18, U+7b1a, U+7b1e-7b1f, U+7b22-7b25, U+7b29-7b2b, U+7b2d-7b2e, U+7b31-7b35, U+7b38-7b3c, U+7b45, U+7b47-7b48, U+7b4a, U+7b4c, U+7b4e-7b50, U+7b58, U+7b5b, U+7b5d, U+7b60, U+7b62, U+7b65-7b67, U+7b69, U+7b6d-7b6f, U+7b72-7b76, U+7b79, U+7b7e, U+7b82, U+7b84-7b85, U+7b87, U+7b8b, U+7b8d-7b93, U+7b95-7b96, U+7b98, U+7b9c-7b9d, U+7ba0, U+7ba7, U+7ba9-7bac, U+7bb0, U+7bb4, U+7bb6, U+7bb8-7bb9, U+7bc1, U+7bc3, U+7bc6, U+7bcb-7bcc, U+7bcf, U+7bd4, U+7bd9-7bdb, U+7bdd, U+7be0-7be1, U+7be5-7be6, U+7bea, U+7bed-7bee, U+7bf1-7bf3, U+7bf8-7bf9, U+7bfc-7c01, U+7c03, U+7c07, U+7c0a-7c0d, U+7c0f, U+7c11-7c12, U+7c15, U+7c1e-7c1f;
}
/* [48] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.48.woff2) format("woff2");
  unicode-range: U+7988, U+798a-798b, U+7991, U+7993-7996, U+7998, U+799a-799b, U+799f-79a2, U+79a4, U+79a8-79a9, U+79af-79b0, U+79b3, U+79b5, U+79b8, U+79ba, U+79c3, U+79c6, U+79c8, U+79cf, U+79d5-79d6, U+79dc-79de, U+79e3, U+79e7, U+79ea-79ed, U+79ef-79f0, U+79f4, U+79f6-79f8, U+79fd, U+7a02-7a03, U+7a08-7a0a, U+7a0c, U+7a0e, U+7a10-7a11, U+7a14, U+7a17-7a19, U+7a1c, U+7a1e-7a1f, U+7a23, U+7a26, U+7a2d, U+7a32-7a33, U+7a37, U+7a39, U+7a3c, U+7a42, U+7a45, U+7a49, U+7a4f, U+7a56, U+7a5a, U+7a5c, U+7a60-7a61, U+7a63, U+7a68, U+7a6d-7a6e, U+7a70-7a71, U+7a77-7a79, U+7a80, U+7a83, U+7a85-7a86, U+7a88, U+7a8d, U+7a90-7a91, U+7a93-7a96, U+7a98, U+7a9c-7a9d, U+7aa0, U+7aa3, U+7aa5-7aa6, U+7aa8, U+7aaa, U+7aac, U+7ab0, U+7ab3, U+7ab6, U+7ab8, U+7abb, U+7abe-7abf, U+7ac2, U+7ac8-7ac9, U+7ad1-7ad2, U+7ad6, U+7ada, U+7adc-7ade, U+7ae4, U+7ae6, U+7ae9-7aeb, U+7af4, U+7af8, U+7afa;
}
/* [49] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.49.woff2) format("woff2");
  unicode-range: U+7840, U+7842-7843, U+7845, U+7847, U+784a, U+784c-7850, U+7852-7853, U+7855, U+7858, U+785a, U+785c-785d, U+7864, U+7866, U+7868, U+786a, U+786f, U+7874, U+7876, U+787c, U+787f, U+7886-7887, U+7889, U+788d, U+788f, U+7893, U+7895-7896, U+7898, U+789a, U+789e, U+78a1, U+78a3, U+78a5, U+78aa, U+78ad, U+78b1-78b2, U+78b4, U+78b6, U+78b8, U+78be, U+78c8-78c9, U+78cb, U+78ce, U+78d0-78d1, U+78d4-78d5, U+78d7-78d8, U+78de, U+78e3, U+78e6-78e7, U+78ea, U+78ec, U+78f2-78f4, U+78fa-78fb, U+78fd-7900, U+7904-7906, U+790a, U+790c, U+7910-7912, U+791c, U+791e, U+7920-7921, U+792a-792c, U+792e, U+7931, U+7934, U+7938, U+793b, U+793d, U+793f, U+7941-7942, U+7945-7947, U+7949, U+794c, U+794e, U+7953-7954, U+7957-795c, U+795f, U+7961-7962, U+7964, U+7967, U+7969, U+796b-796c, U+796f, U+7972-7973, U+7977-7979, U+797b-797c, U+797e, U+7980, U+7982, U+7984-7987;
}
/* [50] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.50.woff2) format("woff2");
  unicode-range: U+7705, U+7707-7708, U+770a, U+770c, U+770f, U+7715, U+7719-771b, U+771d-771e, U+7722, U+7725-7726, U+7728, U+772d, U+772f, U+7733-7736, U+7738, U+773b, U+773d, U+7741, U+7744, U+7746, U+774c, U+774e-7752, U+7755, U+7759-775a, U+775f-7760, U+7762, U+7765-7766, U+7768-776a, U+776c-776e, U+7771, U+7778, U+777a, U+777d-777e, U+7780, U+7785, U+7787, U+7789, U+778b-778d, U+7791-7793, U+779c, U+779f-77a0, U+77a2, U+77a5, U+77a9, U+77b0-77b1, U+77b4, U+77b6-77b7, U+77b9, U+77bc-77bf, U+77c5, U+77c7, U+77cc-77cd, U+77d3, U+77d6-77d7, U+77dc, U+77de, U+77e3, U+77e7, U+77eb-77ec, U+77f0, U+77f2, U+77f6, U+77f8, U+77fa-77fc, U+77fe-7800, U+7803, U+7805-7806, U+7809, U+7810-7812, U+7815-7816, U+781a, U+781c-781d, U+781f-7823, U+7825-7827, U+7829, U+782c-7830, U+7833, U+7835, U+7837, U+7839-783a, U+783c, U+783e;
}
/* [51] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.51.woff2) format("woff2");
  unicode-range: U+75cc-75cd, U+75d2, U+75d4, U+75d9, U+75df, U+75e2-75e4, U+75e6-75e7, U+75e9-75ec, U+75f0-75f3, U+75f7, U+75f9-75fa, U+75fc, U+75fe-7602, U+7608-760a, U+760c-760d, U+7610, U+7615-7616, U+7618-7620, U+7622-7623, U+7625, U+7627, U+7629, U+762b, U+762e, U+7630, U+7632-7635, U+7638, U+763a-763c, U+763e, U+7640, U+7643, U+7646, U+7648-7649, U+764d-764e, U+7654, U+7658, U+765c, U+765f, U+7663-7667, U+7669, U+766b-766d, U+766f-7670, U+7676, U+7678-767a, U+767f-7681, U+7683, U+7688, U+768a-768b, U+768e, U+7690, U+7695-7696, U+769a-769e, U+76a3-76a4, U+76aa, U+76b0-76b1, U+76b4, U+76b7-76b8, U+76c2, U+76c5, U+76c9, U+76cc-76cd, U+76cf-76d1, U+76d6-76d8, U+76e5-76e6, U+76e9, U+76ec, U+76f1, U+76f7, U+76f9-76fb, U+76ff-7700, U+7704;
}
/* [52] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.52.woff2) format("woff2");
  unicode-range: U+749a, U+749c-74a1, U+74a3, U+74a5-74a7, U+74a9-74ab, U+74ae, U+74b1-74b2, U+74b5, U+74b8, U+74ba, U+74bf, U+74c5, U+74c8, U+74cc-74cd, U+74d2, U+74d4, U+74d6, U+74d8, U+74da, U+74de-74e0, U+74e2, U+74e4, U+74e8-74e9, U+74ee-74ef, U+74f4, U+74f9, U+74fb, U+74ff-7501, U+7503, U+7507, U+750c-750d, U+7511, U+7513, U+7515-7517, U+7519, U+751e, U+7521, U+7525, U+752a, U+752c-752f, U+7534, U+753e, U+7542, U+7545, U+7547-7548, U+754a-754b, U+754e, U+7551, U+755a-755b, U+755d, U+7560, U+7563-7564, U+7566-7568, U+756c-756f, U+7572-7575, U+7577-757a, U+757c, U+757e-757f, U+7583-7584, U+7587, U+7589, U+758b-758e, U+7590, U+7592, U+7594-7595, U+7597, U+7599-759a, U+759d, U+759f, U+75a1-75a3, U+75a5, U+75a7, U+75aa, U+75ac, U+75ae-75b1, U+75b3-75b4, U+75b8, U+75bd, U+75c0, U+75c2-75c4, U+75c9-75ca;
}
/* [53] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.53.woff2) format("woff2");
  unicode-range: U+73b3-73ba, U+73bc, U+73bf, U+73c2, U+73c4-73c6, U+73c9, U+73cb-73cc, U+73ce-73d2, U+73d6-73d7, U+73d9, U+73db-73de, U+73e3, U+73e5-73eb, U+73ef, U+73f5-73f7, U+73f9-73fa, U+73fc-73fd, U+7400-7401, U+7404-7405, U+7407-7408, U+740a-740d, U+740f-7410, U+7416, U+741a-741b, U+741d-741e, U+7420-7425, U+7428-7429, U+742c-7432, U+7435-7436, U+7438-743a, U+743c-7442, U+7445-7446, U+7448-744a, U+7451-7452, U+7454, U+7457, U+7459, U+745d, U+7460-7462, U+7465, U+7467-7468, U+746c-746e, U+7471-7477, U+7479-747a, U+747c-747f, U+7481-7482, U+7484-7486, U+7488-748a, U+748e-7490, U+7492, U+7498;
}
/* [54] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.54.woff2) format("woff2");
  unicode-range: U+727f, U+7281-7282, U+7284, U+7287, U+728a, U+728d, U+7292, U+7296, U+7298, U+729b, U+729f-72a2, U+72ad-72ae, U+72b0-72b5, U+72b8-72b9, U+72bc-72bd, U+72c1, U+72c3, U+72c5-72c6, U+72c8, U+72cc-72ce, U+72d2, U+72d4, U+72db, U+72dd, U+72df, U+72e1, U+72e8, U+72ec-72ee, U+72f1, U+72f3-72f4, U+72f7, U+72fa-72fb, U+72fd, U+7300-7301, U+7304, U+7307, U+730a-730b, U+730e, U+7313, U+7315-7317, U+7319, U+731e-731f, U+7322, U+7328-732e, U+7330-7331, U+7337, U+733a-733c, U+733e, U+7340-7341, U+7343, U+734c-734d, U+734f-7350, U+7352, U+7355, U+7357, U+7359-735a, U+7360-7363, U+7365, U+7369-7370, U+7373-7374, U+7377, U+737a, U+737c, U+737e, U+7380, U+7385-7386, U+738a, U+738e-738f, U+7391-7395, U+7397-7398, U+739b, U+73a0-73a2, U+73a5-73a8, U+73ad-73ae;
}
/* [55] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.55.woff2) format("woff2");
  unicode-range: U+7113-7116, U+7118, U+711c, U+711e, U+7120, U+7122, U+712e-712f, U+7131, U+7134, U+713c, U+713f, U+7143, U+7145-7147, U+714a-714b, U+7150-7153, U+7155-7156, U+715a, U+7160, U+7162, U+7166, U+7168, U+716c, U+7171, U+7173, U+7178, U+717a-717b, U+717d, U+7180-7181, U+7185, U+7187-7188, U+718b, U+718f-7190, U+7192, U+7196-7198, U+719a-719c, U+71a0, U+71a4, U+71a8, U+71af, U+71b2-71b3, U+71b5, U+71b7-71ba, U+71be, U+71c1, U+71c4, U+71ca-71cb, U+71ce-71d0, U+71d4, U+71d7-71d8, U+71da, U+71dc, U+71e0-71e1, U+71e7, U+71ec, U+71f4-71f6, U+71f9, U+71fc, U+71fe-7201, U+7203, U+7207, U+7209, U+720c, U+7213-7215, U+7217, U+721a, U+721d, U+7222-7223, U+7228, U+722b, U+7230, U+7237, U+723b, U+723f-7242, U+724b, U+724d, U+7252-7253, U+7256, U+7258, U+725d, U+7263-7266, U+726a-726b, U+726e-7270, U+7273-7275, U+7277, U+727a-727b, U+727e;
}
/* [56] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.56.woff2) format("woff2");
  unicode-range: U+6fe8-6fe9, U+6fec, U+6fee, U+6ff0, U+6ff3, U+6ff5-6ff6, U+6ffa, U+6ffc, U+6fff-7001, U+7003, U+7005-7007, U+700b, U+700d, U+7015, U+7018, U+701b, U+701e, U+7020-7021, U+7023, U+7026-7027, U+702c, U+702f-7032, U+7034-7035, U+7037-703c, U+7040, U+7042-7044, U+7046, U+7049, U+704b, U+704f, U+7052, U+7054-7055, U+705c-7061, U+7064-7069, U+706c-706d, U+706f, U+7073-7075, U+7078, U+707a, U+707e-7081, U+7085-7086, U+7089, U+7090-7091, U+7094-7096, U+7098, U+709c, U+709f, U+70a1, U+70a4, U+70a9, U+70ac, U+70af-70b2, U+70b4-70b5, U+70b7, U+70bb-70be, U+70c0-70c3, U+70ca-70cb, U+70d2, U+70d4-70d5, U+70d9-70dd, U+70df, U+70e6-70e9, U+70eb-70ec, U+70ef, U+70f1, U+70f4, U+70f7, U+70fa, U+70fd, U+70ff, U+7104, U+7106, U+7109, U+710c, U+7110;
}
/* [57] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.57.woff2) format("woff2");
  unicode-range: U+6ed6, U+6ed8, U+6eda-6edb, U+6edd-6ede, U+6ee2, U+6ee4-6ee5, U+6ee8-6ee9, U+6eeb, U+6eee, U+6ef3, U+6ef8-6efb, U+6f00, U+6f08-6f0a, U+6f0d-6f0e, U+6f11-6f13, U+6f15, U+6f19-6f1a, U+6f23, U+6f25-6f2a, U+6f2d-6f31, U+6f33-6f36, U+6f3a-6f3c, U+6f40-6f41, U+6f43-6f44, U+6f47, U+6f4d-6f4f, U+6f53, U+6f57, U+6f59-6f5a, U+6f5c, U+6f5e-6f61, U+6f63, U+6f66-6f67, U+6f69-6f6c, U+6f6f, U+6f72-6f78, U+6f7a-6f7f, U+6f81-6f82, U+6f87, U+6f89, U+6f8b-6f8d, U+6f90, U+6f92, U+6f94-6f97, U+6f9c, U+6f9f-6fa0, U+6fa2-6fa3, U+6fa5-6fa8, U+6faa-6fab, U+6fae-6faf, U+6fb4, U+6fb6, U+6fb9-6fba, U+6fbc, U+6fc2, U+6fc6-6fcb, U+6fce, U+6fd1-6fd2, U+6fd4, U+6fd8, U+6fda, U+6fde, U+6fe0-6fe2;
}
/* [58] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.58.woff2) format("woff2");
  unicode-range: U+6dd3, U+6dd5-6dd6, U+6dd9, U+6ddb-6de0, U+6de2-6de6, U+6de9, U+6dec, U+6def-6df0, U+6df2, U+6df4, U+6df6, U+6df8, U+6dfc-6dfd, U+6e00, U+6e02-6e03, U+6e07-6e0b, U+6e0d-6e0e, U+6e10, U+6e13-6e15, U+6e17, U+6e19-6e1a, U+6e1d, U+6e1f, U+6e22, U+6e24-6e25, U+6e27, U+6e2b, U+6e2d-6e2e, U+6e30-6e31, U+6e36, U+6e39-6e3a, U+6e3c-6e3d, U+6e40-6e41, U+6e44-6e45, U+6e47, U+6e49, U+6e4b, U+6e4d-6e4f, U+6e51, U+6e53-6e54, U+6e59, U+6e5c, U+6e5e-6e61, U+6e63-6e66, U+6e69-6e6b, U+6e6e, U+6e70-6e76, U+6e78, U+6e7c, U+6e7f-6e80, U+6e83, U+6e85-6e86, U+6e88-6e89, U+6e8b, U+6e8d-6e8f, U+6e93, U+6e98-6e9a, U+6e9f, U+6ea1, U+6ea4-6ea7, U+6eae, U+6eb1-6eb2, U+6eb4-6eb5, U+6eb7, U+6ebd, U+6ec1-6ec3, U+6ec7-6ec9, U+6ecd-6ed0, U+6ed3-6ed5;
}
/* [59] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.59.woff2) format("woff2");
  unicode-range: U+6cda, U+6cdc-6cde, U+6ce0, U+6ce7, U+6ce9-6cec, U+6cee-6cef, U+6cf1, U+6cf7-6cf8, U+6cfb-6cfe, U+6d00-6d02, U+6d04, U+6d06-6d07, U+6d09-6d0a, U+6d0c, U+6d0e-6d12, U+6d18-6d1a, U+6d1f, U+6d22-6d24, U+6d27-6d28, U+6d2b, U+6d2d-6d31, U+6d33-6d3a, U+6d3c, U+6d3f, U+6d43-6d47, U+6d4a-6d4b, U+6d4e-6d4f, U+6d51-6d53, U+6d57-6d58, U+6d5a, U+6d5c, U+6d5e-6d65, U+6d67, U+6d6c-6d6d, U+6d6f-6d70, U+6d72, U+6d75, U+6d79, U+6d7c, U+6d7f, U+6d82, U+6d85, U+6d87, U+6d8e-6d8f, U+6d91-6d95, U+6d97-6d99, U+6d9b, U+6d9d, U+6d9f, U+6da1, U+6da4, U+6da6-6dac, U+6db3-6db4, U+6db7-6db8, U+6dbe-6dc0, U+6dc2, U+6dc4-6dc5, U+6dc8-6dca, U+6dcc-6dcd, U+6dcf-6dd0, U+6dd2;
}
/* [60] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.60.woff2) format("woff2");
  unicode-range: U+6bbb, U+6bbd, U+6bc1-6bc2, U+6bcc, U+6bce, U+6bd0-6bd1, U+6bd5-6bd9, U+6bde, U+6be1, U+6bec, U+6bf3, U+6bf9, U+6bfd, U+6bff-6c00, U+6c02, U+6c05-6c06, U+6c0a, U+6c0c-6c0d, U+6c10, U+6c13, U+6c16, U+6c18-6c1a, U+6c1f, U+6c21-6c22, U+6c24, U+6c26, U+6c28-6c2a, U+6c2c, U+6c2e-6c33, U+6c35-6c37, U+6c39-6c3a, U+6c3d-6c3f, U+6c43, U+6c46, U+6c49-6c4f, U+6c54-6c55, U+6c58, U+6c5a-6c5c, U+6c5e, U+6c64-6c69, U+6c6b-6c6f, U+6c71-6c75, U+6c78-6c79, U+6c7c, U+6c7e, U+6c82, U+6c84-6c87, U+6c8c-6c8d, U+6c8f, U+6c93-6c94, U+6c98, U+6c9a, U+6c9d, U+6c9f, U+6ca2, U+6ca5-6ca8, U+6caa, U+6cac-6cb2, U+6cb4-6cb5, U+6cba, U+6cbc, U+6cc2-6cc3, U+6cc5-6cc7, U+6cd0-6cd2, U+6cd4, U+6cd6-6cd7, U+6cd9;
}
/* [61] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.61.woff2) format("woff2");
  unicode-range: U+6a31-6a32, U+6a35, U+6a3b, U+6a3e-6a40, U+6a47-6a48, U+6a50, U+6a52, U+6a55-6a57, U+6a5a-6a5b, U+6a5e, U+6a62, U+6a66, U+6a6a, U+6a71, U+6a79, U+6a7c, U+6a7e-6a81, U+6a84, U+6a87, U+6a89, U+6a8d-6a8e, U+6a90-6a92, U+6a97, U+6a9c, U+6a9e-6aa1, U+6aa3-6aa5, U+6aa8, U+6aab, U+6aae, U+6ab5, U+6aba, U+6abe, U+6ac2, U+6ac5-6ac6, U+6ac8-6ac9, U+6acc, U+6ad3, U+6ada-6adb, U+6add-6adf, U+6ae3, U+6ae7-6ae8, U+6aea-6aec, U+6af1, U+6af3, U+6af8, U+6afa, U+6afc, U+6b05, U+6b09, U+6b0e-6b13, U+6b17, U+6b1d-6b1e, U+6b25, U+6b2c, U+6b31, U+6b35-6b37, U+6b39, U+6b3b, U+6b40, U+6b43, U+6b46, U+6b48, U+6b53-6b55, U+6b59, U+6b5b, U+6b5f-6b60, U+6b68-6b69, U+6b6f, U+6b74, U+6b7a, U+6b7c, U+6b7f-6b84, U+6b86-6b87, U+6b89, U+6b8b, U+6b8d, U+6b91-6b93, U+6b9b, U+6b9e, U+6ba1-6ba2, U+6ba4, U+6baa-6bab, U+6bad-6bae, U+6bb2-6bb4;
}
/* [62] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.62.woff2) format("woff2");
  unicode-range: U+6900, U+6902, U+6904, U+6906-6907, U+6909, U+690b, U+690f-6910, U+6917, U+691a-691c, U+6925, U+692a, U+692c-692d, U+6932, U+6934, U+6939, U+693c-6940, U+6942, U+6949, U+6952, U+6954-6957, U+6959, U+695b-695f, U+6961-696c, U+696e-6970, U+6973-6974, U+6976, U+6978-697a, U+697c, U+6980, U+6984-6986, U+6988-698a, U+698d-698e, U+6990-6991, U+6994, U+6996-699b, U+699e, U+69a3-69a7, U+69ab, U+69ad, U+69af, U+69b1, U+69b3, U+69b6-69b7, U+69bb-69bc, U+69bf, U+69c1, U+69c3-69c5, U+69c7, U+69ca, U+69cc, U+69ce, U+69d0-69d1, U+69d4, U+69d8-69d9, U+69db, U+69df, U+69e4, U+69e8-69ea, U+69ed-69ee, U+69f1-69f4, U+69f6, U+69f8, U+69fa-69fb, U+69ff-6a00, U+6a05, U+6a0a-6a0b, U+6a17-6a18, U+6a1b, U+6a28-6a2b;
}
/* [63] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.63.woff2) format("woff2");
  unicode-range: U+67d9, U+67db-67e0, U+67e2, U+67e4, U+67e9-67ea, U+67f0, U+67f2, U+67f6-67f8, U+67fa-67fb, U+67fe, U+6800-6805, U+6808-6809, U+680b, U+680e-680f, U+6811-6812, U+6814, U+6816, U+6818, U+681c-681e, U+6820, U+6822, U+6825, U+6827-6829, U+682b, U+682e-682f, U+6831-6834, U+683a-683b, U+683e, U+6840-6841, U+6844-6845, U+6849, U+684e, U+6853, U+6855-6856, U+685c-685d, U+685f, U+6861-6863, U+6865-6869, U+686b, U+686d, U+686f, U+6871-6872, U+6874-6875, U+6877, U+6879, U+687b-687c, U+687e, U+6880, U+6882-6883, U+6886, U+688f, U+6891-6892, U+6894, U+6896, U+6898, U+689b-689c, U+689f-68a0, U+68a2-68a3, U+68a6, U+68a9, U+68b1-68b2, U+68b4, U+68b6, U+68c0, U+68c3, U+68c6, U+68c8, U+68ca, U+68d0-68d1, U+68d3, U+68d6, U+68e1, U+68e3, U+68e6, U+68e8-68ec, U+68ef-68f1, U+68f3, U+68f6-68f7, U+68f9, U+68fb-68fd;
}
/* [64] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.64.woff2) format("woff2");
  unicode-range: U+66a7, U+66aa, U+66b2-66b3, U+66b5, U+66b8-66bc, U+66be, U+66c1, U+66c4, U+66c7-66c8, U+66cc, U+66cf, U+66d5, U+66d8-66db, U+66df, U+66e1-66e2, U+66e8-66e9, U+66ef, U+66f1, U+66f5, U+66f7, U+66fa, U+66fd, U+6705, U+670a, U+670f-6710, U+6713-6715, U+6718-6719, U+6720, U+6722-6727, U+6729, U+672e, U+6733, U+6736, U+6738-6739, U+673f-6740, U+6742, U+6745, U+6747-6748, U+674b-674d, U+6753, U+6755, U+6759, U+675d-675e, U+6760, U+6762-6763, U+6767-676a, U+676c, U+676e, U+6772-6777, U+677a-677c, U+6782, U+6786-6787, U+678a-678c, U+678e-678f, U+6791-6793, U+6796, U+6798-6799, U+679f-67a3, U+67a5, U+67aa-67ae, U+67b0, U+67b2-67b5, U+67b7-67bc, U+67c0-67c3, U+67c5-67c6, U+67c8-67ca, U+67ce, U+67d2, U+67d8;
}
/* [65] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.65.woff2) format("woff2");
  unicode-range: U+655d, U+6561, U+6564-6565, U+6567, U+656b, U+656d-656e, U+6573, U+6576, U+6579-657b, U+6581, U+6586, U+6588-6589, U+658b, U+658e, U+6593, U+6595, U+659b, U+659d, U+659f-65a1, U+65a9, U+65ab, U+65ad, U+65b2-65b3, U+65b5, U+65bb, U+65be-65bf, U+65c2-65c4, U+65c6, U+65cc, U+65ce, U+65d2, U+65d6, U+65db, U+65e1, U+65e3, U+65e7, U+65ee-65f0, U+65f2-65f4, U+65f7-65f8, U+65fc-65fd, U+6600, U+6603-6605, U+6609, U+660d, U+6610-6611, U+6619, U+661c-661e, U+6621-6622, U+6624, U+6626, U+6629, U+662b, U+6630, U+6633-6636, U+6639-663d, U+6640-6641, U+6644-6645, U+664a-664c, U+6653-6657, U+6659, U+665b, U+665d-665e, U+6661-6667, U+6669, U+666c, U+6672-6673, U+6677-6679, U+667b-667e, U+6681-6684, U+668b-6690, U+6692, U+6698, U+669d, U+669f-66a0, U+66a6;
}
/* [66] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.66.woff2) format("woff2");
  unicode-range: U+6427-6428, U+642a-642b, U+642f-6430, U+6432, U+6434, U+6437, U+643a, U+643d-6444, U+6446-6447, U+644a-644b, U+644e, U+6450-6453, U+6456, U+6459, U+645b-645c, U+645e, U+6460-6461, U+6463-6465, U+6468, U+646c-646e, U+6470, U+6472-6477, U+6479, U+647b, U+647d, U+6480, U+6482, U+6485, U+648b-648c, U+6491, U+6493, U+6496-649a, U+649d, U+649f-64a0, U+64a2-64a3, U+64ac, U+64b1, U+64b3-64b4, U+64b7-64b9, U+64bb, U+64be, U+64c0, U+64c3-64c4, U+64d0, U+64d2, U+64d5, U+64d7-64d8, U+64e1-64e4, U+64e7, U+64e9, U+64ed, U+64ef-64f0, U+64f3, U+64f8, U+64fb-64fc, U+64ff, U+6504-6506, U+6509, U+6511-6512, U+6516, U+6518-6519, U+651b, U+6520-6523, U+6525-6526, U+6529, U+652b, U+652e, U+6530, U+6532, U+6534-6535, U+6537-6538, U+653a, U+653d, U+6542-6543, U+6549, U+654c-654e, U+6554-6555, U+655b;
}
/* [67] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.67.woff2) format("woff2");
  unicode-range: U+630d-630e, U+6310, U+6312-6313, U+6319-631b, U+631d-6321, U+6323-6325, U+632d-632e, U+6332, U+6334-6339, U+633b-633c, U+633e-6340, U+6342-6346, U+634b-634c, U+634e, U+6352, U+6357, U+635a, U+635c, U+635e-635f, U+6361, U+6363, U+6365, U+6369, U+636b-636d, U+636f-6370, U+6373, U+6375-6376, U+637a-637b, U+637d, U+6381, U+6384, U+6387, U+638a, U+638d-638e, U+6390, U+6394-6397, U+639e-639f, U+63a3-63a4, U+63a6, U+63ac-63af, U+63b1-63b4, U+63b7, U+63b9-63bb, U+63bd-63be, U+63c3-63c4, U+63c8, U+63cd-63ce, U+63d1, U+63d6, U+63dc, U+63de, U+63e0, U+63e3-63e4, U+63e6, U+63e9, U+63f0, U+63f2-63f3, U+63f5-63f8, U+63fa, U+63fc-63fe, U+6400-6402, U+6405-6406, U+6409-640c, U+6410, U+6414-6415, U+6418, U+641b, U+641f-6423, U+6425-6426;
}
/* [68] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.68.woff2) format("woff2");
  unicode-range: U+61c6, U+61ca-61cd, U+61d0, U+61d2, U+61de-61e0, U+61e3, U+61e6, U+61e8, U+61ed-61ee, U+61f5, U+61f9-61fa, U+61fd-61fe, U+6207, U+6209, U+620d-620e, U+6213-6215, U+6219, U+621b, U+621d-6223, U+6225-6227, U+6229, U+622b-622c, U+622e-622f, U+6231, U+6238, U+623b, U+623d-623e, U+6242-6243, U+6246, U+6248-6249, U+624c, U+6251, U+6255, U+6259-625a, U+625e, U+6260-6262, U+6265-6267, U+6269, U+626b-626c, U+6270-6273, U+6275, U+627a-627d, U+6283, U+6285-6286, U+6289, U+628c, U+628e, U+6294, U+629a-629e, U+62a0, U+62a2, U+62a6, U+62a8, U+62af, U+62b3, U+62b6, U+62ba-62bb, U+62be-62bf, U+62c2, U+62c4-62c5, U+62c8, U+62ca, U+62cf, U+62d1, U+62d5, U+62d7, U+62d9, U+62dd, U+62df-62e3, U+62e5-62e8, U+62ee, U+62f4-62fb, U+62fd, U+6300, U+6302, U+6308, U+630c;
}
/* [69] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.69.woff2) format("woff2");
  unicode-range: U+60ac-60ad, U+60af-60b1, U+60b3-60b5, U+60b8, U+60bb, U+60bd-60be, U+60c0, U+60c6-60c7, U+60ca-60cb, U+60d3-60d5, U+60d7-60db, U+60dd, U+60e2-60e3, U+60e6-60f0, U+60f2, U+60f4, U+60f6, U+60fa-60fb, U+60ff-6100, U+6103, U+6106, U+610a-610b, U+610d-610e, U+6110, U+6112-6116, U+6120, U+6123-6124, U+6128-6130, U+6134, U+6136, U+613c-613f, U+6144, U+6146-6147, U+6149-614a, U+614d, U+6151-6153, U+6159-615a, U+615c-615f, U+6164-6165, U+6169-616d, U+616f, U+6171-6175, U+6177, U+617a, U+617c, U+617f-6180, U+6187, U+618a-618e, U+6192-6194, U+6199-619b, U+619f, U+61a1, U+61a7-61a8, U+61aa-61af, U+61b8, U+61ba, U+61bf, U+61c3;
}
/* [70] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.70.woff2) format("woff2");
  unicode-range: U+5f78, U+5f7b, U+5f7d, U+5f82-5f84, U+5f87, U+5f89-5f8a, U+5f8d, U+5f93, U+5f95, U+5f98-5f99, U+5f9b-5f9c, U+5fa0, U+5fa4, U+5fa6-5fa8, U+5fab-5fad, U+5fb3-5fb4, U+5fbc, U+5fc4, U+5fc6, U+5fc9, U+5fcb, U+5fce-5fd6, U+5fdc-5fdf, U+5fe1, U+5fe4, U+5fe7, U+5fea, U+5fec-5fee, U+5ff1, U+5ff3, U+5ff8, U+5ffa-5ffc, U+5fff-6000, U+6002, U+6005, U+600a, U+600d, U+600f-6010, U+6014, U+6017, U+6019-601c, U+601e, U+6020, U+6022, U+6026, U+6029, U+602b-602c, U+602e-602f, U+6031, U+6033-6035, U+6039, U+603c, U+6040-6043, U+6045, U+6047, U+604a-604c, U+604f, U+6053, U+6059-605b, U+605d, U+6060, U+6063, U+6067, U+606a-606b, U+606e, U+6072-6076, U+6078, U+607a, U+607c, U+607f-6081, U+6083, U+6086, U+608a, U+608c, U+608e, U+6092-6093, U+6095-6097, U+609b, U+609d, U+60a2, U+60a6-60a7, U+60a9-60aa;
}
/* [71] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.71.woff2) format("woff2");
  unicode-range: U+5e34, U+5e37, U+5e3a, U+5e3c, U+5e40, U+5e42-5e44, U+5e47, U+5e54, U+5e57-5e5b, U+5e5e-5e5f, U+5e61-5e62, U+5e64, U+5e6a, U+5e6c, U+5e6e, U+5e75, U+5e77, U+5e7a, U+5e80-5e81, U+5e83, U+5e86, U+5e88, U+5e8b, U+5e90, U+5e92, U+5e96, U+5e99, U+5e9b, U+5e9d-5ea2, U+5ea4-5ea5, U+5eb3-5eb6, U+5eb9, U+5ebe, U+5ec3-5ec4, U+5ec6, U+5ecb-5ecd, U+5ed0-5ed2, U+5ed4-5ed5, U+5ed8-5ed9, U+5edb, U+5edd, U+5ee1, U+5ee8-5ee9, U+5eec, U+5eef-5ef0, U+5ef4-5ef5, U+5ef8, U+5efb-5efc, U+5efe, U+5f01-5f03, U+5f05, U+5f07-5f09, U+5f0b-5f0e, U+5f10-5f12, U+5f14, U+5f16, U+5f1b, U+5f1d, U+5f22, U+5f25, U+5f28-5f29, U+5f2d, U+5f2f-5f30, U+5f36, U+5f38-5f39, U+5f3c, U+5f3e, U+5f40-5f42, U+5f45-5f46, U+5f4a, U+5f50-5f52, U+5f54, U+5f56-5f58, U+5f5a-5f5e, U+5f61, U+5f63, U+5f66-5f67, U+5f6b, U+5f72-5f74, U+5f76;
}
/* [72] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.72.woff2) format("woff2");
  unicode-range: U+5ce0-5ce1, U+5ce5-5ce6, U+5ce8-5cea, U+5ced-5cee, U+5cf1, U+5cf4-5cf5, U+5cf8, U+5cfe-5d00, U+5d06, U+5d08, U+5d0a-5d0d, U+5d15, U+5d18, U+5d1a, U+5d1d, U+5d1f-5d22, U+5d24, U+5d26-5d28, U+5d2c-5d2e, U+5d33-5d35, U+5d3d, U+5d3f, U+5d42-5d43, U+5d46-5d47, U+5d49-5d4b, U+5d4e, U+5d52-5d53, U+5d57-5d59, U+5d5b-5d5c, U+5d65, U+5d68-5d69, U+5d6b-5d6c, U+5d6f, U+5d74-5d75, U+5d7e-5d7f, U+5d81-5d82, U+5d85-5d88, U+5d8b-5d8c, U+5d92, U+5d94, U+5d97, U+5d99, U+5d9d, U+5da0-5da2, U+5da7, U+5da9-5daa, U+5dae, U+5db2, U+5db4, U+5db7-5db8, U+5dbd, U+5dc2-5dc5, U+5dc9, U+5dcb-5dcd, U+5dd2, U+5dd6, U+5dd8, U+5ddb-5ddc, U+5de0, U+5de3, U+5de9, U+5df0, U+5df3, U+5df5, U+5df9, U+5dfb-5dfd, U+5e00-5e01, U+5e04-5e05, U+5e0a, U+5e11, U+5e14, U+5e18-5e1c, U+5e1f-5e22, U+5e27-5e28, U+5e2f-5e30;
}
/* [73] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.73.woff2) format("woff2");
  unicode-range: U+5b80-5b82, U+5b84, U+5b8d, U+5b92-5b93, U+5b95-5b96, U+5b9f-5ba1, U+5ba6-5ba8, U+5baa-5bad, U+5bbd-5bbe, U+5bc0-5bc1, U+5bc3, U+5bd0-5bd1, U+5bd4-5bd8, U+5bdb-5bdd, U+5be4-5be5, U+5bef, U+5bf3, U+5bfb, U+5bfe-5bff, U+5c02-5c03, U+5c05, U+5c09, U+5c0c, U+5c10, U+5c12-5c13, U+5c15, U+5c18-5c19, U+5c1b, U+5c1d-5c1f, U+5c22, U+5c25, U+5c27-5c28, U+5c2a-5c2b, U+5c34, U+5c38, U+5c3d, U+5c42, U+5c44, U+5c47, U+5c49-5c4a, U+5c50, U+5c53, U+5c58-5c59, U+5c5b, U+5c5d, U+5c61, U+5c63, U+5c68, U+5c6d-5c6e, U+5c74, U+5c79-5c84, U+5c86, U+5c88, U+5c8a-5c8d, U+5c92-5c9c, U+5ca0, U+5ca2-5ca3, U+5ca5-5ca7, U+5cab-5cad, U+5cb5, U+5cb7, U+5cba-5cbb, U+5cc1, U+5cc8, U+5cca-5ccb, U+5cce, U+5cd2, U+5cd6, U+5cd8-5cda, U+5cdf;
}
/* [74] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.74.woff2) format("woff2");
  unicode-range: U+5a51, U+5a53, U+5a56-5a57, U+5a5d-5a5e, U+5a60-5a62, U+5a65, U+5a67, U+5a6a, U+5a6c-5a6d, U+5a73-5a76, U+5a7a-5a7c, U+5a81-5a84, U+5a8c, U+5a90, U+5a93, U+5a96-5a97, U+5a9c, U+5a9e-5aa0, U+5aa4, U+5aa7, U+5aab-5aac, U+5aae-5aaf, U+5ab1, U+5ab4-5ab5, U+5ab8, U+5aba-5abc, U+5abe-5abf, U+5ac3-5ac4, U+5ac6-5acb, U+5acf-5ad2, U+5ad4-5adc, U+5ae0-5ae1, U+5ae3, U+5ae5-5ae6, U+5ae8, U+5aea-5aeb, U+5aee, U+5af0, U+5af2, U+5af5, U+5afa, U+5aff, U+5b01, U+5b05, U+5b08, U+5b0b, U+5b11, U+5b16-5b17, U+5b19, U+5b1b, U+5b1d, U+5b21-5b23, U+5b28, U+5b2a-5b2d, U+5b32, U+5b34, U+5b36-5b38, U+5b3e-5b40, U+5b43-5b46, U+5b4b-5b4c, U+5b51, U+5b53, U+5b59, U+5b5b-5b5c, U+5b62, U+5b65, U+5b6c-5b6e, U+5b70-5b73, U+5b75, U+5b7a-5b7b, U+5b7d, U+5b7f;
}
/* [75] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.75.woff2) format("woff2");
  unicode-range: U+590b-590c, U+590e, U+5910-5911, U+5914, U+5919, U+591b, U+591f, U+5923-5924, U+592c, U+5932, U+5938-593a, U+5940, U+5942, U+5944, U+594b-594c, U+594e, U+5950, U+5953, U+5956, U+5958, U+595a, U+5961, U+5966, U+5968-5969, U+596c-596d, U+5975, U+5977, U+597b-597c, U+597e, U+5980-5981, U+5986-5988, U+598a, U+598f, U+5997-5998, U+599f-59a3, U+59a6-59a7, U+59a9, U+59ab-59ac, U+59af, U+59b1-59b2, U+59b6, U+59b8, U+59ba, U+59be, U+59c1, U+59c3, U+59c7-59c9, U+59cd-59ce, U+59d2, U+59d6-59d9, U+59dd-59de, U+59e0, U+59e3-59e5, U+59e9-59eb, U+59ee, U+59f1-59f3, U+59f5-59f9, U+59fc-59fd, U+5a00, U+5a04-5a07, U+5a09, U+5a0c, U+5a11, U+5a13, U+5a16-5a17, U+5a1a, U+5a1e, U+5a20, U+5a23-5a24, U+5a29-5a2b, U+5a2d-5a2f, U+5a32-5a34, U+5a38, U+5a3c, U+5a3f-5a44, U+5a47-5a48, U+5a4a, U+5a4c-5a4d, U+5a50;
}
/* [76] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.76.woff2) format("woff2");
  unicode-range: U+57a1, U+57a4, U+57a6, U+57a9, U+57ab, U+57b5, U+57b8-57bb, U+57c2, U+57c5-57c8, U+57cc, U+57cf, U+57d2, U+57dc-57de, U+57e1-57e2, U+57e5, U+57e7, U+57ed-57ee, U+57f0, U+57f3-57f6, U+57f8, U+57fb-57fd, U+5800-5801, U+5803-5804, U+5807, U+5809-580b, U+580d-580e, U+5810-5811, U+5814-5815, U+5819, U+581d-581e, U+5820, U+5823, U+5826, U+582c-582d, U+5830, U+583a, U+583f-5841, U+5848, U+584b, U+584d, U+584f, U+5852, U+5859-585a, U+585c, U+5861, U+5864, U+5868-5869, U+586c-586d, U+5871-5872, U+5879, U+587c-5881, U+5887-5889, U+588e, U+5890-5892, U+5896-5899, U+589d, U+58a1, U+58a3, U+58a6, U+58a9, U+58ac, U+58b0-58b1, U+58bb-58bc, U+58c2, U+58c5-58c6, U+58ca, U+58cc, U+58ce, U+58d0-58d1, U+58d5, U+58d9-58da, U+58df-58e0, U+58e9, U+58ec, U+58ee, U+58f1-58f3, U+58f6-58f7, U+58fb-58fc, U+5900, U+5902, U+5906, U+5909-590a;
}
/* [77] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.77.woff2) format("woff2");
  unicode-range: U+566f, U+5671-5672, U+5676, U+567a-567c, U+5680, U+5684-5686, U+568c, U+568e-568f, U+5692-5693, U+5697-5699, U+569c, U+569e, U+56a1-56a7, U+56a9, U+56ab-56ad, U+56af, U+56b3, U+56b5-56b6, U+56b8, U+56bf-56c1, U+56c3, U+56c5, U+56c7-56c8, U+56cb-56cc, U+56d2-56d4, U+56d6-56d9, U+56df, U+56e1-56e5, U+56ea-56ec, U+56ee-56ef, U+56f1-56f4, U+56f7, U+56f9, U+56ff-5700, U+5703-5704, U+5706-5707, U+5709-570a, U+570c, U+570f, U+5711, U+5717, U+571c, U+5723-5724, U+5727, U+5729-572a, U+572c, U+572e-572f, U+5734-5735, U+573b, U+5741, U+574b-574d, U+574f, U+5752, U+5754, U+575a-5760, U+5763, U+5768-5769, U+576b, U+576d, U+576f-5770, U+5772-5775, U+5777, U+577b-577d, U+5780, U+5784, U+5788, U+578c, U+578e, U+5792-5793, U+5795, U+579a-579b, U+579f-57a0;
}
/* [78] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.78.woff2) format("woff2");
  unicode-range: U+5551, U+5553, U+5555-5557, U+555c, U+5562-5563, U+5567, U+5569, U+556b-556c, U+5570, U+5575-5579, U+557b-557c, U+557f, U+5581, U+5583, U+5586, U+5588, U+558b, U+558f, U+5591, U+5599, U+559f, U+55a1, U+55a3, U+55a5-55a6, U+55a8-55a9, U+55ab, U+55ad, U+55b0-55b1, U+55b3, U+55b6-55b7, U+55b9, U+55bc-55bd, U+55c4-55c5, U+55c7, U+55c9, U+55cc-55cd, U+55d0, U+55d2, U+55d4-55d9, U+55db, U+55dd-55df, U+55e1-55e6, U+55e9-55ea, U+55ec, U+55ee, U+55f1-55f3, U+55f5-55f7, U+55f9-55fa, U+55fe, U+5600-5602, U+5608, U+560c, U+560f, U+5612-5613, U+5615-5616, U+5618, U+561a, U+561c, U+561e, U+5620, U+5623-5625, U+5627, U+562a, U+562c-562e, U+5630-5631, U+5635-5636, U+5638-563a, U+5640, U+5642-5643, U+5649, U+564c-5650, U+5654, U+5658-565d, U+5664-5666, U+5669, U+566b, U+566d;
}
/* [79] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.79.woff2) format("woff2");
  unicode-range: U+543d, U+543f, U+5441, U+5444-5445, U+5447, U+5449, U+544b-544d, U+5450-5455, U+5457, U+545b-545c, U+545f-5460, U+5463-5464, U+546a-5472, U+5474, U+5476, U+5478, U+547b, U+547e-547f, U+5482-5488, U+548a, U+548d-5491, U+5494, U+5498-549d, U+54a1-54a5, U+54ab, U+54ad-54af, U+54b5, U+54b7, U+54bb-54bc, U+54be-54bf, U+54ca, U+54cc, U+54cf-54d2, U+54d4, U+54d6-54d7, U+54da, U+54de-54df, U+54e2, U+54e4, U+54e7, U+54eb, U+54f3, U+54fd, U+54ff, U+5501-5502, U+5504-5506, U+550a, U+550c, U+550e-550f, U+5511-5513, U+5516-5517, U+551a-551b, U+551e, U+5520, U+5524, U+5526-5527, U+552a, U+552c-552d, U+5530, U+5532-5533, U+5535-5536, U+553b-553c, U+553e-553f, U+5541-5542, U+5544-5545, U+5547, U+5549, U+554b, U+554d-554e, U+5550;
}
/* [80] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.80.woff2) format("woff2");
  unicode-range: U+52db, U+52e0, U+52e3, U+52e6-52e7, U+52eb, U+52ed-52ee, U+52f0-52f2, U+52f7, U+52f9-52fa, U+5300-5302, U+530a-530b, U+530d, U+530f-5310, U+5315, U+531a, U+531c-531d, U+532d-532e, U+5331, U+5338, U+533b-533e, U+5344-5345, U+534b-534d, U+534f-5350, U+5358, U+535e-535f, U+5362-5364, U+5367, U+5369, U+536b-536c, U+536e-536f, U+5372, U+5374, U+5379-537a, U+537c-537d, U+5382, U+5385, U+5389, U+538b-538c, U+538e, U+5392-5396, U+5399, U+53a0-53a2, U+53a4-53a6, U+53a8-53a9, U+53ae, U+53b0, U+53b3-53b4, U+53b6-53b7, U+53b9, U+53bf, U+53c1, U+53c4, U+53ce-53cf, U+53d2, U+53d5, U+53d9-53da, U+53df-53e1, U+53e7-53e9, U+53f1, U+53f5-53f6, U+53f9, U+53fb-53fd, U+5400-5402, U+5405-5407, U+540f, U+5412, U+5414-5417, U+541a, U+5420-5421, U+5424-5425, U+5428-5429, U+542c-542f, U+5431-5432, U+5434, U+5437;
}
/* [81] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.81.woff2) format("woff2");
  unicode-range: U+518f, U+5191, U+5193, U+5195-5197, U+519a-519c, U+519e, U+51a2, U+51a6-51a9, U+51ab, U+51ad-51af, U+51b1-51b6, U+51ba-51c0, U+51c3-51c5, U+51c7, U+51c9-51cb, U+51ce-51d1, U+51d4, U+51d6, U+51d9, U+51db-51dc, U+51df, U+51e4, U+51e6, U+51e9-51ea, U+51ed, U+51ef, U+51f4-51f5, U+51fc, U+51ff, U+5201-5202, U+5204-5205, U+5208, U+520b, U+520d-520e, U+5213, U+5215-5216, U+5218, U+521a, U+5220, U+5223, U+5226-5228, U+5232-5234, U+5239, U+523c, U+5241-5242, U+5244, U+5249, U+524c, U+5251-5252, U+5255, U+5257, U+525c, U+525e, U+5261, U+5263-5265, U+526e, U+5270, U+5273-5274, U+5277, U+527d, U+527f, U+5281-5282, U+5284, U+528a, U+528c, U+528f, U+5292-5294, U+529d, U+52a6, U+52ac-52ad, U+52b1-52b5, U+52b9, U+52bb-52bc, U+52be-52c0, U+52c5, U+52cb, U+52cd, U+52d0-52d1, U+52d6-52d7;
}
/* [82] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.82.woff2) format("woff2");
  unicode-range: U+5032, U+5036, U+503a-503b, U+503e, U+5040-5041, U+5043, U+5045-5046, U+5048, U+504a-504e, U+5051-5053, U+505d-5060, U+5063, U+506a, U+506f-5072, U+5078, U+507a-507b, U+507f-5080, U+5088-5089, U+508b-508c, U+508e, U+5092, U+5095-5096, U+509a-509d, U+50a3, U+50a5, U+50a8, U+50af, U+50b1, U+50b4, U+50ba, U+50c2, U+50c6-50ca, U+50cd-50ce, U+50d6, U+50d9, U+50dd-50df, U+50e1, U+50e3, U+50e5-50e6, U+50e8-50ea, U+50ec-50f0, U+50f3, U+50fb, U+50fe, U+5101-5102, U+5105-5109, U+510b-510e, U+5110, U+5113-5115, U+5117, U+511a-511c, U+511e, U+5120-5121, U+5125, U+512b, U+5131, U+5134-5135, U+5138-513c, U+5140, U+514e, U+5150-5151, U+5155-5157, U+515a, U+515f, U+5162, U+516a, U+516e, U+5172, U+5174, U+5179, U+517b, U+517d, U+5182, U+5186, U+5188-5189, U+518b;
}
/* [83] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.83.woff2) format("woff2");
  unicode-range: U+4f22, U+4f24, U+4f29-4f2b, U+4f2d, U+4f31-4f32, U+4f35, U+4f37, U+4f39, U+4f3b, U+4f3e, U+4f41-4f43, U+4f47, U+4f49, U+4f4c, U+4f52, U+4f57-4f58, U+4f5a, U+4f5d-4f5f, U+4f61, U+4f63-4f64, U+4f67, U+4f6a, U+4f6e-4f6f, U+4f72, U+4f74, U+4f76-4f7b, U+4f7d-4f7e, U+4f80-4f82, U+4f84, U+4f89-4f8a, U+4f8e-4f94, U+4f96-4f98, U+4f9a, U+4f9e, U+4fa0-4fa3, U+4fa5-4fa8, U+4faa-4fac, U+4fb2-4fb3, U+4fb7-4fba, U+4fc0-4fc1, U+4fc5-4fc7, U+4fcb, U+4fcd-4fce, U+4fd1, U+4fd3-4fd4, U+4fd8-4fdc, U+4fdf, U+4fe2-4fe5, U+4fe8-4fea, U+4fec-4fed, U+4ff3-4ff6, U+4ff8-4ffa, U+4ffd, U+5000, U+5002, U+5005, U+5008, U+500c, U+500f, U+5013-5015, U+501b-501c, U+501e, U+5022-5025, U+5027-5028, U+502c-502e, U+5030-5031;
}
/* [84] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.84.woff2) format("woff2");
  unicode-range: U+3a17, U+3a52, U+3b22, U+3bd7, U+3bff, U+3ca5, U+3d68, U+3ddb, U+3de7, U+3deb, U+3e03, U+3e74, U+3f08, U+3f0e, U+3f21, U+3f97, U+4102, U+411b, U+4181, U+43c8, U+4552, U+4576, U+46e5, U+4837, U+493d, U+4a3b, U+4d09, U+4e02, U+4e04-4e05, U+4e0c, U+4e0f-4e10, U+4e15, U+4e17, U+4e1b, U+4e21-4e22, U+4e25, U+4e27, U+4e31, U+4e34, U+4e36-4e37, U+4e3d, U+4e3f-4e42, U+4e44, U+4e47, U+4e49, U+4e4c, U+4e52-4e54, U+4e57, U+4e5a-4e5b, U+4e60-4e61, U+4e69, U+4e6d, U+4e78, U+4e80-4e81, U+4e85, U+4e87, U+4e89-4e8a, U+4e8d, U+4e8f, U+4e93, U+4e96, U+4e98-4e99, U+4e9c, U+4e9f-4ea0, U+4ea2, U+4ea5, U+4ea9, U+4eb0, U+4eb2-4eb3, U+4eb5-4eb7, U+4eb9, U+4ebb-4ebc, U+4ebf, U+4ec2-4ec6, U+4ec8-4ec9, U+4ecf, U+4ed1, U+4ed3, U+4edc-4ee1, U+4ee7-4eeb, U+4eee-4eef, U+4ef1, U+4ef3-4ef5, U+4efa, U+4efc, U+4f00, U+4f02-4f03, U+4f05, U+4f07-4f09, U+4f0b, U+4f0e, U+4f15, U+4f17, U+4f1d-4f1f;
}
/* [85] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.85.woff2) format("woff2");
  unicode-range: U+305c, U+3062, U+306d, U+307a, U+307c, U+3080, U+308e, U+3090-3091, U+3099-309e, U+30a5, U+30c2, U+30c5, U+30ee, U+30f0-30f2, U+30f4-30f6, U+30fd-30fe, U+3105-3106, U+3108, U+310a-310b, U+310d-3119, U+311b-311e, U+3120-3126, U+3128-3129, U+3131, U+3134, U+3137, U+3139, U+3141-3142, U+3145, U+3147-3148, U+314b, U+314d-314f, U+3153, U+315c, U+3160-3161, U+3163-3164, U+3181, U+318d, U+3192-3193, U+3196-3198, U+319d-319f, U+3220-3226, U+3231, U+3268, U+3281, U+328b, U+3291-3292, U+3295-3297, U+3299, U+329d, U+329f, U+32a3-32a4, U+32d6, U+32e1, U+3314, U+3322, U+337f, U+338e-338f, U+339c-339e, U+33a1, U+33c4, U+33d1-33d2, U+3440, U+3449, U+3479, U+3551, U+3569, U+35ad, U+35ce, U+36ac, U+373a, U+3863, U+38ec, U+39b8, U+3a02;
}
/* [86] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.86.woff2) format("woff2");
  unicode-range: U+2749-274f, U+2752-275b, U+275d-275e, U+2761, U+2763, U+2765-2769, U+276e-276f, U+2771, U+2776-277e, U+2780-2782, U+278a-278c, U+2794-2796, U+2798-2799, U+279c-27a6, U+27a8-27ab, U+27ad, U+27af-27b0, U+27b2-27b3, U+27b7-27b9, U+27bc-27bd, U+27bf, U+27e9-27eb, U+27f5-27f6, U+2800, U+28ec, U+2922, U+2934-2935, U+29bf, U+2a2f, U+2b05-2b07, U+2b1b, U+2b50, U+2b55, U+2cf5, U+2e1c-2e1d, U+2f00, U+2f08, U+2f12, U+2f24, U+2f29, U+2f2f, U+2f3c, U+2f3f, U+2f42, U+2f45, U+2f63-2f64, U+2f83, U+2f8f, U+3003-3007, U+3012-3013, U+3016-3019, U+3020-3025, U+3030, U+303d, U+3041, U+3043, U+3045, U+3047, U+3049, U+3052;
}
/* [87] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.87.woff2) format("woff2");
  unicode-range: U+264a-2656, U+2658-265c, U+265e-2660, U+2662-2664, U+2666-2669, U+266b-266f, U+267b, U+2692-2696, U+2698, U+269b-269c, U+26a0-26a1, U+26a3-26a5, U+26aa-26ac, U+26bd-26be, U+26c4-26c5, U+26c8, U+26d1, U+26d3-26d4, U+26e4, U+26e9-26ea, U+26f0-26f5, U+26f9-26fa, U+26fd, U+2701-2702, U+2704-2706, U+2708, U+270a-2712, U+2714, U+2716-2727, U+2729-273e, U+2740-2748;
}
/* [88] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.88.woff2) format("woff2");
  unicode-range: U+2567-2570, U+2572, U+2574, U+2579, U+2580-258a, U+258c-2595, U+2597, U+25a1, U+25a3-25a4, U+25a6-25ac, U+25b0, U+25b4, U+25b7-25b9, U+25bb, U+25bd, U+25bf-25c2, U+25c7-25ca, U+25cc-25cd, U+25d0-25d9, U+25dc-25e6, U+25ea-25eb, U+25ef, U+25fb-25fe, U+2600-2604, U+2607, U+2609-260b, U+260d-2615, U+2618, U+261a-2623, U+262a, U+262d-2630, U+2638-263e, U+2641-2642, U+2648-2649;
}
/* [89] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.89.woff2) format("woff2");
  unicode-range: U+2476-2481, U+2488-2491, U+24b6-24c5, U+24c7-24ca, U+24cc, U+24ce, U+24d0-24df, U+24e1-24ea, U+24f5, U+24ff, U+2501, U+2503-250d, U+250f-2511, U+2513-2515, U+2517-2518, U+251b-251d, U+2520, U+2523-2524, U+2528, U+252b-252c, U+252f, U+2533-2534, U+2537, U+253b-253c, U+2541, U+2543-2545, U+254b, U+2550-2566;
}
/* [90] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.90.woff2) format("woff2");
  unicode-range: U+2139, U+2153, U+2194-2197, U+2199, U+219d-219e, U+21a0, U+21a9-21aa, U+21ac, U+21af-21b1, U+21b3-21b5, U+21ba-21bb, U+21c4, U+21ca, U+21cc, U+21d0, U+21d2-21d4, U+21d8, U+21dd, U+21e2-21e9, U+2200, U+2202, U+2205-2208, U+220e-220f, U+2211-2212, U+2215, U+2217-221a, U+221d-2220, U+2225, U+2227-222b, U+222e, U+2234-2237, U+223c-223d, U+2248, U+2256, U+2260-2261, U+2264-2265, U+226a-226b, U+226e-226f, U+2282-2283, U+2295-2296, U+2299, U+22a5, U+22b0-22b1, U+22b9, U+22bf, U+22c5-22c6, U+22c8, U+22d0-22d1, U+22ee, U+2312-2313, U+2318, U+231a-231b, U+2323, U+2328, U+239d, U+23a0, U+23af, U+23e4, U+23e9-23ea, U+23ec, U+23f0-23f3, U+23fa, U+2445, U+2461-2471, U+2474-2475;
}
/* [91] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.91.woff2) format("woff2");
  unicode-range: U+1ec0-1ec3, U+1ec5-1ec6, U+1ec9, U+1ecb-1ecd, U+1ecf-1ed1, U+1ed3-1ed5, U+1ed7-1edd, U+1edf, U+1ee1, U+1ee3-1ee7, U+1ee9, U+1eeb, U+1eed, U+1eef-1ef1, U+1ef3, U+1ef7, U+1ef9, U+1f62, U+1fa2, U+2001-2006, U+2009-200a, U+200c-200d, U+200f-2012, U+2015-2016, U+201a, U+201e, U+2020-2021, U+2023-2025, U+2028, U+202a-202d, U+202f-2030, U+2032-2033, U+2035, U+2038, U+203e-203f, U+2042-2044, U+2049, U+204d-204e, U+2060-2061, U+2063, U+2070, U+2074-207b, U+207d-2083, U+208a, U+208d-208e, U+20a1, U+20a4, U+20a6, U+20a8-20ab, U+20ad-20ae, U+20b1-20b3, U+20b5, U+20b8-20ba, U+20bd, U+20dd, U+20e3, U+2105, U+2109, U+2112-2113, U+2115-2117, U+2120-2121, U+2126, U+212b;
}
/* [92] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.92.woff2) format("woff2");
  unicode-range: U+11af, U+11b7, U+11bc, U+121b, U+122d, U+129b, U+12a0, U+13a6, U+13a9, U+13ac, U+13af, U+13b3, U+13c2, U+13e3, U+141b, U+1555, U+1557, U+15dc, U+15e8, U+1780-1782, U+1784-1785, U+1787, U+178a, U+178e-1791, U+1793-179c, U+179f, U+17a2, U+17b6-17b9, U+17bb-17bc, U+17bf-17c7, U+17c9, U+17cb, U+17d0, U+17d2, U+17db, U+1871, U+18fa, U+1bff, U+1d00, U+1d04-1d05, U+1d07, U+1d0a-1d0b, U+1d0d, U+1d0f, U+1d17-1d18, U+1d1b-1d1c, U+1d20-1d22, U+1d25, U+1d2c, U+1d2e, U+1d30-1d31, U+1d33-1d3a, U+1d3c, U+1d3e-1d42, U+1d52, U+1d55, U+1d5b, U+1d5e, U+1d9c, U+1da0, U+1dc4-1dc5, U+1e3b, U+1e43, U+1e45, U+1e47, U+1e63, U+1e6d, U+1e73, U+1ea0, U+1ea2, U+1ea4-1ea9, U+1eab-1eaf, U+1eb1, U+1eb3, U+1eb5, U+1eb7, U+1eb9, U+1ebb, U+1ebd-1ebe;
}
/* [97] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.97.woff2) format("woff2");
  unicode-range: U+2c8-2c9, U+2cc-2cd, U+2d0, U+2d8, U+2da, U+2dc, U+2e1-2e3, U+2e7, U+2eb, U+2ee, U+2f1-2ff, U+302-304, U+306-309, U+30c-30d, U+311, U+31b, U+321, U+323-325, U+328-329, U+32b-32c, U+32e-32f, U+331-33a, U+33c-33f, U+348, U+353, U+358-359, U+35c, U+35e-35f, U+361, U+363, U+367-368, U+36c, U+36f, U+530-535, U+537-540, U+55e, U+561-565, U+568-56d, U+56f-576, U+578-582, U+584, U+5a1, U+5a3-5a4, U+5aa, U+5ae, U+5b0-5b4;
}
/* [98] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.98.woff2) format("woff2");
  unicode-range: U+1af, U+1b1, U+1b4-1be, U+1d0, U+1d2, U+1d4, U+1da, U+1dc-1dd, U+1e1, U+1e3-1e4, U+1e7, U+1e9, U+1eb-1ec, U+1f0-1f1, U+1f3-1f5, U+1f7, U+1f9-1ff, U+219, U+221, U+225-226, U+228-22b, U+22e-22f, U+231-235, U+239, U+23b, U+23e, U+250-252, U+254-255, U+259-25e, U+261-263, U+268-26b, U+26d, U+26f-277, U+279-27a, U+27d-281, U+283, U+28a-28c, U+28f, U+292, U+294-296, U+298-29a, U+29c, U+29f, U+2a1-2a2, U+2a4-2a7, U+2a9-2aa, U+2ae-2b3, U+2b5-2b7, U+2b9-2bf, U+2c2-2c4, U+2c6-2c7;
}
/* [99] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.99.woff2) format("woff2");
  unicode-range: U+a1-a2, U+a4, U+a6-a8, U+aa, U+ac, U+af, U+b1, U+b5-b6, U+b8-ba, U+bc-be, U+c0-c8, U+ca-cc, U+ce-d5, U+d8-df, U+f0, U+f5, U+f8, U+fb, U+fe-100, U+102, U+105, U+107, U+109-10b, U+10f, U+112, U+115, U+117, U+119, U+11b, U+11f, U+121, U+123-124, U+127, U+129, U+12c-12d, U+130-13f, U+141-142, U+144, U+148, U+14b-14c, U+14f-153, U+159-15b, U+15e-160, U+163-166, U+169-16a, U+16d-171, U+173-17e, U+192, U+1a0, U+1a4, U+1aa, U+1ac-1ad;
}
/* [100] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.100.woff2) format("woff2");
  unicode-range: U+a3, U+2ca, U+2223, U+2460, U+2640, U+273f, U+301c, U+3107, U+310c, U+4e30, U+4e3e, U+4e5e, U+4e71, U+4f26, U+4f7c, U+4f83, U+50da, U+5243, U+5267, U+529e, U+5321, U+5352, U+5477, U+548b, U+54a6, U+54b2, U+54c2, U+54c4, U+54c6, U+54cd, U+54ee, U+5543, U+55d1, U+55d3, U+55f0, U+55fd, U+560d, U+5629, U+5660, U+56d1, U+56dd, U+57ae, U+57e0, U+57e4, U+5904, U+592d, U+5965, U+5a31, U+5a7f, U+5b5a, U+5bb8, U+5c14, U+5c3b, U+5c5c, U+5c5e, U+5d10, U+5e10, U+5e4c, U+603b, U+604d, U+611c, U+6137, U+61c8, U+62c7, U+6371, U+6382, U+645f, U+64ae, U+64c2, U+651e, U+65f1, U+660a, U+663e, U+673d, U+6784, U+6789, U+67b1, U+67ff, U+6813, U+6854, U+68d8, U+68fa, U+697d, U+6a01, U+6a1e, U+6baf, U+6c08, U+6c17, U+6c2b, U+6c81, U+6cbd, U+6dc6, U+6df9, U+6ed9, U+6ee1, U+6f86, U+6fc1, U+6fdb, U+701f, U+7076, U+715c, U+7194, U+71ee, U+71fb, U+720d, U+72b6, U+7396, U+73af, U+745b, U+746f, U+748b, U+7647, U+7699, U+76ce, U+76de, U+77aa, U+786b, U+7881, U+78ca, U+793c, U+797a, U+79b9, U+79bb, U+79bf, U+7a92, U+7ac7, U+7ae3, U+7b19, U+7b20, U+7b51, U+7b94, U+7cbd, U+7cde, U+7cef, U+7d46, U+7dde, U+7f88, U+80da, U+814b, U+81cd, U+8235, U+8282, U+82b9, U+846b, U+84c1, U+84d3, U+8518, U+8611, U+8783, U+8814, U+8a15, U+8aa6, U+8b2c, U+8ba8-8ba9, U+8bc6, U+8be2, U+8be5-8be6, U+8c22, U+8d05, U+8d27, U+8dbe, U+8e34, U+8e66, U+8ec0, U+9005, U+9082, U+914b, U+916f, U+92c5, U+92f0, U+9318, U+938a, U+93e2, U+964b, U+96c1, U+96cc-96cd, U+96db, U+973e, U+97a0, U+9803, U+9876, U+9879, U+9955, U+99f1, U+9a5b, U+9ab7, U+9abc, U+9c57, U+9c9c, U+9d1b, U+9d26, U+9d51, U+9eef, U+9f99, U+c2a4, U+e253, U+e313-e314, U+e5c7, U+e5c9, U+e8db-e8dc, U+ff2d-ff2e, U+ff34, U+ffe5, U+1f60a, U+1f618, U+1f62d;
}
/* [101] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.101.woff2) format("woff2");
  unicode-range: U+b4, U+10d, U+2d9, U+641, U+6cc, U+e20, U+e29, U+200e, U+20ac, U+2266, U+25be, U+301d-301e, U+3058, U+4e07, U+4e1d, U+4e66, U+4ece, U+4fde, U+5016, U+5180, U+5199, U+51aa, U+5306, U+5386, U+53d8, U+5413, U+541d, U+54ce, U+54e8, U+54fc, U+5571, U+557e, U+558e, U+55a7, U+56a8, U+57a2-57a3, U+58b3, U+5960, U+5992-5993, U+59a4, U+5a55, U+5ab2, U+5afb, U+5b56, U+5bc5, U+5bc7, U+5bf0, U+5cb1, U+5cc7, U+5dff, U+5e93, U+5ed3, U+5f6a, U+60bc, U+61ff, U+6218, U+6292, U+634d, U+6467, U+64f1-64f2, U+6582, U+65fb, U+6615, U+6687, U+66e6, U+66f0, U+6781, U+67f5, U+68a7, U+6a1f, U+6b27, U+6b4e, U+6b73, U+6b79, U+6bcb, U+6c5d, U+6cf5, U+6dee, U+6ec4, U+6ecc, U+6f88, U+701d, U+703e, U+707c, U+7099, U+710a, U+72d9, U+72e9, U+731d, U+739f, U+7463, U+7480, U+74a8, U+7523, U+7526, U+75e0, U+7613, U+7656, U+76bf, U+76d4, U+773a, U+775c, U+775e, U+780c, U+78e1, U+78f7, U+7960, U+7a20, U+7aaf, U+7b08, U+7b71, U+7be4, U+7cec, U+7cf0, U+7d5e, U+7d62, U+7dbe, U+7e1b, U+7ea2, U+7ec4, U+7ec6, U+7edc, U+7eed, U+7efc, U+7f16, U+7f57, U+7fb9, U+7fca, U+803d, U+816e, U+8258, U+82a5, U+82b7, U+8317, U+8338, U+834a, U+83d3, U+8401, U+8469, U+849e, U+854a, U+8559, U+865e, U+8700, U+8759, U+8760, U+8778, U+8782, U+879e, U+87d1, U+8836, U+8944, U+89c8, U+8aac, U+8b74, U+8ba2, U+8ba4, U+8bae, U+8bfb, U+8c4e, U+8cb3, U+8d16, U+8d28, U+8e44, U+8f3b, U+8f3f, U+8f91, U+8fb9, U+8fc4, U+8fde, U+8ff9, U+9076, U+9091, U+90ae, U+90b8, U+9257, U+9310, U+9382, U+93df, U+94fe, U+95a5, U+962e, U+968f-9690, U+9704, U+9713, U+97f6, U+9824, U+986b, U+9884, U+9886, U+98e2, U+991a, U+9986, U+99a5, U+99dd, U+9ab8, U+9b41, U+9b77, U+9bad, U+c774, U+e5d4, U+fe52, U+ff02, U+ff25, U+1f389, U+1f449, U+1f495;
}
/* [102] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.102.woff2) format("woff2");
  unicode-range: U+2cb, U+5d1, U+5d9, U+5e2, U+5e8, U+5ea, U+633, U+e32, U+2252, U+2267, U+2573, U+25b3, U+25c4, U+2713, U+2715, U+30e2, U+4e28, U+4e3c, U+4e4d, U+4e70, U+4f88, U+4fef, U+5018, U+501a, U+5026, U+5137, U+513f, U+51f3, U+524b, U+5254, U+52d8, U+5308, U+5384, U+53cc, U+5436, U+5443, U+5466, U+54a8, U+54bd, U+54c9, U+54cb, U+555e, U+5580, U+560e, U+561f, U+562f, U+566c, U+5679, U+56bc, U+56cd, U+56e7, U+56ed, U+572d, U+57d7, U+582f, U+589f, U+5b09, U+5ba5, U+5c51, U+5c90, U+5cef, U+5d16, U+5d84, U+5dd4, U+5e08, U+5e26, U+5f0a, U+5f20, U+606c, U+61c7, U+620f, U+6254, U+625b, U+62a4, U+62d0, U+62f1, U+63a0, U+63c6, U+63f9, U+6413, U+6417, U+6483, U+64f7, U+650f, U+65a7, U+665f, U+66ae, U+66d6, U+66e0, U+6746, U+6756, U+67d1, U+6837, U+68e0, U+68f5, U+6995, U+69a8, U+69b4, U+69d3, U+6a3d, U+6abb, U+6bb7, U+6bd3, U+6c47, U+6cc4, U+6cd3, U+6dae, U+6e26, U+6e29, U+6eaf, U+6eba, U+6fef, U+7028, U+70b3, U+711a, U+725f, U+7325, U+733f, U+73c0, U+73ee, U+7444, U+745a, U+7487, U+7540, U+75a4, U+7729, U+779e, U+798e, U+79e9, U+7a3d, U+7a4c, U+7a9f, U+7ac4, U+7aff, U+7c27, U+7cd9, U+7d76, U+7e43, U+7ea6, U+7ed9, U+7ff1, U+808b, U+809b, U+80fa, U+827a, U+8309, U+8328, U+832b, U+8396, U+83e0, U+840e, U+8425, U+852d, U+853b, U+8588, U+85e9, U+86b5, U+86e4, U+8718, U+87ec, U+880d, U+8910, U+893b, U+89c1-89c2, U+8b3e, U+8baf, U+8bc1, U+8bdd, U+8c41, U+8c48, U+8cb6, U+8d2d, U+8d5e, U+8fbe, U+9015, U+90a8, U+90b5, U+90e1, U+9169, U+9183, U+91d0, U+91dc, U+9293, U+92f8, U+9472, U+9598, U+95a9, U+95ed, U+95fb, U+9605, U+96c7, U+9739, U+9742, U+9761, U+99ad, U+9ae6, U+9b1a, U+9b44, U+9bc9, U+9d3f, U+9dd7, U+9e7c, U+9e92, U+fe5d-fe5e, U+ff22-ff24, U+ff2f-ff30, U+ff33;
}
/* [103] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.103.woff2) format("woff2");
  unicode-range: U+60, U+f7, U+2198, U+2571, U+258b, U+25b6, U+2661, U+3051, U+3109, U+311a, U+4e11, U+4e24, U+4e2b, U+4ef7, U+4f18, U+4f36, U+4fd0, U+5029-502a, U+5055, U+508d, U+50ad, U+50e7, U+50f1, U+50f5, U+51a5, U+51c8, U+51fb, U+5203, U+524e, U+5288, U+5323, U+53c2, U+5458, U+54a7, U+54b1, U+54b3, U+54b8, U+556a, U+5582, U+55b2, U+55ba, U+55da, U+55dc, U+5614, U+5662, U+5678, U+56c2, U+5742, U+57d5, U+5862, U+58e4, U+58f0, U+5907, U+590d, U+5934, U+5978, U+5984, U+5a25, U+5c06, U+5c62, U+5c91, U+5d01, U+5d1b, U+5e87, U+5eff, U+5f27, U+5f3a, U+5f53, U+6001, U+6168, U+61a9, U+6233, U+62a5, U+62ce, U+62ed, U+638f, U+6399, U+646f, U+6590, U+6631, U+664f, U+6689, U+66dc, U+672f, U+67af, U+67ec, U+68d7, U+6977, U+6a44, U+6c14, U+6c40, U+6c70, U+6c76, U+6cb8, U+6ce3, U+6df3, U+6e20, U+6e43, U+6e5b, U+6ebc, U+6eec, U+6f2c, U+6fb1, U+7009, U+7011, U+701a, U+7117, U+7184, U+72f9, U+7426, U+74bd, U+74cf, U+752b, U+7554, U+75b9, U+7621, U+7671-7672, U+7693, U+76ef, U+7737, U+77a7, U+77b3, U+77bb, U+77da, U+77e2, U+77e9, U+77ef, U+7801, U+7940, U+797f, U+79a7, U+79b1, U+79bd, U+79cd, U+7a6b, U+7ac5, U+7b1b, U+7b77, U+7ca7, U+7dab, U+7db4, U+7db8, U+7dcb, U+7ddd, U+7de0, U+7e55, U+7e9c, U+7ed3, U+7ef4, U+803f, U+8046, U+8087, U+8116, U+81a8, U+8214, U+821c, U+82d4, U+831c, U+8339, U+8350, U+8354, U+8526, U+860a, U+86db, U+8713, U+873b, U+8822, U+8993, U+8a1f, U+8ab9, U+8ad7, U+8bcd, U+8e72, U+8f4e, U+8f9c, U+8fd0, U+8fd8, U+8fe6, U+9042, U+907c, U+91ba, U+9452, U+95e2, U+9631, U+9699, U+96b8, U+9709, U+978d, U+9830, U+98ce, U+9945, U+99ed, U+9a8c, U+9ad3, U+9baa, U+9be8, U+9c77, U+9cf6, U+9d72, U+9e1f, U+9ec4, U+fe31, U+fe55, U+ff03, U+ff20, U+ff3b, U+ff3d, U+1f3fb, U+1f44d, U+1f60d;
}
/* [104] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.104.woff2) format("woff2");
  unicode-range: U+10c, U+161, U+e44, U+2728, U+3081, U+4e13, U+4e19, U+4e1c, U+4e1e, U+4e5c, U+4ea7, U+4ed7, U+4f20, U+4f8d, U+4ffe, U+5021, U+50d5, U+515c, U+51a4, U+51e0, U+521b, U+522b, U+532a, U+534e, U+5355, U+537f, U+5398, U+541f, U+543c, U+544e, U+5509, U+5598, U+5622, U+563f, U+5641, U+566a, U+5695, U+569f, U+56ae, U+56da, U+573a, U+574e, U+584c, U+5885, U+58ae, U+5a1f, U+5ac2, U+5b24, U+5bb0, U+5bde, U+5be1, U+5bfc, U+5c39, U+5c4c, U+5c60, U+5cfb, U+5d11, U+5e76, U+5e7f, U+5e9a, U+5eb8, U+5f13, U+5f64, U+5f6c, U+6127, U+61f2, U+6208, U+620a, U+620c, U+6252, U+62ef, U+6328, U+633d, U+6362, U+63b0, U+63c0, U+63c9, U+640f, U+64a9, U+6514, U+652c, U+655e, U+6583, U+658c, U+6627, U+66f3, U+6734, U+6743, U+676d, U+67c4, U+67da, U+6807, U+68cd, U+68f2, U+690e, U+6b16, U+6b38, U+6b3d, U+6bc6, U+6ca1, U+6cab, U+6d8c, U+6e32, U+6e3e, U+6e58, U+6eef, U+6ef2, U+708a, U+7130, U+7165, U+7172, U+71ed, U+7232, U+7239, U+7261, U+7280, U+72a7, U+72f8, U+73c8, U+7464, U+753b, U+754f, U+755c, U+75d8, U+76ea, U+776b, U+7779, U+777f, U+7784, U+778e, U+77ee, U+79e4, U+7a46, U+7a57, U+7aba, U+7aed, U+7b4d, U+7c7b, U+7c7d, U+7d13, U+7d33, U+7dbb, U+7df9, U+7e46, U+7ea7, U+8085, U+8165, U+81fb, U+82b8, U+82d3, U+8305, U+8335, U+8343, U+83e9, U+840d, U+851a, U+853d, U+8543, U+859b, U+85fb, U+87fb, U+888d, U+88c5, U+8adc, U+8b0a, U+8bb0, U+8bbe, U+8bc4, U+8bf4, U+8c5a, U+8cc3, U+8ce4, U+8d44, U+8e81, U+8f44, U+8f66, U+8fdb, U+900d, U+9063, U+914c, U+9223, U+9226, U+923a, U+925b, U+9264, U+929c, U+92b9, U+9320, U+934d, U+9444, U+957f, U+9591, U+96a7, U+97ad, U+97cc, U+9811, U+9898, U+98ea, U+9921, U+9952, U+9a55, U+9b0d, U+9bca, U+9ebd, U+e60f, U+ff1c-ff1d, U+ff21, U+ff38, U+ff9f, U+fffd, U+1f602;
}
/* [105] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.105.woff2) format("woff2");
  unicode-range: U+e17, U+e22, U+2103, U+25a0, U+266a, U+3014-3015, U+311f, U+4e1a, U+4e50, U+4f10, U+4f6c, U+4f70, U+5006, U+50d1, U+5170, U+518c, U+51f0, U+51f6, U+51f9, U+5219, U+5256, U+525d, U+52c9, U+5349, U+5351, U+5356, U+5375, U+539d, U+53db, U+53ee, U+53f7, U+5492, U+54fa, U+5538, U+55bb, U+55e8, U+5632, U+5757, U+5835, U+58be, U+5937, U+59dc, U+59e8, U+5a49, U+5a9a-5a9b, U+5ab3, U+5b9b, U+5b9e, U+5be8, U+5c37, U+5c4e, U+5d14, U+5d19, U+5d4c, U+5d50, U+5deb, U+5e84, U+5e94, U+5ec2, U+5f17, U+5f26, U+5f55, U+5f77, U+5f7f, U+5fbd, U+6052, U+6064-6065, U+608d, U+609a, U+6101, U+614c, U+621a, U+6237, U+6296, U+62e9, U+632a-632b, U+6488, U+6500, U+652a, U+6556, U+65e0, U+65ec, U+6643, U+6850, U+6893, U+6897, U+68b3, U+68d5, U+6930, U+6960, U+6a11, U+6a3a, U+6ab3, U+6b22, U+6c59, U+6c83, U+6ccc, U+6dea, U+6df5, U+6ef7, U+6f3e, U+6f80, U+6fe4, U+7164, U+71c9, U+722a, U+7260, U+7272, U+73b0, U+74e3, U+7538, U+7586, U+75b5, U+7661-7662, U+77db, U+7838, U+786e, U+788c, U+7950, U+79a6, U+79aa, U+7a40, U+7bf7, U+7c3e, U+7c98, U+7ca5, U+7d21, U+7d2e, U+7dba, U+7dec, U+7e79, U+7ecf, U+7edf, U+7f79, U+8086, U+810a, U+8139, U+813e, U+817a, U+81b3, U+821f, U+8247, U+8259, U+8271, U+839e, U+8431, U+846c, U+849c, U+84b2, U+84c4, U+8513-8514, U+8755, U+8877, U+8881, U+88f9, U+8a1d, U+8a3c, U+8a6d-8a6e, U+8a93, U+8ae7, U+8af7, U+8b17, U+8b5a, U+8ba1, U+8bba, U+8cdc, U+8dea, U+8f6c, U+8f7d, U+8fc7, U+8fd9, U+902e, U+90ca, U+916a, U+916c, U+921e, U+9245, U+935b, U+9594, U+95a8, U+95ee, U+95f4, U+9706, U+971e, U+9756, U+980c, U+9891, U+98b1, U+98fc, U+9903, U+9957, U+99ff, U+9b91, U+9db4, U+9f4b, U+e602-e605, U+e610-e611, U+ff16-ff19;
}
/* [106] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.106.woff2) format("woff2");
  unicode-range: U+2190-2191, U+2193, U+22c1, U+2302, U+25cb, U+2699, U+2709, U+3127, U+4e0e, U+4e18, U+4e3a, U+4e48, U+4e91, U+4eec, U+4f3d, U+4fcf, U+5112, U+524a, U+52a3, U+52ab, U+52c3, U+52f3, U+52fb, U+5320, U+5339, U+533f, U+53e2, U+543e, U+5480, U+5495, U+5497, U+5564, U+5572, U+55c6, U+55ef, U+563b, U+5653, U+5657, U+56b7, U+5824, U+58d8, U+5955, U+5983, U+598d, U+59da, U+59e6, U+5a36, U+5bb5, U+5bc2, U+5bf9, U+5cb3, U+5d17, U+5dbc, U+5e2e, U+60df, U+611a, U+6190, U+61a4, U+61be, U+61fc, U+6284, U+62ac, U+62bc, U+634f, U+636e, U+6398, U+63a9, U+6487, U+6495, U+64ab, U+6577, U+65ac, U+6602, U+6652, U+66f9, U+6761, U+679a, U+683d, U+68ad, U+68b5, U+68da, U+68e7, U+6a38, U+6a59, U+6ae5, U+6b47, U+6b67, U+6b6a, U+6bef, U+6c50, U+6c9b, U+6e23, U+6e34, U+6e4a, U+6e67, U+6ea2, U+6eb6, U+6f20, U+6feb, U+70ed, U+714c, U+715e, U+7199, U+71ac, U+7231, U+7262, U+7409, U+745f, U+7469, U+74ca, U+7535, U+753a, U+75f4, U+7624, U+7682, U+76ba, U+76f2, U+77fd, U+780d, U+7832, U+78c5, U+78ef, U+7901, U+79be, U+79c9, U+79e6, U+7a1a, U+7a62, U+7a84, U+7aca, U+7cb5, U+7cb9, U+7cdf, U+7ce7, U+7d6e, U+7db1, U+7def, U+7e61, U+7e7d, U+7e8f, U+7f38, U+7f77, U+7fa8, U+7fc5, U+7fe1, U+7ff9, U+800d, U+8015, U+8054, U+80a2, U+80aa, U+80ba, U+814e, U+8180, U+819d, U+81c0, U+828b, U+82ad, U+82af, U+83f1, U+83f8, U+8403, U+84bc, U+84c9, U+84ec, U+8523, U+8549, U+8569, U+8591, U+85b0, U+881f, U+884d, U+88d4, U+89c4, U+89c6, U+8a60, U+8a79, U+8b19, U+8bd5, U+8bf7, U+8c03, U+8c79, U+8cc8, U+8d9f, U+8e10, U+8e48, U+8faf, U+9009, U+9017, U+9175, U+9187, U+918b, U+9214, U+946b, U+9470, U+947c, U+9640, U+9675, U+96ef, U+97cb, U+97e9, U+985b, U+99ae, U+99b3, U+9b4f, U+9d09, U+9e9f, U+9edb, U+9f90, U+ff14, U+1f464;
}
/* [107] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.107.woff2) format("woff2");
  unicode-range: U+a5, U+25ce, U+4e56, U+4e9a, U+4ea8, U+4ead, U+4f3a, U+4f51, U+4f62, U+4faf, U+5098, U+50ac, U+5147, U+5173, U+5187, U+51f8, U+52a1, U+52a8, U+52f8, U+535c, U+53ed, U+541e, U+5435, U+5475, U+54a9, U+54c0, U+54c7, U+5589, U+5605, U+5733, U+5764, U+5782, U+57c3, U+5858, U+5893, U+589c, U+58e2, U+5974, U+599e, U+59a8, U+59ec, U+5b66, U+5b99, U+5b9d, U+5bee, U+5c2c, U+5c65, U+5cfd, U+5d0e, U+5dba, U+5de2, U+5e06, U+5e15, U+5ec1, U+5ed6, U+5f00, U+5f4c, U+5f65, U+6055, U+6070, U+609f, U+60b6, U+6241, U+624e, U+626f, U+6291, U+62cc, U+62d8, U+62da, U+62fe, U+6349, U+6367, U+63ea, U+6435, U+6454, U+64a4, U+64b2, U+64bc, U+64bf, U+64c5, U+64ce, U+6558, U+6572, U+65a5, U+65e8, U+65ed, U+6614, U+6670, U+6688, U+672d, U+673a, U+6770, U+68cb, U+6912, U+6953, U+6a61, U+6aac, U+6aaf, U+6ab8, U+6b20, U+6b96, U+6bbf, U+6bc5, U+6c6a, U+6cbe, U+6d59, U+6d78, U+6dc7, U+6deb, U+6e7e, U+6e9c, U+6f3f, U+6f51, U+6f84, U+704c, U+7051, U+70ab, U+70ad, U+70f9, U+7119, U+7149, U+714e, U+71e6, U+72c4, U+72d0, U+72e0, U+7334, U+744b, U+7455, U+7504, U+75ab, U+75b2, U+766e, U+76c3, U+76fc, U+76fe, U+7891, U+7948, U+7a74, U+7b28, U+7c72, U+7cca, U+7ebf, U+7f55, U+7ff0, U+8154, U+81c2, U+81d8, U+81e3, U+81e5, U+8292, U+8299, U+8302, U+8304, U+8332, U+83c1, U+83c7, U+83ca, U+845b, U+8475, U+8490, U+85af, U+8650, U+8667, U+86d9, U+8774, U+8abc, U+8b0e, U+8b39, U+8bed, U+8c54, U+8c6b, U+8c9e, U+8ca7, U+8caa-8cab, U+8ce6, U+8cec-8ced, U+8eb2, U+8eba, U+8fb0, U+901d, U+908f, U+9127, U+91c0, U+91d8, U+9215, U+92b3, U+932b, U+93fd, U+95ca, U+964c, U+96c0, U+970d, U+9716, U+9774, U+97fb, U+9812, U+9913, U+9935, U+99c1, U+9b31, U+9d5d, U+9d6c, U+9e79, U+fe0f, U+fe30, U+ff05, U+ff0b, U+ff10, U+ff15;
}
/* [108] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.108.woff2) format("woff2");
  unicode-range: U+b0, U+926, U+928, U+939, U+93f-940, U+94d, U+200b, U+22ef, U+25ba, U+25c6, U+2665, U+4e08, U+4e2a, U+4e4f, U+4e59, U+4ec7, U+4f0d, U+4f0f, U+4f19, U+4f59, U+4fae, U+5075, U+507d, U+50b2, U+50b5, U+511f, U+5141, U+5146, U+514c, U+5185, U+51dd, U+51fd, U+522e, U+5319, U+533a, U+5378, U+53ad, U+53c9, U+53d1, U+53d4, U+543b, U+5442, U+5446, U+5481, U+54e9, U+5507, U+5565, U+559a, U+55aa, U+5690, U+56ca, U+56fe, U+582a, U+5915, U+5949, U+5962, U+5996, U+59a5, U+59fb, U+5a77, U+5b0c, U+5b5f, U+5bd3, U+5be2, U+5bfa, U+5c41, U+5c48, U+5ca9, U+5d07, U+5ec8, U+5eca, U+5f18, U+5f4e, U+5f59, U+5f6d, U+5f79, U+5fb9, U+6028, U+6068, U+606d, U+6094, U+60f1, U+6108-6109, U+614e, U+6170, U+617e, U+61b2, U+61f8, U+6247, U+626d, U+6276, U+62ab, U+62cb, U+62d3, U+62f3, U+6368, U+6380, U+6492, U+64e0, U+6570, U+6606, U+660f, U+6649, U+6691, U+66a8, U+6749, U+674f, U+6795, U+67f1, U+67f3, U+6842, U+6851, U+687f, U+68df, U+69fd, U+6a58, U+6c27, U+6c88, U+6cca, U+6d29, U+6d66, U+6daf, U+6f01, U+6f58, U+6f6d, U+6f70, U+6fa1, U+6ff1, U+6ffe, U+7058, U+70ae, U+71d9, U+71e5, U+7235, U+7267, U+73ca, U+742a, U+74f7, U+7529, U+758f, U+75bc, U+76c6, U+7955, U+7a00, U+7a3b, U+7b4b, U+7bad, U+7be9, U+7c4c, U+7c60, U+7cfe, U+7dbf, U+7e2b, U+7f9e, U+7fc1, U+7ffc, U+8096, U+809d, U+80de, U+8155, U+816b, U+81df, U+82bd, U+8352, U+8393, U+8404, U+8525, U+856d, U+8606, U+868a, U+87f9, U+886b, U+8870, U+896a, U+896f, U+8a23, U+8a87, U+8ad2, U+8b00, U+8b20, U+8cb8, U+8cca, U+8ce0, U+8d39, U+8d6b, U+8db4, U+8e29, U+8ef8, U+8f1b, U+8f5f, U+8fa8, U+906e, U+9077, U+90aa, U+90b1, U+90c1, U+9165, U+919c, U+92c1, U+95d6, U+95e8, U+975a, U+9817, U+98c6, U+9ecf, U+feff, U+ff06, U+ff0a, U+ff12-ff13;
}
/* [109] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.109.woff2) format("woff2");
  unicode-range: U+627-629, U+631, U+639, U+644, U+64a, U+25cf, U+2606, U+2764, U+3008-3009, U+4e38, U+4e43, U+4ed5, U+4ef0, U+4eff, U+4fe0, U+5085, U+50a2, U+50be, U+5118, U+5211-5212, U+5272, U+52fe, U+5366, U+53b2, U+53ec, U+54ac, U+5587, U+55b5, U+5606, U+561b, U+5751, U+576a, U+57cb, U+58ef, U+58fa, U+594f, U+5951, U+596e, U+59d1, U+5ac1, U+5acc, U+5b8b, U+5c4d, U+5ca1, U+5d29, U+5dfe, U+5e7d, U+5edf, U+5ef7, U+5fa1, U+5faa, U+5fcc, U+5ffd, U+6021, U+6046, U+6062, U+62b9, U+6316, U+6350, U+6478, U+647a, U+6490, U+64b0, U+64e6, U+6524, U+6591, U+659c, U+65a4, U+65e6, U+65f6, U+6607, U+6674, U+6765, U+679d, U+67f4, U+68a8, U+6b3a, U+6c57, U+6c61, U+6c90, U+6cbf, U+6cdb, U+6d69, U+6db5, U+6dd1, U+6e21, U+6f06, U+6f62, U+70d8, U+71c3, U+71d5, U+722c, U+727d, U+72ac, U+72fc, U+731c, U+7336, U+7344, U+7384, U+73ab, U+7433, U+745c, U+7470, U+75d5, U+7652, U+76c8, U+76e7, U+7709, U+7720, U+7740, U+7747, U+7763, U+77ac-77ad, U+7802, U+78a7, U+78b3, U+78c1, U+78da, U+7926, U+796d, U+798d, U+7aae, U+7b52, U+7c92, U+7d68, U+7d81, U+7e31, U+7e5e, U+7e69, U+7e73, U+7f50, U+7f70, U+7f75, U+8058, U+8070, U+80c3, U+8105-8106, U+8108, U+8179, U+818f, U+81a9, U+81ed, U+820c-820d, U+8277, U+82d1, U+8461, U+84b8, U+852c, U+857e, U+8587, U+85e4, U+863f, U+8679, U+86c7, U+8702, U+8776, U+87ba, U+8896, U+88c2, U+88d5, U+88f8, U+8af8, U+8b7d, U+8ca2, U+8d64, U+8d74, U+8d81, U+8d99, U+8e5f, U+8e8d, U+8ecc, U+8ed2, U+8fb1, U+8fc5, U+9022, U+9038, U+905c, U+9081, U+9189, U+9234, U+92d2, U+934a, U+95a3, U+962a, U+9646, U+96d5, U+971c, U+9838, U+9875, U+98c4, U+99db, U+9a45, U+9a5f, U+9a6c, U+9ad2, U+9cf4, U+9d28, U+9daf, U+9df9, U+9e7d, U+9f0e, U+9f52, U+9f9c, U+ff11, U+ff1e;
}
/* [110] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.110.woff2) format("woff2");
  unicode-range: U+2022, U+2500, U+25bc, U+4e1f, U+4e95, U+4f50, U+4f54, U+4f69, U+4fb6, U+4fc4, U+4fca, U+5009, U+50bb, U+5154, U+51cc, U+528d, U+5291, U+52d2, U+52e4, U+5353, U+5360, U+540a-540b, U+5410, U+54f2, U+5514, U+5537, U+558a, U+55ac, U+5617, U+56fd, U+573e, U+5766, U+5783, U+57d4, U+5806, U+5821, U+5857, U+5875, U+58f9, U+592f, U+5954, U+596a, U+59ae, U+59c6, U+59ca, U+59ff, U+5a03, U+5ae9, U+5b64, U+5bb4, U+5c3f, U+5c6f, U+5de1, U+5e16, U+5e45, U+5e72, U+5ec9, U+5f7c, U+5f81, U+5f90-5f92, U+6016, U+6085, U+6089, U+60a0, U+60a3, U+60b2, U+60d1, U+60f9, U+6155, U+6158, U+6191, U+6212, U+626e, U+62d4, U+632f, U+633a, U+63aa, U+642c, U+64a5, U+64cb, U+6566, U+6597, U+66b1, U+66ec, U+6731, U+6735, U+67ef, U+6846, U+6876, U+6881, U+68af-68b0, U+68c9, U+6905, U+6bc0, U+6beb, U+6c0f, U+6c1b, U+6c41, U+6ce5, U+6cf3, U+6d25, U+6d2a, U+6d3d, U+6dcb, U+6dd8, U+6dfa, U+6e9d, U+6eaa, U+6ec5, U+6ecb, U+6f0f, U+6f32, U+707d, U+708e, U+7092, U+716e, U+723a, U+731b, U+7345, U+7375, U+73b2, U+7434, U+74e6, U+758a, U+75be, U+75de, U+764c, U+76dc, U+788e, U+7897, U+789f, U+78a9, U+78b0, U+790e, U+7965, U+7a4e, U+7c43, U+7d17, U+7dd2, U+7e96, U+7f51, U+7f69, U+7f72, U+7fd4, U+7fe0, U+8017, U+80a9, U+80d6, U+8150, U+8178, U+81bd, U+829d, U+82ac, U+8303, U+838e, U+83cc, U+840c, U+8482, U+8499, U+85a9-85aa, U+883b, U+8861, U+88c1, U+88cf, U+88d9, U+8a3a, U+8a98, U+8aee, U+8c8c, U+8cc0, U+8ce2, U+8d0f, U+8da8, U+8dcc, U+8e0f, U+8f1d, U+8f29, U+8fad, U+9003, U+9006, U+903c, U+903e, U+9059, U+9072, U+9075, U+90ce, U+9130, U+91ac, U+91e3, U+9285, U+9298, U+92ea, U+9326, U+937e, U+95c6, U+9676-9677, U+9727, U+994b, U+99a8, U+99d0, U+9a30, U+9b42, U+9b45, U+9d3b, U+9e7f, U+9ee8, U+9f3b;
}
/* [111] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.111.woff2) format("woff2");
  unicode-range: U+5e, U+3042, U+3044, U+3046, U+3048, U+304a-3050, U+3053-3057, U+3059-305b, U+305d-3061, U+3063-306c, U+306e-3079, U+307b, U+307d-307f, U+3082-308d, U+308f, U+3092-3093, U+30a1-30a4, U+30a6-30c1, U+30c3-30c4, U+30c6-30e1, U+30e3-30ed, U+30ef, U+30f3, U+30fb-30fc, U+4e32, U+4ef2, U+4fd7, U+501f, U+5144, U+51c6, U+52c1, U+5440, U+54e6, U+54ed, U+5510, U+5687, U+58c1, U+5b5d, U+5bd2, U+5ee2, U+5f31, U+6012, U+6084, U+6148, U+6182, U+622a, U+6355, U+6372, U+63ee, U+6416, U+6575, U+660c, U+66c9, U+675c, U+6a6b, U+6b32, U+6b49, U+6b98, U+6d6e, U+6dda, U+6ef4, U+6efe, U+7159, U+7378, U+775b, U+78e8, U+7aa9, U+7d1b, U+7de9, U+7f6a, U+7f8a, U+7fbd, U+8000, U+809a, U+8102, U+8170, U+819c, U+8266, U+82b3, U+87f2, U+8972, U+8a17, U+8b02, U+8e22, U+904d, U+90ed, U+91c7, U+93c8, U+9451, U+9a37, U+9b27, U+ad6d, U+c5b4, U+d55c;
}
/* [112] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.112.woff2) format("woff2");
  unicode-range: U+2502, U+2605, U+4e39, U+4e58, U+4ea1, U+4ed9, U+4f2f-4f30, U+4f38, U+4f75, U+4fc3, U+4ff1, U+500d, U+5049, U+5074, U+5077, U+5091, U+5175, U+517c, U+51cd, U+51e1, U+5269-526a, U+52aa, U+52c7, U+52df, U+5377, U+541b, U+5439, U+5448, U+54aa, U+5674, U+56f0, U+5761, U+585e, U+588a, U+58a8, U+58fd, U+5925, U+592e, U+5948, U+5999, U+59b3, U+5a18, U+5a1c, U+5a46, U+5b30, U+5b54, U+5b6b, U+5b8f, U+5be9, U+5bf8, U+5c0a, U+5c16, U+5c24, U+5c46, U+5cf0, U+5e1d, U+5e25, U+5e2d, U+5e3d, U+5e79, U+5f04, U+5fcd, U+5fe0, U+60dc, U+6163, U+616e, U+61f6, U+6258, U+6293, U+62c6, U+62d2, U+62d6, U+62fc, U+63da, U+63ed, U+640d, U+6458, U+649e, U+64ec, U+64f4, U+64fe, U+651c, U+6562, U+65cb, U+65e2, U+65fa, U+6628, U+6668, U+66a2, U+66dd, U+66fc, U+66ff, U+6717, U+67cf, U+67d4, U+6817, U+6885, U+695a, U+69cd, U+6afb, U+6bbc, U+6c89, U+6c96, U+6cc9, U+6d1b, U+6d1e, U+6d74, U+6db2, U+6dbc, U+6df7, U+6dfb, U+6e38, U+6f38, U+6f5b, U+6f64, U+6f8e, U+6fa4, U+6fc3, U+6fd5, U+7070, U+70b8, U+70cf, U+70e4, U+7169, U+7210, U+721b, U+7238, U+737b, U+73bb, U+746a, U+7483, U+74dc, U+74f6, U+7518, U+756a, U+75c7, U+7919, U+7956, U+795d, U+7a05, U+7a0d, U+7a3f, U+7bc9, U+7c97, U+7cd5, U+7d0b, U+7d10, U+7dfb, U+7e3e, U+7e6a, U+8036, U+808c, U+80af, U+80ce, U+80e1, U+80f8, U+817f, U+8216, U+8239, U+827e, U+8377, U+8389, U+83ab, U+83f2, U+840a, U+8584, U+85c9, U+865b, U+8766, U+87a2, U+8932, U+8a50, U+8a69, U+8a95, U+8b6f, U+8c37, U+8c6c, U+8ca9, U+8cfa, U+8d95, U+8de1, U+8f14, U+8f9b, U+8fa3, U+8feb, U+8ff4, U+9010, U+901b, U+905e, U+9080, U+912d, U+9177, U+9336, U+947d, U+963b, U+966a, U+9670, U+9769, U+9813, U+9846, U+98fd, U+99d5, U+9a0e, U+9a19, U+9b6f, U+9ce5, U+9cf3, U+9ece, U+9ed8, U+9f13, U+9f20;
}
/* [113] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.113.woff2) format("woff2");
  unicode-range: U+201c-201d, U+203b, U+2192, U+25b2, U+300e-300f, U+4e01, U+4e73, U+4e82, U+4e88, U+4e8e, U+4f0a, U+4f5b, U+502b, U+504f, U+5076, U+5100, U+5104, U+5132, U+5192, U+51a0, U+51ac, U+51f1, U+5200, U+5224, U+522a, U+5237-5238, U+523a, U+526f, U+5289, U+52de, U+52f5, U+52ff, U+5371, U+539a, U+53e5, U+540e, U+5433, U+547c, U+552f, U+5531, U+5634, U+56c9, U+574a, U+57f7, U+57f9, U+5805, U+5851, U+5854, U+586b, U+58c7, U+58de, U+5967, U+59bb, U+59d3-59d4, U+5b55, U+5b87, U+5b97, U+5bae, U+5bbf, U+5be7, U+5bec, U+5cb8, U+5df7, U+5e7b-5e7c, U+5f1f, U+5f70, U+5fd9, U+60e1, U+61b6, U+61c2, U+6200, U+6234, U+6263, U+62b5, U+62dc, U+62ec, U+6383, U+638c, U+63cf, U+63d2, U+63e1, U+63f4, U+641e, U+64cd, U+64fa, U+654f, U+6557, U+656c, U+65c1, U+65d7, U+6620, U+6676, U+6696-6697, U+66ab, U+66c6, U+671d, U+672b, U+676f, U+677e, U+67d0, U+67d3, U+684c, U+68c4, U+690d, U+694a, U+699c, U+6a4b, U+6ac3, U+6b04, U+6b23, U+6b78, U+6b8a, U+6bd2, U+6c60, U+6cb3, U+6d89, U+6de1, U+6de8, U+6e6f, U+6f02, U+70c8, U+7126, U+718a, U+7236, U+723d, U+7246, U+72af, U+72d7, U+73cd, U+7532, U+760b, U+7626, U+7687, U+76df, U+7761, U+79cb, U+79d2, U+79df, U+7a69, U+7af6, U+7b80, U+7c3d, U+7c3f, U+7c4d, U+7cd6, U+7d2b, U+7de3, U+7e2e, U+7e54, U+7e6b, U+8010, U+80a5, U+812b, U+819a, U+81a0, U+82d7, U+838a, U+8463, U+84cb, U+8521, U+8607, U+860b, U+864e, U+871c, U+878d, U+885d, U+89f8, U+8a13, U+8aa0, U+8afe, U+8b5c, U+8c46, U+8cbf, U+8cd3, U+8cf4, U+8d08, U+8d0a, U+8ddd, U+8de8, U+8fea, U+9014, U+9055, U+906d, U+907a, U+907f, U+90a6, U+9178, U+92fc, U+934b, U+9396, U+93ae, U+9583, U+9663, U+9694, U+96bb, U+9707, U+9738, U+9905, U+9aa8, U+9b25, U+9b3c, U+9ea5, U+9eb5, U+9f4a, U+9f61, U+ff0d-ff0e;
}
/* [114] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.114.woff2) format("woff2");
  unicode-range: U+3c, U+d7, U+2027, U+4e4e, U+4e92, U+4ea6, U+4ec1, U+4ecd, U+4f34, U+4f48, U+4f53, U+4fb5, U+5012, U+50b7, U+51b0, U+5283, U+52dd, U+532f, U+5354, U+53eb, U+53f3, U+5409, U+5496, U+54c8, U+54e5, U+554a, U+5561, U+5594, U+559d, U+56b4, U+56fa, U+5713, U+5750, U+57df, U+584a, U+590f, U+592b, U+593e, U+5976, U+599d, U+59b9, U+59d0, U+5a5a, U+5a66, U+5b85, U+5b88, U+5ba3, U+5bdf, U+5c01, U+5c04, U+5c3a, U+5c3e, U+5c4f, U+5ddd-5dde, U+5de7-5de8, U+5e63, U+5e8a, U+5e9c, U+5eda, U+5ef3, U+5ef6, U+5f48, U+5fb5, U+6015, U+6025, U+602a, U+6050, U+6069, U+6162, U+6176, U+61f7, U+6279, U+627f, U+6297, U+62b1, U+62bd, U+6311, U+6377, U+6388-6389, U+63a1-63a2, U+6436, U+64c1, U+64d4, U+6551, U+6563, U+65bd, U+66b4, U+66f2, U+6751, U+675f, U+6790, U+6838, U+68d2, U+68ee, U+6982, U+69ae, U+69cb, U+6a39, U+6b66, U+6bba, U+6c5f, U+6ce1, U+6d0b, U+6d3e, U+6d6a, U+6e1b, U+6ed1, U+6f22, U+6f54, U+6f6e, U+6fc0, U+6fdf, U+719f, U+71c8, U+71d2, U+7259, U+72c2, U+7389, U+73e0, U+745e, U+751a, U+751c, U+7530, U+7533, U+7562, U+7591, U+75c5, U+75db, U+7642, U+7686, U+76d2, U+76db, U+76e1, U+76e3, U+7701, U+786c, U+7981, U+79c0, U+79fb, U+7a81, U+7a97, U+7adf, U+7aef, U+7b26, U+7c64, U+7d0d, U+7d14, U+7d2f, U+7d9c, U+7dca, U+7df4, U+7e23, U+7f3a, U+8033, U+804a, U+8056, U+805a, U+8173, U+81e8, U+8212, U+821e, U+822a, U+82e6, U+8336, U+83dc, U+8449, U+84ee, U+85e5, U+885b, U+888b, U+8907, U+8a34, U+8a5e, U+8aa4, U+8ab0, U+8ab2, U+8ac7, U+8b66, U+8c6a, U+8c93, U+8c9d, U+8cb4, U+8dd1, U+8f2a, U+8fb2, U+9109, U+9192, U+91cb, U+91dd, U+93e1, U+964d, U+9686, U+968e, U+969c, U+96de, U+96e8, U+96ea, U+96f7, U+975c, U+9760, U+978b, U+9858, U+98ef, U+9918, U+9aee, U+9b54, U+9ebb, U+ff0f, U+ff5c;
}
/* [115] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.115.woff2) format("woff2");
  unicode-range: U+23-25, U+3d, U+4e45, U+4e5d, U+4e7e, U+4eac, U+4eae, U+4ed4, U+4ee4, U+4f01, U+4f3c, U+4f8b, U+4fc2, U+505c, U+50c5, U+5145, U+51b7, U+521d, U+523b, U+525b, U+5287, U+52e2, U+5348, U+535a, U+537b, U+5426, U+542b, U+5438, U+5462, U+54ea, U+555f, U+5566, U+5584, U+5609, U+5708, U+570d, U+571f, U+5747, U+5802, U+58d3, U+5920, U+5922, U+5957, U+5979, U+5a01, U+5a92, U+5abd, U+5b63, U+5b69, U+5b83, U+5bb3, U+5bc4, U+5bf5, U+5c3c, U+5c40, U+5c4b, U+5c64, U+5cf6, U+5de6, U+5e0c, U+5e55, U+5e78, U+5eab, U+5ead, U+5ee0, U+5f85, U+5f8b, U+5fd7-5fd8, U+6167, U+6298, U+62db, U+62ff, U+639b, U+63a7, U+642d, U+6469, U+64ad, U+651d, U+653b, U+65b7, U+65cf, U+665a, U+666e, U+66fe, U+6728, U+674e, U+67b6, U+6821, U+6839, U+6843, U+6a94, U+6b4c, U+6b50, U+6b62, U+6b72, U+6b7b, U+6bcd, U+6bdb, U+6c38, U+6c7a, U+6c7d, U+6c99, U+6cb9, U+6ce2, U+6cf0, U+6d17, U+6d32, U+6e2c, U+6e56, U+6fb3, U+722d, U+723e, U+725b, U+734e, U+7387, U+73ed, U+7565, U+7570, U+76ca, U+76e4, U+773e, U+77ed, U+77f3, U+7814, U+7834, U+7968, U+79d8, U+7a7f, U+7af9, U+7b11, U+7b46, U+7b54, U+7bc4, U+7d19, U+7d22, U+7d42, U+7d55, U+7da0, U+7e41, U+7e7c, U+7f85, U+7ffb, U+8077, U+8089, U+80cc, U+81c9, U+81f4, U+81fa, U+820a, U+822c, U+826f, U+8349, U+85cd, U+86cb, U+8840, U+88dc, U+8986, U+8a0e, U+8a73, U+8a8c, U+8b1b, U+8b9a, U+8c50, U+8ca0, U+8cde, U+8cfd, U+8d8a, U+8df3, U+8e64, U+8ecd, U+8edf, U+8f38, U+8fd4, U+8ff0, U+8ff7, U+9000, U+9047, U+9060, U+90f5, U+9152, U+91ce, U+9280, U+9418, U+9435, U+9589, U+9592, U+9678, U+968a, U+96aa, U+96c5, U+96d6, U+96dc, U+96f6, U+9732, U+9748, U+9802, U+9806, U+9808, U+983b, U+984d, U+984f, U+9867, U+98db, U+98f2, U+98fe, U+9a5a, U+9b06, U+9b5a, U+9bae, U+ff5e;
}
/* [116] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.116.woff2) format("woff2");
  unicode-range: U+40, U+2026, U+4e03, U+4e14, U+4e9e, U+4ec0, U+4ed8, U+4f11, U+4f4e-4f4f, U+4f73, U+4fee, U+5019, U+503c, U+5047, U+514b, U+516b, U+5178, U+5207, U+520a, U+5236, U+5343, U+5347, U+534a, U+5370, U+53cd, U+53e4, U+53e6, U+53f2, U+5403, U+5411, U+5427, U+5468, U+5473, U+547d, U+552e, U+5740, U+5883, U+589e, U+591c, U+5931, U+59cb, U+5a1b, U+5b58, U+5b98, U+5b9c, U+5ba4, U+5bcc, U+5beb, U+5c45, U+5c6c, U+5dee, U+5df4, U+5e03, U+5e33, U+5e6b, U+5e7e, U+5e8f, U+5e95, U+5ea7, U+5f15, U+5f62, U+5f69, U+5f80, U+5fa9, U+5fae, U+5fb7, U+5ff5, U+600e, U+601d, U+60e0, U+614b, U+6230, U+623f, U+628a, U+6295, U+62c9, U+6309, U+64c7, U+64ca, U+652f, U+6545, U+6548, U+65af, U+65e9, U+6625, U+666f, U+667a, U+670b, U+671b, U+6750, U+677f, U+6975, U+6a13, U+6a21, U+6aa2, U+6b65, U+6b77, U+6bb5, U+6cc1, U+6ce8, U+6df1, U+6e90, U+6e96, U+6eab, U+6f14, U+6f2b, U+700f, U+706b, U+7206, U+724c, U+72c0, U+7368, U+7372, U+74b0, U+756b, U+76ae, U+773c, U+78ba, U+798f, U+79ae, U+7a4d, U+7a76, U+7ae5, U+7b56, U+7b97, U+7bb1, U+7bc7, U+7c73, U+7c89, U+7d00, U+7d20, U+7d39, U+7d72, U+7dad, U+7e8c, U+7fa4, U+7fd2, U+8003, U+807d, U+80a1, U+80b2, U+8166, U+8208-8209, U+82e5, U+843d, U+85cf, U+85dd, U+862d, U+8857, U+8863, U+89ba, U+89d2, U+8a2a, U+8a31, U+8a62, U+8a66, U+8a72, U+8abf, U+8b1d, U+8b58, U+8c61, U+8ca1, U+8ca8, U+8cac, U+8cbc, U+8d70, U+8da3, U+8db3, U+8ddf, U+8f03, U+8f15, U+8f2f, U+8fa6, U+8fce, U+8ffd, U+900f, U+9031, U+9069, U+908a, U+91ab, U+91cc, U+92b7, U+9322, U+932f, U+9375, U+9632, U+963f, U+9644, U+9662, U+9673, U+967d, U+96a8, U+96c4, U+96d9, U+96e2-96e3, U+96f2, U+9752, U+97d3, U+97ff, U+9805, U+9810, U+9818, U+986f, U+990a, U+9910, U+9928, U+9e97, U+9ec3, U+9f8d, U+ff1b;
}
/* [117] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.117.woff2) format("woff2");
  unicode-range: U+26, U+3e, U+5f, U+7e, U+3000, U+300a-300b, U+3010-3011, U+4e16, U+4e26, U+4e94, U+4e9b, U+4ea4, U+4eca-4ecb, U+4efb, U+4efd, U+4f46, U+4f55, U+4f9b, U+4f9d, U+4fbf, U+505a, U+5065, U+5099, U+50cf, U+512a, U+5143, U+5148, U+5152, U+5169, U+516d, U+5171, U+5177, U+518a, U+5225, U+5247, U+5275, U+529f, U+52a9, U+5305, U+5341, U+5357, U+5361, U+5373, U+53bb, U+53c3, U+53c8, U+53d6-53d7, U+53e3, U+5404, U+559c, U+55ce, U+5668, U+56db, U+5712, U+5718, U+57ce, U+57fa, U+58eb, U+592a, U+5947, U+5bc6, U+5bf6, U+5c0e, U+5c11, U+5c1a, U+5c55, U+5c71, U+5df1, U+5e2b, U+5e36, U+5e97, U+5eb7, U+5ee3, U+5efa, U+5f35, U+5f37, U+5f88, U+5f9e, U+5fc5, U+606f, U+60a8, U+6232, U+6236, U+624d, U+627e, U+6280, U+62cd, U+6301, U+6307, U+6392, U+63db, U+64da, U+6539, U+653e-653f, U+6559, U+6574, U+65c5, U+6613, U+66f8, U+6797, U+67e5, U+6848, U+6a19, U+6a23, U+6b61, U+6bcf, U+6c11, U+6c42, U+6d41, U+6d77, U+6e2f, U+6eff, U+7167, U+71df, U+738b, U+73a9, U+7403, U+7537, U+754c, U+7559, U+767d-767e, U+78bc, U+793a, U+795e, U+79c1, U+79d1, U+7a2e, U+7a31, U+7a7a, U+7ae0, U+7ba1, U+7bc0, U+7c21, U+7cfb, U+7d04-7d05, U+7d1a, U+7d30, U+7d44, U+7d66, U+7d71, U+7de8, U+7e3d, U+7f6e, U+7fa9, U+8001, U+805e, U+8072, U+81f3, U+82f1, U+83ef, U+842c, U+8457, U+85a6, U+8655, U+8853, U+88ab, U+88dd, U+88e1, U+88fd, U+897f, U+898f, U+89aa, U+89bd, U+89c0, U+89e3, U+8a02, U+8a3b, U+8a55, U+8a8d, U+8a9e, U+8ad6, U+8b49, U+8b70, U+8b77, U+8b80, U+8b8a, U+8b93, U+8cb7, U+8ce3, U+8cea, U+8cfc, U+8f09, U+8fd1, U+9001, U+901f-9020, U+9054, U+90a3, U+914d, U+91cf, U+9304, U+95b1, U+9664, U+969b, U+96b1, U+96c6, U+9700, U+975e, U+97f3, U+98a8, U+98df, U+9999, U+99ac, U+9a57, U+9ebc, U+9ed1;
}
/* [118] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.118.woff2) format("woff2");
  unicode-range: U+d, U+2b, U+7c, U+a9, U+300c-300d, U+4e09, U+4e3b, U+4e4b, U+4e5f, U+4e86, U+4e8b-4e8c, U+4eab, U+4ed6, U+4ee3, U+4ef6, U+4f1a, U+4f4d, U+4f60, U+4f7f, U+4fdd, U+4fe1, U+50b3, U+50f9, U+5149, U+514d, U+5167, U+5176, U+518d, U+5217, U+5229, U+524d, U+529b, U+52a0, U+52d9, U+5316-5317, U+5340, U+539f, U+53ca-53cb, U+53ea, U+53f8, U+5408, U+540c-540d, U+544a, U+548c, U+54c1, U+54e1, U+5546, U+554f, U+55ae, U+56de, U+56e0, U+5716, U+578b, U+5831, U+5834, U+5916, U+5973, U+5982, U+5b57, U+5b78, U+5b89, U+5b8c, U+5b9a, U+5ba2, U+5bb9, U+5be6, U+5c07-5c08, U+5c0b, U+5c0d, U+5c31, U+5de5, U+5df2, U+5e02, U+5e38, U+5ea6, U+5f0f, U+5f71, U+5f8c, U+5f97, U+5feb, U+6027, U+60c5, U+60f3, U+610f, U+611b, U+611f, U+61c9, U+6216, U+624b, U+6253, U+63a5, U+63a8, U+63d0, U+641c, U+6536, U+6578, U+6599, U+661f, U+662d, U+670d, U+671f, U+672a, U+6771, U+679c, U+682a, U+683c, U+689d, U+696d, U+6a02, U+6a5f, U+6b0a, U+6b21, U+6b3e, U+6b64, U+6bd4, U+6c23, U+6c34, U+6c92, U+6cd5, U+6d3b, U+6d88, U+6e05, U+7063, U+7121, U+7136, U+71b1, U+7247-7248, U+7269, U+7279, U+73fe, U+7406, U+7522, U+7531, U+7576, U+767b, U+76ee, U+76f4, U+770b, U+771f, U+77e5, U+793e, U+7a0b, U+7acb, U+7ad9, U+7b2c, U+7b49, U+7cbe, U+7d50, U+7d61, U+7d93, U+7dda, U+8005, U+800c, U+806f, U+8207, U+8272, U+82b1, U+865f, U+8868, U+898b, U+8996, U+8a00, U+8a08, U+8a0a, U+8a18, U+8a2d, U+8a71, U+8aaa, U+8acb, U+8cbb, U+8cc7, U+8d77, U+8d85, U+8def, U+8eab, U+8eca, U+8f49, U+9019-901a, U+9023, U+9032, U+904a-904b, U+904e, U+9053, U+9078, U+9084, U+90e8, U+90fd, U+91cd, U+91d1, U+9577, U+9580, U+9593, U+9650, U+9762, U+982d, U+984c, U+985e, U+9ad4, U+9ad8, U+9ede, U+ff01, U+ff08-ff09, U+ff1f;
}
/* [119] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.119.woff2) format("woff2");
  unicode-range: U+20-22, U+27-2a, U+2c-3b, U+3f, U+41-5d, U+61-7b, U+7d, U+a0, U+ab, U+ae, U+b2-b3, U+b7, U+bb, U+bf, U+c9, U+cd, U+d6, U+e0-ef, U+f1-f4, U+f6, U+f9-fa, U+fc-fd, U+101, U+103, U+110-111, U+113, U+12b, U+14d, U+16b, U+1a1, U+1b0, U+1ce, U+300-301, U+1ea1, U+1ea3, U+1ebf, U+1ec7, U+2013-2014, U+2039-203a, U+203c, U+2122, U+3001-3002, U+4e00, U+4e0a-4e0b, U+4e0d, U+4e2d, U+4eba, U+4ee5, U+4f5c, U+4f86, U+500b, U+5011, U+5165, U+5168, U+516c, U+51fa, U+5206, U+5230, U+52d5, U+53ef-53f0, U+570b, U+5728, U+5730, U+591a, U+5927, U+5929, U+597d, U+5b50, U+5bb6, U+5c0f, U+5e73-5e74, U+5fc3, U+6210-6211, U+6240, U+6587, U+65b0, U+65b9, U+65bc, U+65e5, U+660e, U+662f, U+6642, U+66f4, U+6700, U+6703, U+6708-6709, U+672c, U+6b63, U+6cbb, U+70b9-70ba, U+751f, U+7528, U+767c, U+7684, U+76f8, U+7db2, U+7f8e, U+80fd, U+81ea, U+884c, U+8981, U+958b, U+95dc, U+96fb, U+9801, U+9996, U+ff0c, U+ff1a;
}
/* [0] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.0.woff2) format("woff2");
  unicode-range: U+1f921-1f930, U+1f932-1f935, U+1f937-1f939, U+1f940-1f944, U+1f947-1f94a, U+1f950-1f95f, U+1f962-1f967, U+1f969-1f96a, U+1f980-1f981, U+1f984-1f98d, U+1f990-1f992, U+1f994-1f996, U+1f9c0, U+1f9d0, U+1f9d2, U+1f9d4, U+1f9d6, U+1f9d8, U+1f9da, U+1f9dc-1f9dd, U+1f9df-1f9e2, U+1f9e5-1f9e6, U+20024, U+20487, U+20779, U+20c41, U+20c78, U+20d71, U+20e98, U+20ef9, U+2107b, U+210c1, U+22c51, U+233b4, U+24a12, U+2512b, U+2546e, U+25683, U+267cc, U+269f2, U+27657, U+282e2, U+2898d, U+29d5a, U+f0001-f0005, U+f0019, U+f009b, U+f0101-f0104, U+f012b, U+f01ba, U+f01d6, U+f0209, U+f0217, U+f0223-f0224, U+fc355, U+fe327, U+fe517, U+feb97, U+fffb4;
}
/* [6] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.6.woff2) format("woff2");
  unicode-range: U+ff78-ff7e, U+ff80-ff86, U+ff89-ff94, U+ff97-ff9e, U+ffb9, U+ffe0-ffe3, U+ffe9, U+ffeb, U+ffed, U+fffc, U+1d7c7, U+1f004, U+1f0cf, U+1f141-1f142, U+1f150, U+1f154, U+1f158, U+1f15b, U+1f15d-1f15e, U+1f162-1f163, U+1f170-1f171, U+1f174, U+1f177-1f178, U+1f17d-1f17f, U+1f192-1f195, U+1f197-1f19a, U+1f1e6-1f1f5, U+1f1f7-1f1ff, U+1f21a, U+1f22f, U+1f232-1f237, U+1f239-1f23a, U+1f250-1f251, U+1f300, U+1f302-1f319;
}
/* [7] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.7.woff2) format("woff2");
  unicode-range: U+fa0a, U+fa0c, U+fa11, U+fa17, U+fa19, U+fa1b, U+fa1d, U+fa26, U+fa2c, U+fb01, U+fdfc, U+fe0e, U+fe33-fe36, U+fe38-fe44, U+fe49-fe51, U+fe54, U+fe56-fe57, U+fe59-fe5c, U+fe5f-fe6a, U+fe8e, U+fe92-fe93, U+feae, U+fecb-fecc, U+fee0, U+feec, U+fef3, U+ff04, U+ff07, U+ff26-ff2c, U+ff31-ff32, U+ff35-ff37, U+ff39-ff3a, U+ff3c, U+ff3e-ff5b, U+ff5d, U+ff61-ff65, U+ff67-ff68, U+ff6a, U+ff6c-ff77;
}
/* [8] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.8.woff2) format("woff2");
  unicode-range: U+f78a-f78c, U+f78e, U+f792-f796, U+f798, U+f79c, U+f7f5, U+f812, U+f815, U+f876, U+f8f5, U+f8f8, U+f8ff, U+f901-f902, U+f904, U+f906, U+f909-f90a, U+f90f, U+f914, U+f918-f919, U+f91b, U+f91d, U+f91f, U+f923, U+f925, U+f92d-f92f, U+f934, U+f937-f938, U+f93d, U+f93f, U+f941, U+f949, U+f94c, U+f94e-f94f, U+f95a, U+f95d-f95e, U+f961-f963, U+f965-f970, U+f974, U+f976-f97a, U+f97c, U+f97e-f97f, U+f981, U+f983, U+f988, U+f98a, U+f98c, U+f98e, U+f996-f997, U+f999-f99a, U+f99c, U+f99f-f9a0, U+f9a3, U+f9a8, U+f9ad, U+f9b2-f9b6, U+f9b9-f9ba, U+f9bd-f9be, U+f9c1, U+f9c4, U+f9c7, U+f9ca, U+f9cd, U+f9d0-f9d1, U+f9d3-f9d4, U+f9d7-f9d8, U+f9dc-f9dd, U+f9df-f9e1, U+f9e4, U+f9e8-f9ea, U+f9f4, U+f9f6-f9f7, U+f9f9-f9fa, U+f9fc-fa01, U+fa03-fa04, U+fa06, U+fa08-fa09;
}
/* [19] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.19.woff2) format("woff2");
  unicode-range: U+9eb9-9eba, U+9ebe-9ebf, U+9ecc-9ecd, U+9ed0, U+9ed2, U+9ed4, U+9ed9-9eda, U+9edc-9edd, U+9edf-9ee0, U+9ee2, U+9ee5, U+9ee7, U+9eee, U+9ef3-9ef4, U+9ef6-9ef7, U+9ef9, U+9efb-9efd, U+9eff, U+9f07-9f09, U+9f10, U+9f14-9f15, U+9f19, U+9f22, U+9f29, U+9f2c, U+9f2f, U+9f31, U+9f34, U+9f37, U+9f39, U+9f3d-9f3e, U+9f41, U+9f4c-9f50, U+9f54, U+9f57, U+9f59, U+9f5c, U+9f5f-9f60, U+9f62-9f63, U+9f66-9f67, U+9f6a, U+9f6c, U+9f72, U+9f76-9f77, U+9f7f, U+9f84-9f85, U+9f88, U+9f8e, U+9f91, U+9f94-9f96, U+9f98, U+9f9a-9f9b, U+9f9f-9fa0, U+9fa2, U+9fa4, U+a1f4, U+a4b0-a4b1, U+a4b3, U+a9c1-a9c2, U+aa31, U+ab34, U+ac00-ac01, U+ac04, U+ac08, U+ac10-ac11, U+ac13-ac16, U+ac19, U+ac1c-ac1d, U+ac24, U+ac70-ac71, U+ac74, U+ac77-ac78, U+ac80-ac81, U+ac83, U+ac8c, U+ac90, U+aca0, U+aca8-aca9, U+acac, U+acb0, U+acb8-acb9, U+acbc-acbd, U+acc1, U+acc4, U+ace0-ace1, U+ace4, U+ace8, U+acf0-acf1, U+acf3, U+acf5, U+acfc;
}
/* [20] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.20.woff2) format("woff2");
  unicode-range: U+9d34, U+9d37, U+9d3d, U+9d42, U+9d50, U+9d52-9d53, U+9d59, U+9d5c, U+9d5e-9d61, U+9d6a, U+9d6f-9d70, U+9d77, U+9d7a, U+9d7e, U+9d87, U+9d89, U+9d8f, U+9d91-9d93, U+9d96, U+9d98, U+9d9a, U+9da1-9da2, U+9da5, U+9da9, U+9dab-9dac, U+9db1-9db2, U+9db5, U+9db9-9dbc, U+9dbf-9dc2, U+9dc4, U+9dc7-9dc9, U+9dd3, U+9dd6, U+9dd9-9dda, U+9dde-9ddf, U+9de2, U+9de5-9de6, U+9de8, U+9def-9df0, U+9df2-9df4, U+9df8, U+9dfa, U+9dfd, U+9dff, U+9e02, U+9e07, U+9e0a, U+9e11, U+9e15, U+9e18, U+9e1a-9e1b, U+9e1d-9e1e, U+9e20-9e23, U+9e25-9e26, U+9e2d, U+9e2f, U+9e33, U+9e35, U+9e3d-9e3f, U+9e42-9e43, U+9e45, U+9e48-9e4a, U+9e4c, U+9e4f, U+9e51, U+9e55, U+9e64, U+9e66, U+9e6b, U+9e6d-9e6e, U+9e70, U+9e73, U+9e75, U+9e78, U+9e80-9e83, U+9e87-9e88, U+9e8b-9e8c, U+9e90-9e91, U+9e93, U+9e96, U+9e9d, U+9ea1, U+9ea4, U+9ea6, U+9ea9-9eab, U+9ead, U+9eaf, U+9eb4, U+9eb7-9eb8;
}
/* [21] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.21.woff2) format("woff2");
  unicode-range: U+9bd3-9bd7, U+9bd9, U+9bdb, U+9bdd, U+9be1-9be2, U+9be4-9be5, U+9be7, U+9bea, U+9bed-9bee, U+9bf0-9bf1, U+9bf5, U+9bf7, U+9bf9, U+9bfd, U+9bff, U+9c02, U+9c06, U+9c08-9c0a, U+9c0d, U+9c10, U+9c12-9c13, U+9c15, U+9c1c, U+9c1f, U+9c21, U+9c23-9c25, U+9c28-9c29, U+9c2d-9c2e, U+9c31-9c33, U+9c35-9c37, U+9c39-9c3b, U+9c3d-9c3e, U+9c40, U+9c42, U+9c45-9c49, U+9c52, U+9c54, U+9c56, U+9c58-9c5a, U+9c5d, U+9c5f-9c60, U+9c63, U+9c67-9c68, U+9c72, U+9c75, U+9c78, U+9c7a-9c7c, U+9c7f-9c81, U+9c87-9c88, U+9c8d, U+9c91, U+9c94, U+9c97, U+9c9b, U+9ca4, U+9ca8, U+9cab, U+9cad, U+9cb1-9cb3, U+9cb6-9cb8, U+9cc4-9cc5, U+9ccc-9ccd, U+9cd5-9cd7, U+9cdd-9cdf, U+9ce7, U+9ce9, U+9cee-9cf0, U+9cf2, U+9cfc-9cfe, U+9d03, U+9d06-9d08, U+9d0e, U+9d10, U+9d12, U+9d15, U+9d17, U+9d1d-9d1f, U+9d23, U+9d2b, U+9d2f-9d30;
}
/* [22] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.22.woff2) format("woff2");
  unicode-range: U+9a6f-9a71, U+9a73-9a74, U+9a76, U+9a78-9a79, U+9a7b-9a7c, U+9a7e-9a7f, U+9a81-9a82, U+9a84-9a87, U+9a8a-9a8b, U+9a8f-9a91, U+9a97, U+9a9a, U+9a9e, U+9aa0-9aa1, U+9aa4-9aa5, U+9aaf-9ab1, U+9ab6, U+9ab9-9aba, U+9abe, U+9ac0-9ac5, U+9ac8, U+9acb-9acc, U+9ace-9acf, U+9ad1, U+9ad5-9ad7, U+9ad9, U+9adf, U+9ae1, U+9ae3, U+9aea-9aeb, U+9aed, U+9aef, U+9af2, U+9af4, U+9af9, U+9afb, U+9afd, U+9b03-9b04, U+9b08, U+9b13, U+9b18, U+9b1f, U+9b22-9b23, U+9b28-9b2a, U+9b2c-9b30, U+9b32, U+9b3b, U+9b43, U+9b46-9b49, U+9b4b-9b4e, U+9b51, U+9b55, U+9b58, U+9b5b, U+9b5e-9b60, U+9b63, U+9b68-9b69, U+9b74, U+9b7d, U+9b80-9b81, U+9b83-9b84, U+9b87-9b88, U+9b8a-9b8b, U+9b8d-9b8e, U+9b90, U+9b92-9b95, U+9b97, U+9b9f-9ba0, U+9ba2-9ba3, U+9ba8, U+9bab, U+9bb0, U+9bb8, U+9bc0-9bc1, U+9bc3, U+9bc6-9bc8;
}
/* [23] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.23.woff2) format("woff2");
  unicode-range: U+9912, U+9914, U+9916-9917, U+991b-991c, U+991e, U+9920, U+9927, U+992b-992c, U+992e, U+9931-9933, U+9938, U+993a, U+993c-9940, U+9943-9944, U+9948-994a, U+994c-994e, U+9951, U+9954, U+995c, U+995e-995f, U+9961-9962, U+9965, U+9968, U+996a, U+996d-9972, U+9975-9976, U+997a, U+997c, U+997f-9980, U+9984-9985, U+9988, U+998b, U+998d, U+998f, U+9992, U+9994-9995, U+9997-9998, U+999c, U+999e, U+99a0-99a1, U+99ab, U+99af, U+99b1, U+99b4, U+99b9, U+99c4-99c6, U+99cf, U+99d1-99d2, U+99d4, U+99d6, U+99d8-99d9, U+99df, U+99e1-99e2, U+99e9, U+99ee, U+99f0, U+99f8, U+99fb, U+9a01-9a05, U+9a0c, U+9a0f-9a13, U+9a16, U+9a1b-9a1c, U+9a20, U+9a24, U+9a28, U+9a2b, U+9a2d-9a2e, U+9a34-9a36, U+9a38, U+9a3e, U+9a40-9a44, U+9a4a, U+9a4c-9a4e, U+9a52, U+9a56, U+9a62-9a65, U+9a69-9a6b, U+9a6d;
}
/* [24] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.24.woff2) format("woff2");
  unicode-range: U+97c6, U+97c9, U+97cd, U+97d6, U+97d8-97d9, U+97dc-97de, U+97e1, U+97e6-97e7, U+97ec-97ee, U+97f0-97f1, U+97f5, U+97f9-97fa, U+97fe, U+9804, U+9807, U+980a, U+980e-980f, U+9814, U+9816, U+981a, U+981c, U+981e, U+9820-9821, U+9823, U+9826, U+9828, U+982a-982c, U+9832, U+9834-9835, U+9837, U+9839, U+983c-983d, U+9845, U+9848-9849, U+984e, U+9852-9857, U+9859-985a, U+9862-9863, U+9865, U+9870-9871, U+9873-9874, U+9877, U+987a-987f, U+9881-9882, U+9885, U+9887-988a, U+988c-988d, U+9890, U+9893, U+9896-9897, U+989a, U+989c-989e, U+98a0, U+98a4, U+98a6-98a7, U+98a9, U+98ae-98af, U+98b2-98b3, U+98b6-98b8, U+98ba-98bd, U+98bf, U+98c7-98c8, U+98ca, U+98d2-98d3, U+98d8-98da, U+98dc, U+98de, U+98e0-98e1, U+98e3, U+98e5, U+98e7-98e9, U+98eb-98ee, U+98f1, U+98f4, U+9901, U+9908-9909, U+990c, U+9911;
}
/* [25] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.25.woff2) format("woff2");
  unicode-range: U+964e-964f, U+9651, U+9653-9655, U+9658, U+965b-965f, U+9661, U+9665, U+9668-9669, U+966c, U+9672, U+9674, U+967a, U+9682-9685, U+9688-9689, U+968b, U+968d, U+9695, U+9697-9698, U+969e, U+96a0-96a4, U+96a9, U+96ac, U+96ae, U+96b0, U+96b2-96b4, U+96b6-96b7, U+96b9, U+96bc-96be, U+96c3, U+96c9-96cb, U+96ce-96cf, U+96d1-96d2, U+96d8, U+96dd, U+96e9, U+96eb, U+96f0-96f1, U+96f3-96f4, U+96f9, U+96fe-96ff, U+9701-9702, U+9705, U+9708, U+970a, U+970e-9711, U+9719, U+971d, U+971f-9720, U+9728, U+972a, U+972d, U+9730, U+9733, U+973a, U+973d, U+9744, U+9746-9747, U+9749, U+9750-9751, U+9753, U+9758-9759, U+975b, U+975d, U+9763, U+9765-9766, U+9768, U+976c-976d, U+9771, U+9773, U+9776, U+977a, U+977c, U+9780, U+9784-9786, U+9788, U+978e-978f, U+9798, U+979d-979e, U+97a3, U+97a5-97a6, U+97a8, U+97ab-97ac, U+97ae, U+97b6-97b7, U+97b9, U+97bf, U+97c1, U+97c3;
}
/* [26] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.26.woff2) format("woff2");
  unicode-range: U+950c, U+950f-9510, U+9519-951b, U+951d, U+951f, U+9521-9526, U+952d-9530, U+9535, U+953a-953b, U+9540-9542, U+9547, U+9549-954a, U+954d, U+9550-9551, U+9554-9556, U+955c, U+956c-956d, U+956f-9570, U+9573, U+9576, U+9578, U+9582, U+9586, U+9588, U+958e-958f, U+9599, U+959c, U+959e-959f, U+95a1-95a2, U+95a4, U+95a6-95a7, U+95aa-95ae, U+95b0, U+95b2, U+95b6, U+95b9-95bf, U+95c2-95c4, U+95c7-95c9, U+95cb-95cd, U+95d0, U+95d3-95d5, U+95d7-95d8, U+95da, U+95de, U+95e0-95e1, U+95e4-95e5, U+95ea-95eb, U+95ef-95f0, U+95f2-95f3, U+95f5, U+95f7-95fa, U+95fd, U+9600-9602, U+9607-9609, U+960e, U+9610-9611, U+9614, U+9616, U+9619-961a, U+961c-961d, U+961f, U+9621, U+9624, U+9628, U+962f, U+9633-9636, U+963c, U+9641-9643, U+9645, U+9647-9648;
}
/* [27] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.27.woff2) format("woff2");
  unicode-range: U+93e4, U+93e8, U+93ee, U+93f0, U+93f5, U+93f7-93f9, U+93fb, U+9403, U+9407, U+940f-9410, U+9413-9414, U+9417, U+9419-941a, U+941c, U+941e, U+9420-9425, U+9427-942b, U+942e, U+9432-9433, U+9436, U+9438, U+943a, U+943d, U+943f-9440, U+9445, U+944a, U+944c, U+9454-9455, U+945a-945b, U+945e, U+9460, U+9462-9463, U+9468, U+946a, U+946d, U+946f, U+9471, U+9474-9477, U+9479, U+947e-9481, U+9488-948a, U+948e, U+9492-9493, U+9497, U+9499, U+949b-94a3, U+94a5-94a9, U+94ac, U+94ae-94b1, U+94b3-94b5, U+94bb-94bc, U+94be-94c3, U+94c5-94c6, U+94c9, U+94ce-94d0, U+94db-94dd, U+94e0, U+94e2-94e3, U+94e8, U+94ec-94ee, U+94f0, U+94f2, U+94f5-94f6, U+94f8, U+94fa, U+94ff-9502, U+9504-9506, U+9508, U+950b;
}
/* [28] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.28.woff2) format("woff2");
  unicode-range: U+92e5-92e6, U+92e8-92e9, U+92ed-92ef, U+92f1-92f3, U+92f6, U+92f9, U+92fb, U+9300-9302, U+9306, U+930b, U+930f, U+9312, U+9315, U+9319-931b, U+931d-931f, U+9321, U+9323-9325, U+9327-932a, U+932c-932e, U+9331-9333, U+9335, U+9338, U+933c, U+9340-9341, U+9345-9349, U+934f-9352, U+9354, U+9356-935a, U+935c-9360, U+9363-9367, U+9369-936a, U+936c, U+936e, U+9370-9371, U+9373, U+9376, U+9379-937a, U+937c, U+9385, U+9387, U+938c, U+938f, U+9394, U+9397-9398, U+939a-939b, U+939d-939e, U+93a1-93a3, U+93a6-93a7, U+93a9-93aa, U+93ac-93ad, U+93af-93b0, U+93b3-93bb, U+93bd-93be, U+93c0-93c4, U+93c7, U+93ca-93cd, U+93d0-93d1, U+93d6-93d8, U+93dc-93de, U+93e0;
}
/* [29] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.29.woff2) format("woff2");
  unicode-range: U+91f0-91f1, U+91f5-91fa, U+91fd, U+91ff-9201, U+9203-920a, U+920d-920e, U+9210-9211, U+9217, U+9219, U+921c, U+9221, U+9224-9225, U+9227, U+922a, U+922d-922e, U+9230-9231, U+9233, U+9235-9239, U+923b, U+923d-9241, U+9244, U+9246, U+9248-9249, U+924b-9251, U+9253, U+925a, U+925d-925e, U+9262, U+9265-9267, U+926b-926d, U+926f, U+9271-9272, U+9274, U+9276, U+9278, U+927a-927c, U+927e-927f, U+9282-9283, U+9286, U+9288, U+928a, U+928d-928e, U+9291, U+9295-9296, U+9299-929b, U+929d, U+92a0-92ae, U+92b2, U+92b5-92b6, U+92bb-92bc, U+92c2-92c3, U+92c6-92cd, U+92cf-92d1, U+92d5, U+92d7, U+92d9, U+92dd, U+92df, U+92e4;
}
/* [30] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.30.woff2) format("woff2");
  unicode-range: U+90bb, U+90bd-90be, U+90c3-90c5, U+90c7, U+90d1, U+90d4-90d5, U+90d7, U+90db-90df, U+90e2-90e4, U+90ea-90eb, U+90ef, U+90f4, U+90f7-90f8, U+90fc, U+90fe-9100, U+9102, U+9104, U+9106, U+9112, U+9114-911a, U+911c, U+911e, U+9120, U+9122-9123, U+9129, U+912b, U+912f, U+9131-9132, U+9134, U+9136, U+9139-913a, U+9143, U+9146, U+9148-914a, U+914e-9150, U+9154, U+9156-9157, U+9159-915a, U+915d-915e, U+9161-9164, U+916b, U+916e, U+9170-9172, U+9174, U+9176, U+9179-917a, U+917f, U+9181-9182, U+9184-9186, U+918c-918e, U+9190-9191, U+9196, U+919a-919b, U+919e, U+91a1-91a4, U+91a7, U+91aa, U+91ae-91b2, U+91b4-91b5, U+91b8, U+91bd-91be, U+91c1, U+91c3, U+91c5-91c6, U+91c8-91ca, U+91d2-91d5, U+91d7, U+91d9, U+91e1, U+91e4, U+91e6-91e9, U+91ec-91ed;
}
/* [31] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.31.woff2) format("woff2");
  unicode-range: U+8f68-8f69, U+8f6d-8f72, U+8f74, U+8f76, U+8f7b-8f7c, U+8f7f, U+8f83-8f86, U+8f88-8f8a, U+8f8d, U+8f90, U+8f93, U+8f95-8f97, U+8f99, U+8f9e-8f9f, U+8fa2, U+8fa7, U+8fa9, U+8fab, U+8fae, U+8fb3, U+8fb5-8fb6, U+8fba-8fbd, U+8fbf, U+8fc1-8fc2, U+8fc6, U+8fc8, U+8fcc-8fcd, U+8fd2-8fd3, U+8fd5, U+8fdc-8fdd, U+8fdf, U+8fe2-8fe5, U+8fe8-8fe9, U+8fed-8fee, U+8ff3, U+8ff5, U+8ff8, U+8ffa-8ffc, U+8ffe, U+9002, U+9004, U+9008, U+900a-900b, U+9011-9013, U+9016, U+901e, U+9021, U+9024, U+902d, U+902f-9030, U+9034-9036, U+9039-903b, U+9041, U+9044-9045, U+904f-9052, U+9057-9058, U+905b, U+905d, U+9061-9062, U+9065, U+9068, U+906f, U+9074, U+9079, U+907d, U+9083, U+9085, U+9087-9089, U+908b, U+9090, U+9093, U+9095, U+9097, U+9099, U+909b, U+909d-909e, U+90a0-90a2, U+90ac, U+90af-90b0, U+90b2-90b4, U+90b6, U+90b9;
}
/* [32] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.32.woff2) format("woff2");
  unicode-range: U+8e18, U+8e1d-8e21, U+8e23, U+8e26-8e28, U+8e2a-8e2b, U+8e2d-8e2e, U+8e30-8e31, U+8e35, U+8e39, U+8e3c-8e3d, U+8e40-8e42, U+8e47, U+8e49-8e4e, U+8e50, U+8e53-8e55, U+8e59-8e5b, U+8e60, U+8e62-8e63, U+8e67, U+8e69, U+8e6c-8e6d, U+8e6f, U+8e74, U+8e76, U+8e7a-8e7c, U+8e82, U+8e84-8e85, U+8e87, U+8e89-8e8b, U+8e8f-8e95, U+8e99-8e9a, U+8e9d-8e9e, U+8ea1, U+8ea3, U+8ea5-8ea6, U+8eaa, U+8eac-8ead, U+8eaf-8eb1, U+8eb9, U+8ebc, U+8ebe, U+8ec6, U+8ecb, U+8ecf, U+8ed1, U+8ed4, U+8ed7, U+8eda-8edb, U+8ee2, U+8ee8, U+8eeb, U+8ef2, U+8ef9-8efe, U+8f05, U+8f07-8f08, U+8f0a-8f0c, U+8f12-8f13, U+8f17, U+8f19-8f1a, U+8f1c, U+8f1e-8f1f, U+8f25-8f26, U+8f2d, U+8f30, U+8f33, U+8f36, U+8f3e, U+8f40-8f42, U+8f45-8f47, U+8f4d, U+8f54-8f55, U+8f5d, U+8f61-8f62, U+8f64, U+8f67;
}
/* [33] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.33.woff2) format("woff2");
  unicode-range: U+8cc1-8cc2, U+8cc4-8cc5, U+8ccf-8cd2, U+8cd4-8cd5, U+8cd9-8cdb, U+8ce1, U+8ce8, U+8ceb, U+8cf0, U+8cf8, U+8cfb, U+8cfe, U+8d04, U+8d07, U+8d0b, U+8d0d, U+8d10, U+8d12-8d14, U+8d17, U+8d1b-8d1f, U+8d21-8d26, U+8d29-8d2c, U+8d2e-8d32, U+8d34-8d35, U+8d37-8d38, U+8d3a-8d3c, U+8d3e-8d3f, U+8d41-8d43, U+8d48, U+8d4b-8d4c, U+8d4e-8d50, U+8d54, U+8d56, U+8d58, U+8d5a-8d5b, U+8d5f-8d60, U+8d62-8d63, U+8d66-8d69, U+8d6c-8d6e, U+8d73, U+8d75-8d76, U+8d7b, U+8d7d, U+8d84, U+8d8b, U+8d90-8d91, U+8d94, U+8d96, U+8d9c, U+8dab, U+8daf, U+8db2, U+8db5, U+8db7, U+8dba, U+8dbc, U+8dbf, U+8dc2-8dc3, U+8dc6, U+8dcb, U+8dce-8dd0, U+8dd6-8dd7, U+8dda-8ddb, U+8de3-8de4, U+8de9, U+8deb-8dec, U+8df1, U+8df5-8df7, U+8dfa-8dfd, U+8e05, U+8e08-8e0a, U+8e0e, U+8e14, U+8e16;
}
/* [34] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.34.woff2) format("woff2");
  unicode-range: U+8b6c-8b6d, U+8b72, U+8b7e, U+8b83, U+8b89, U+8b8c, U+8b8e, U+8b90, U+8b92, U+8b95-8b96, U+8b99, U+8b9c, U+8b9e-8b9f, U+8ba3, U+8ba5, U+8ba7, U+8baa, U+8bad, U+8bb2-8bb4, U+8bb6-8bb9, U+8bbc-8bbd, U+8bbf-8bc0, U+8bc3, U+8bc5, U+8bc8-8bcb, U+8bcf, U+8bd1, U+8bd7-8bdc, U+8bde-8be1, U+8be3, U+8be7, U+8be9, U+8beb-8bec, U+8bef, U+8bf1-8bf2, U+8bf5-8bf6, U+8bf8, U+8bfa, U+8bfd-8bfe, U+8c01-8c02, U+8c05, U+8c08, U+8c0a-8c11, U+8c13-8c15, U+8c18-8c1c, U+8c1f, U+8c23-8c29, U+8c2c-8c2d, U+8c31, U+8c34, U+8c36, U+8c39, U+8c3f, U+8c47, U+8c49-8c4c, U+8c4f, U+8c51, U+8c55, U+8c62, U+8c68, U+8c73, U+8c78, U+8c7a-8c7c, U+8c82, U+8c85, U+8c89-8c8a, U+8c8d-8c8e, U+8c90, U+8c94, U+8c98-8c99, U+8ca3-8ca4, U+8cad-8cb0, U+8cb2, U+8cb9-8cba, U+8cbd;
}
/* [35] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.35.woff2) format("woff2");
  unicode-range: U+89f6, U+89ff, U+8a01, U+8a03, U+8a07, U+8a09, U+8a0c, U+8a0f-8a12, U+8a16, U+8a1b, U+8a22, U+8a25, U+8a27, U+8a2b, U+8a33, U+8a36, U+8a38, U+8a3d-8a3e, U+8a41, U+8a45-8a46, U+8a48, U+8a4e, U+8a51-8a52, U+8a54, U+8a56-8a58, U+8a5b, U+8a5d, U+8a61, U+8a63, U+8a67, U+8a6a-8a6c, U+8a70, U+8a74-8a76, U+8a7a-8a7c, U+8a82, U+8a84-8a86, U+8a89, U+8a8f-8a92, U+8a94, U+8a9a, U+8aa1, U+8aa3, U+8aa5, U+8aa7-8aa8, U+8aad, U+8ab1, U+8ab6, U+8abe, U+8ac2, U+8ac4, U+8ac6, U+8ac9, U+8acc-8acf, U+8ad1, U+8adb, U+8add-8ae2, U+8ae4, U+8ae6, U+8aea-8aeb, U+8aed, U+8af1-8af6, U+8afa, U+8afc, U+8b01, U+8b04-8b05, U+8b07, U+8b0b-8b0d, U+8b0f-8b10, U+8b13-8b14, U+8b16, U+8b1a, U+8b1c, U+8b21-8b22, U+8b26, U+8b28, U+8b2b, U+8b2e, U+8b33, U+8b41, U+8b46, U+8b4c-8b4f, U+8b53-8b54, U+8b56, U+8b59, U+8b5e-8b60, U+8b64, U+8b6a-8b6b;
}
/* [36] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.36.woff2) format("woff2");
  unicode-range: U+889b-889c, U+889e, U+88a2, U+88a4, U+88a8, U+88aa, U+88ad-88ae, U+88b1, U+88b4, U+88b7-88b8, U+88bc-88be, U+88c0, U+88c4, U+88c6-88c7, U+88c9-88cc, U+88ce, U+88d2, U+88d6, U+88d8, U+88db, U+88df, U+88e4, U+88e8-88e9, U+88ec, U+88ef-88f1, U+88f3-88f5, U+88fc, U+88fe, U+8900, U+8902, U+8906, U+890a-890c, U+8912-8915, U+8918-891a, U+891f, U+8921, U+8923, U+8925, U+892a-892b, U+892d, U+8930, U+8933, U+8935-8936, U+8938, U+893d, U+8941-8943, U+8946-8947, U+8949, U+894c-894d, U+8956-8957, U+8959, U+895c, U+895e-8960, U+8964, U+8966, U+896c, U+8971, U+8974, U+897b, U+897e, U+8980, U+8982-8983, U+8987-8988, U+898a, U+898c, U+8991, U+8994-8995, U+8997-8998, U+899a, U+899c, U+89a1, U+89a4-89a7, U+89a9, U+89ac, U+89af, U+89b2-89b3, U+89b7, U+89bb, U+89bf, U+89c5, U+89c9-89ca, U+89d1, U+89d4-89d5, U+89da, U+89dc-89de, U+89e5-89e7, U+89ed, U+89f1, U+89f3-89f4;
}
/* [37] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.37.woff2) format("woff2");
  unicode-range: U+874c, U+874e-874f, U+8753, U+8757-8758, U+875d, U+875f, U+8761-8765, U+8768, U+876a, U+876c-8770, U+8772, U+8777, U+877a-877b, U+877d, U+8781, U+8784-8785, U+8788, U+878b, U+8793, U+8797-8798, U+879f, U+87a3, U+87a8, U+87ab-87ad, U+87af, U+87b3, U+87b5, U+87b9, U+87bb, U+87bd-87c0, U+87c4-87c8, U+87ca-87cc, U+87ce, U+87d2, U+87db-87dc, U+87e0-87e1, U+87e3, U+87e5, U+87e7, U+87ea-87eb, U+87ee-87ef, U+87f3-87f4, U+87f6-87f7, U+87fe, U+8802-8806, U+880a-880b, U+880e, U+8810-8811, U+8813, U+8815-8816, U+881b, U+8821, U+8823, U+8827, U+8831-8832, U+8835, U+8839-883a, U+883c, U+8844-8846, U+884a, U+884e, U+8852, U+8854-8856, U+8859-885a, U+885e, U+8862, U+8864-8865, U+8869-886a, U+886c-886e, U+8871-8872, U+8875, U+8879, U+887d-887f, U+8882, U+8884-8885, U+8888, U+8890, U+8892-8893, U+8897-8898, U+889a;
}
/* [38] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.38.woff2) format("woff2");
  unicode-range: U+8602, U+8604-8605, U+8610, U+8616-8618, U+861a, U+861e, U+8621-8622, U+8627, U+8629-862a, U+8634-8636, U+8638, U+863a, U+863c, U+8640, U+8642, U+8646, U+864c-864d, U+864f, U+8651-8654, U+8657, U+8659-865a, U+865c, U+8662, U+866b-866c, U+866f-8671, U+8673, U+8677, U+867a-867b, U+867d-867e, U+8680-8682, U+868b-868d, U+8693-8696, U+869a, U+869c-869d, U+86a1, U+86a3-86a4, U+86a7-86aa, U+86af-86b1, U+86b3-86b4, U+86b6, U+86b9-86ba, U+86c0-86c2, U+86c4, U+86c6, U+86c9-86ca, U+86cc-86ce, U+86d0, U+86d3-86d4, U+86de-86df, U+86e9, U+86ed-86f0, U+86f3, U+86f8-86fc, U+86fe, U+8703, U+8706-870a, U+870d-870e, U+8711-8712, U+8715, U+8717, U+8719-871a, U+871e, U+8721-8723, U+8725, U+8728-8729, U+872e, U+8731, U+8734, U+8737, U+873a, U+873e-8740, U+8742, U+8747, U+8749, U+874b;
}
/* [39] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.39.woff2) format("woff2");
  unicode-range: U+84b1, U+84b4, U+84b9-84bb, U+84bd-84c0, U+84c2, U+84c6-84c7, U+84ca, U+84cd-84d2, U+84d6, U+84dd, U+84df, U+84e2, U+84e6-84e8, U+84ea, U+84ef-84f0, U+84f3-84f4, U+84f7, U+84fa, U+84fc-84fd, U+84ff-8500, U+8506, U+850c, U+8511, U+8515, U+8517, U+851d-851f, U+8524, U+852b, U+852f, U+8532, U+8534-8535, U+8537-8538, U+853a, U+853c, U+853e, U+8541-8542, U+8545, U+8548, U+854b, U+854d-854e, U+8552-8553, U+8555-8558, U+855a, U+855e, U+8561-8565, U+8568, U+856a-856c, U+856f, U+8574, U+8577-857b, U+8580-8581, U+8585-8586, U+858a, U+858c, U+858f-8590, U+8594, U+8597-8599, U+859c, U+859f, U+85a1-85a2, U+85a4, U+85a8, U+85ab-85ac, U+85ae, U+85b3-85b4, U+85b7, U+85b9-85ba, U+85bd-85be, U+85c1-85c2, U+85cb, U+85ce, U+85d0, U+85d3, U+85d5, U+85dc, U+85e0, U+85e6, U+85e8, U+85ea, U+85ed, U+85f4, U+85f6-85f7, U+85f9-85fa, U+85ff;
}
/* [40] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.40.woff2) format("woff2");
  unicode-range: U+8378, U+837a-8380, U+8383, U+8385-8386, U+8392, U+8394-8395, U+8398-8399, U+839b-839c, U+83a0, U+83a2, U+83a4, U+83a7-83aa, U+83af-83b5, U+83b7, U+83b9-83ba, U+83bc-83c0, U+83c2, U+83c4-83c5, U+83c8-83c9, U+83cb, U+83ce-83cf, U+83d1, U+83d4-83d6, U+83d8, U+83dd, U+83df, U+83e1-83e2, U+83e5, U+83ea-83eb, U+83f0, U+83f3-83f4, U+83f9, U+83fb-83fe, U+8406-8407, U+840b, U+840f, U+8411, U+8413, U+8418, U+841b-841d, U+8420-8421, U+8423-8424, U+8426-8429, U+842b, U+842d-842e, U+8432-8433, U+8435, U+8437-8439, U+843b-843c, U+843e, U+8445-8447, U+844e, U+8451-8452, U+8456, U+8459-845a, U+845c, U+845f, U+8462, U+8466-8467, U+846d, U+846f-8471, U+8473-8474, U+8476-8478, U+847a, U+8484, U+848b, U+848d-848e, U+8493-8494, U+8497, U+849d, U+849f, U+84a1, U+84a8, U+84af;
}
/* [41] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.41.woff2) format("woff2");
  unicode-range: U+826e, U+8270, U+8273, U+8276, U+8278-8279, U+827d, U+827f, U+8283-8284, U+8288-828a, U+828d-8291, U+8293-8294, U+8298, U+829a-829c, U+829e-82a4, U+82a6-82ab, U+82ae, U+82b0, U+82b4, U+82b6, U+82ba-82bc, U+82be, U+82c1, U+82c4-82c5, U+82c7, U+82cb, U+82cd, U+82cf-82d0, U+82d2, U+82d5-82d6, U+82d9, U+82db-82dc, U+82de-82e1, U+82e3-82e4, U+82e7, U+82ea-82eb, U+82ef-82f0, U+82f3-82f4, U+82f6-82f7, U+82f9-82fc, U+82fe-8301, U+8306-8308, U+830b-830e, U+8316, U+8318, U+831a-831b, U+831d-831e, U+8327, U+832a, U+832c-832d, U+832f, U+8331, U+8333-8334, U+8337, U+833a-833c, U+833f-8340, U+8342, U+8344-8347, U+834b-834c, U+834f, U+8351, U+8356-8358, U+835a, U+835e-8361, U+8363-8364, U+8367-8368, U+836b, U+836f, U+8373, U+8375;
}
/* [42] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.42.woff2) format("woff2");
  unicode-range: U+8113, U+8115, U+8117-8118, U+811a-811b, U+811e, U+8121-8127, U+8129, U+812c, U+812f-8134, U+8137-8138, U+813a, U+813d, U+8144, U+8146, U+8148, U+814a, U+814c-814d, U+8151, U+8153, U+8156, U+8158, U+815a, U+8160, U+8167, U+8169, U+816d, U+816f, U+8171, U+8174, U+817b-817c, U+817e, U+8182, U+8188, U+818a, U+8194-8195, U+8198, U+819b, U+819e, U+81a3, U+81a6-81a7, U+81ab, U+81af-81b0, U+81b5-81b6, U+81b8, U+81ba-81bb, U+81be-81bf, U+81c3, U+81c6, U+81ca, U+81cc, U+81cf, U+81d1-81d3, U+81d6-81d7, U+81d9-81da, U+81dd-81de, U+81e0-81e2, U+81e7, U+81ec, U+81ef, U+81fc, U+81fe, U+8200-8202, U+8204-8206, U+820b, U+820e, U+8210, U+8215, U+8217-8218, U+821b, U+821d, U+8221-8222, U+8224, U+8228-8229, U+822b, U+822f-8234, U+8236-8238, U+823a, U+823e, U+8240, U+8244-8245, U+8249, U+824b, U+824e-824f, U+8257, U+825a, U+825f, U+8264, U+8268, U+826b;
}
/* [43] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.43.woff2) format("woff2");
  unicode-range: U+7fb1-7fb2, U+7fb4, U+7fb6, U+7fb8, U+7fbc, U+7fbf-7fc0, U+7fc3, U+7fcb-7fcc, U+7fce-7fcf, U+7fd1, U+7fd5, U+7fd8, U+7fdb, U+7fde-7fdf, U+7fe5-7fe6, U+7fe9, U+7feb-7fec, U+7fee, U+7ff2-7ff3, U+7ffa, U+7ffd-7ffe, U+8002, U+8004, U+8006, U+800b, U+800e, U+8011-8012, U+8014, U+8016, U+8018-8019, U+801c, U+8024, U+8026, U+8028, U+802c, U+8030, U+8035, U+8037-8039, U+803b, U+8042-8043, U+804b-804c, U+8052, U+8061, U+8068, U+806a, U+806e, U+8071, U+8073-8076, U+8079, U+807c, U+807e-807f, U+8083-8084, U+808f, U+8093, U+8095, U+8098, U+809c, U+809f-80a0, U+80a4, U+80a7, U+80ab, U+80ad-80ae, U+80b0-80b1, U+80b4-80b6, U+80b8, U+80bc-80c2, U+80c4, U+80c6-80c7, U+80cb, U+80cd, U+80cf, U+80d4, U+80d7, U+80d9, U+80db-80dd, U+80e0, U+80e3-80e5, U+80e7, U+80eb-80ed, U+80ef-80f1, U+80f3-80f4, U+80f6-80f7, U+80fc, U+80fe-80ff, U+8101, U+8107, U+8109, U+810f-8112;
}
/* [44] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.44.woff2) format("woff2");
  unicode-range: U+7eaf, U+7eb1-7eb3, U+7eb5-7eba, U+7ebd-7ebe, U+7ec0-7ec1, U+7ec3, U+7ec5, U+7ec7-7eca, U+7ecd-7ece, U+7ed1-7ed2, U+7ed4-7ed5, U+7ed7-7ed8, U+7eda-7edb, U+7edd-7ede, U+7ee2-7ee3, U+7ee5, U+7ee7, U+7ee9-7eeb, U+7eee-7ef0, U+7ef3, U+7ef5, U+7ef7-7ef8, U+7efd-7f01, U+7f03, U+7f05-7f09, U+7f0e, U+7f10, U+7f13-7f15, U+7f18-7f1a, U+7f1c-7f1d, U+7f20, U+7f24-7f25, U+7f28-7f2a, U+7f2d-7f2e, U+7f30, U+7f34, U+7f36-7f37, U+7f3d, U+7f42-7f45, U+7f47-7f4e, U+7f52, U+7f54, U+7f58, U+7f5a, U+7f5d, U+7f5f-7f63, U+7f65, U+7f68, U+7f6b, U+7f78, U+7f7d-7f7e, U+7f81-7f83, U+7f86-7f87, U+7f8b-7f8d, U+7f91, U+7f93-7f95, U+7f97, U+7f99-7f9a, U+7f9d, U+7f9f, U+7fa1-7fa3, U+7fa5, U+7fa7, U+7fad-7fb0;
}
/* [45] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.45.woff2) format("woff2");
  unicode-range: U+7d89, U+7d8b-7d8f, U+7d91, U+7d95-7d96, U+7d98-7d9a, U+7d9d-7d9e, U+7da2-7da3, U+7da6, U+7daa, U+7dac, U+7dae-7db0, U+7db3, U+7db5, U+7db7, U+7db9, U+7dbd, U+7dc1, U+7dc3-7dc7, U+7dcc-7dcf, U+7dd1, U+7dd6-7dd9, U+7ddb-7ddc, U+7de1-7de2, U+7de6, U+7df0-7df3, U+7df6, U+7dfe, U+7e01-7e02, U+7e04, U+7e08-7e0b, U+7e10-7e11, U+7e13, U+7e15, U+7e1d-7e20, U+7e22, U+7e25-7e27, U+7e29, U+7e2d, U+7e2f-7e30, U+7e32-7e37, U+7e39, U+7e3b, U+7e44-7e45, U+7e47-7e48, U+7e4a-7e4b, U+7e4d, U+7e50-7e52, U+7e56, U+7e58-7e5b, U+7e62, U+7e68, U+7e6d-7e70, U+7e76, U+7e78, U+7e7b, U+7e7e, U+7e81-7e82, U+7e86-7e88, U+7e8a, U+7e8d-7e8e, U+7e92-7e94, U+7e98-7e9b, U+7e9e, U+7ea0, U+7ea3-7ea4, U+7ea8, U+7eaa-7eae;
}
/* [46] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.46.woff2) format("woff2");
  unicode-range: U+7c20, U+7c23, U+7c25-7c26, U+7c2a-7c2b, U+7c37-7c39, U+7c40-7c41, U+7c48-7c49, U+7c50, U+7c53-7c54, U+7c56-7c57, U+7c59-7c5c, U+7c5f, U+7c63, U+7c65, U+7c69, U+7c6c-7c6e, U+7c74-7c75, U+7c79, U+7c7c, U+7c7e, U+7c84, U+7c8b, U+7c8d, U+7c91, U+7c94-7c95, U+7c9b, U+7c9f, U+7ca2, U+7ca4, U+7ca6, U+7ca8-7caa, U+7cae, U+7cb1-7cb3, U+7cba, U+7cbc, U+7cbf-7cc0, U+7cc5, U+7cc8-7cc9, U+7ccc-7cce, U+7cd7, U+7cdc-7cdd, U+7ce0, U+7ce2, U+7ce8, U+7cea, U+7ced, U+7cf2, U+7cf4, U+7cf6, U+7cf8-7cfa, U+7cfc, U+7d02, U+7d06-7d0a, U+7d0f, U+7d11-7d12, U+7d15, U+7d18, U+7d1c-7d1e, U+7d25, U+7d27, U+7d29, U+7d2c, U+7d31-7d32, U+7d35, U+7d38, U+7d3a, U+7d3c, U+7d3e-7d41, U+7d43, U+7d45, U+7d4c, U+7d4e-7d4f, U+7d53-7d54, U+7d56, U+7d5b-7d5d, U+7d5f, U+7d63, U+7d67, U+7d6a, U+7d6d, U+7d70, U+7d73, U+7d75, U+7d79-7d7b, U+7d7d, U+7d80, U+7d83-7d84, U+7d86-7d88;
}
/* [47] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.47.woff2) format("woff2");
  unicode-range: U+7afb, U+7afd-7afe, U+7b01-7b06, U+7b09-7b0b, U+7b0e-7b10, U+7b14, U+7b18, U+7b1a, U+7b1e-7b1f, U+7b22-7b25, U+7b29-7b2b, U+7b2d-7b2e, U+7b31-7b35, U+7b38-7b3c, U+7b45, U+7b47-7b48, U+7b4a, U+7b4c, U+7b4e-7b50, U+7b58, U+7b5b, U+7b5d, U+7b60, U+7b62, U+7b65-7b67, U+7b69, U+7b6d-7b6f, U+7b72-7b76, U+7b79, U+7b7e, U+7b82, U+7b84-7b85, U+7b87, U+7b8b, U+7b8d-7b93, U+7b95-7b96, U+7b98, U+7b9c-7b9d, U+7ba0, U+7ba7, U+7ba9-7bac, U+7bb0, U+7bb4, U+7bb6, U+7bb8-7bb9, U+7bc1, U+7bc3, U+7bc6, U+7bcb-7bcc, U+7bcf, U+7bd4, U+7bd9-7bdb, U+7bdd, U+7be0-7be1, U+7be5-7be6, U+7bea, U+7bed-7bee, U+7bf1-7bf3, U+7bf8-7bf9, U+7bfc-7c01, U+7c03, U+7c07, U+7c0a-7c0d, U+7c0f, U+7c11-7c12, U+7c15, U+7c1e-7c1f;
}
/* [48] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.48.woff2) format("woff2");
  unicode-range: U+7988, U+798a-798b, U+7991, U+7993-7996, U+7998, U+799a-799b, U+799f-79a2, U+79a4, U+79a8-79a9, U+79af-79b0, U+79b3, U+79b5, U+79b8, U+79ba, U+79c3, U+79c6, U+79c8, U+79cf, U+79d5-79d6, U+79dc-79de, U+79e3, U+79e7, U+79ea-79ed, U+79ef-79f0, U+79f4, U+79f6-79f8, U+79fd, U+7a02-7a03, U+7a08-7a0a, U+7a0c, U+7a0e, U+7a10-7a11, U+7a14, U+7a17-7a19, U+7a1c, U+7a1e-7a1f, U+7a23, U+7a26, U+7a2d, U+7a32-7a33, U+7a37, U+7a39, U+7a3c, U+7a42, U+7a45, U+7a49, U+7a4f, U+7a56, U+7a5a, U+7a5c, U+7a60-7a61, U+7a63, U+7a68, U+7a6d-7a6e, U+7a70-7a71, U+7a77-7a79, U+7a80, U+7a83, U+7a85-7a86, U+7a88, U+7a8d, U+7a90-7a91, U+7a93-7a96, U+7a98, U+7a9c-7a9d, U+7aa0, U+7aa3, U+7aa5-7aa6, U+7aa8, U+7aaa, U+7aac, U+7ab0, U+7ab3, U+7ab6, U+7ab8, U+7abb, U+7abe-7abf, U+7ac2, U+7ac8-7ac9, U+7ad1-7ad2, U+7ad6, U+7ada, U+7adc-7ade, U+7ae4, U+7ae6, U+7ae9-7aeb, U+7af4, U+7af8, U+7afa;
}
/* [49] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.49.woff2) format("woff2");
  unicode-range: U+7840, U+7842-7843, U+7845, U+7847, U+784a, U+784c-7850, U+7852-7853, U+7855, U+7858, U+785a, U+785c-785d, U+7864, U+7866, U+7868, U+786a, U+786f, U+7874, U+7876, U+787c, U+787f, U+7886-7887, U+7889, U+788d, U+788f, U+7893, U+7895-7896, U+7898, U+789a, U+789e, U+78a1, U+78a3, U+78a5, U+78aa, U+78ad, U+78b1-78b2, U+78b4, U+78b6, U+78b8, U+78be, U+78c8-78c9, U+78cb, U+78ce, U+78d0-78d1, U+78d4-78d5, U+78d7-78d8, U+78de, U+78e3, U+78e6-78e7, U+78ea, U+78ec, U+78f2-78f4, U+78fa-78fb, U+78fd-7900, U+7904-7906, U+790a, U+790c, U+7910-7912, U+791c, U+791e, U+7920-7921, U+792a-792c, U+792e, U+7931, U+7934, U+7938, U+793b, U+793d, U+793f, U+7941-7942, U+7945-7947, U+7949, U+794c, U+794e, U+7953-7954, U+7957-795c, U+795f, U+7961-7962, U+7964, U+7967, U+7969, U+796b-796c, U+796f, U+7972-7973, U+7977-7979, U+797b-797c, U+797e, U+7980, U+7982, U+7984-7987;
}
/* [50] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.50.woff2) format("woff2");
  unicode-range: U+7705, U+7707-7708, U+770a, U+770c, U+770f, U+7715, U+7719-771b, U+771d-771e, U+7722, U+7725-7726, U+7728, U+772d, U+772f, U+7733-7736, U+7738, U+773b, U+773d, U+7741, U+7744, U+7746, U+774c, U+774e-7752, U+7755, U+7759-775a, U+775f-7760, U+7762, U+7765-7766, U+7768-776a, U+776c-776e, U+7771, U+7778, U+777a, U+777d-777e, U+7780, U+7785, U+7787, U+7789, U+778b-778d, U+7791-7793, U+779c, U+779f-77a0, U+77a2, U+77a5, U+77a9, U+77b0-77b1, U+77b4, U+77b6-77b7, U+77b9, U+77bc-77bf, U+77c5, U+77c7, U+77cc-77cd, U+77d3, U+77d6-77d7, U+77dc, U+77de, U+77e3, U+77e7, U+77eb-77ec, U+77f0, U+77f2, U+77f6, U+77f8, U+77fa-77fc, U+77fe-7800, U+7803, U+7805-7806, U+7809, U+7810-7812, U+7815-7816, U+781a, U+781c-781d, U+781f-7823, U+7825-7827, U+7829, U+782c-7830, U+7833, U+7835, U+7837, U+7839-783a, U+783c, U+783e;
}
/* [51] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.51.woff2) format("woff2");
  unicode-range: U+75cc-75cd, U+75d2, U+75d4, U+75d9, U+75df, U+75e2-75e4, U+75e6-75e7, U+75e9-75ec, U+75f0-75f3, U+75f7, U+75f9-75fa, U+75fc, U+75fe-7602, U+7608-760a, U+760c-760d, U+7610, U+7615-7616, U+7618-7620, U+7622-7623, U+7625, U+7627, U+7629, U+762b, U+762e, U+7630, U+7632-7635, U+7638, U+763a-763c, U+763e, U+7640, U+7643, U+7646, U+7648-7649, U+764d-764e, U+7654, U+7658, U+765c, U+765f, U+7663-7667, U+7669, U+766b-766d, U+766f-7670, U+7676, U+7678-767a, U+767f-7681, U+7683, U+7688, U+768a-768b, U+768e, U+7690, U+7695-7696, U+769a-769e, U+76a3-76a4, U+76aa, U+76b0-76b1, U+76b4, U+76b7-76b8, U+76c2, U+76c5, U+76c9, U+76cc-76cd, U+76cf-76d1, U+76d6-76d8, U+76e5-76e6, U+76e9, U+76ec, U+76f1, U+76f7, U+76f9-76fb, U+76ff-7700, U+7704;
}
/* [52] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.52.woff2) format("woff2");
  unicode-range: U+749a, U+749c-74a1, U+74a3, U+74a5-74a7, U+74a9-74ab, U+74ae, U+74b1-74b2, U+74b5, U+74b8, U+74ba, U+74bf, U+74c5, U+74c8, U+74cc-74cd, U+74d2, U+74d4, U+74d6, U+74d8, U+74da, U+74de-74e0, U+74e2, U+74e4, U+74e8-74e9, U+74ee-74ef, U+74f4, U+74f9, U+74fb, U+74ff-7501, U+7503, U+7507, U+750c-750d, U+7511, U+7513, U+7515-7517, U+7519, U+751e, U+7521, U+7525, U+752a, U+752c-752f, U+7534, U+753e, U+7542, U+7545, U+7547-7548, U+754a-754b, U+754e, U+7551, U+755a-755b, U+755d, U+7560, U+7563-7564, U+7566-7568, U+756c-756f, U+7572-7575, U+7577-757a, U+757c, U+757e-757f, U+7583-7584, U+7587, U+7589, U+758b-758e, U+7590, U+7592, U+7594-7595, U+7597, U+7599-759a, U+759d, U+759f, U+75a1-75a3, U+75a5, U+75a7, U+75aa, U+75ac, U+75ae-75b1, U+75b3-75b4, U+75b8, U+75bd, U+75c0, U+75c2-75c4, U+75c9-75ca;
}
/* [53] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.53.woff2) format("woff2");
  unicode-range: U+73b3-73ba, U+73bc, U+73bf, U+73c2, U+73c4-73c6, U+73c9, U+73cb-73cc, U+73ce-73d2, U+73d6-73d7, U+73d9, U+73db-73de, U+73e3, U+73e5-73eb, U+73ef, U+73f5-73f7, U+73f9-73fa, U+73fc-73fd, U+7400-7401, U+7404-7405, U+7407-7408, U+740a-740d, U+740f-7410, U+7416, U+741a-741b, U+741d-741e, U+7420-7425, U+7428-7429, U+742c-7432, U+7435-7436, U+7438-743a, U+743c-7442, U+7445-7446, U+7448-744a, U+7451-7452, U+7454, U+7457, U+7459, U+745d, U+7460-7462, U+7465, U+7467-7468, U+746c-746e, U+7471-7477, U+7479-747a, U+747c-747f, U+7481-7482, U+7484-7486, U+7488-748a, U+748e-7490, U+7492, U+7498;
}
/* [54] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.54.woff2) format("woff2");
  unicode-range: U+727f, U+7281-7282, U+7284, U+7287, U+728a, U+728d, U+7292, U+7296, U+7298, U+729b, U+729f-72a2, U+72ad-72ae, U+72b0-72b5, U+72b8-72b9, U+72bc-72bd, U+72c1, U+72c3, U+72c5-72c6, U+72c8, U+72cc-72ce, U+72d2, U+72d4, U+72db, U+72dd, U+72df, U+72e1, U+72e8, U+72ec-72ee, U+72f1, U+72f3-72f4, U+72f7, U+72fa-72fb, U+72fd, U+7300-7301, U+7304, U+7307, U+730a-730b, U+730e, U+7313, U+7315-7317, U+7319, U+731e-731f, U+7322, U+7328-732e, U+7330-7331, U+7337, U+733a-733c, U+733e, U+7340-7341, U+7343, U+734c-734d, U+734f-7350, U+7352, U+7355, U+7357, U+7359-735a, U+7360-7363, U+7365, U+7369-7370, U+7373-7374, U+7377, U+737a, U+737c, U+737e, U+7380, U+7385-7386, U+738a, U+738e-738f, U+7391-7395, U+7397-7398, U+739b, U+73a0-73a2, U+73a5-73a8, U+73ad-73ae;
}
/* [55] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.55.woff2) format("woff2");
  unicode-range: U+7113-7116, U+7118, U+711c, U+711e, U+7120, U+7122, U+712e-712f, U+7131, U+7134, U+713c, U+713f, U+7143, U+7145-7147, U+714a-714b, U+7150-7153, U+7155-7156, U+715a, U+7160, U+7162, U+7166, U+7168, U+716c, U+7171, U+7173, U+7178, U+717a-717b, U+717d, U+7180-7181, U+7185, U+7187-7188, U+718b, U+718f-7190, U+7192, U+7196-7198, U+719a-719c, U+71a0, U+71a4, U+71a8, U+71af, U+71b2-71b3, U+71b5, U+71b7-71ba, U+71be, U+71c1, U+71c4, U+71ca-71cb, U+71ce-71d0, U+71d4, U+71d7-71d8, U+71da, U+71dc, U+71e0-71e1, U+71e7, U+71ec, U+71f4-71f6, U+71f9, U+71fc, U+71fe-7201, U+7203, U+7207, U+7209, U+720c, U+7213-7215, U+7217, U+721a, U+721d, U+7222-7223, U+7228, U+722b, U+7230, U+7237, U+723b, U+723f-7242, U+724b, U+724d, U+7252-7253, U+7256, U+7258, U+725d, U+7263-7266, U+726a-726b, U+726e-7270, U+7273-7275, U+7277, U+727a-727b, U+727e;
}
/* [56] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.56.woff2) format("woff2");
  unicode-range: U+6fe8-6fe9, U+6fec, U+6fee, U+6ff0, U+6ff3, U+6ff5-6ff6, U+6ffa, U+6ffc, U+6fff-7001, U+7003, U+7005-7007, U+700b, U+700d, U+7015, U+7018, U+701b, U+701e, U+7020-7021, U+7023, U+7026-7027, U+702c, U+702f-7032, U+7034-7035, U+7037-703c, U+7040, U+7042-7044, U+7046, U+7049, U+704b, U+704f, U+7052, U+7054-7055, U+705c-7061, U+7064-7069, U+706c-706d, U+706f, U+7073-7075, U+7078, U+707a, U+707e-7081, U+7085-7086, U+7089, U+7090-7091, U+7094-7096, U+7098, U+709c, U+709f, U+70a1, U+70a4, U+70a9, U+70ac, U+70af-70b2, U+70b4-70b5, U+70b7, U+70bb-70be, U+70c0-70c3, U+70ca-70cb, U+70d2, U+70d4-70d5, U+70d9-70dd, U+70df, U+70e6-70e9, U+70eb-70ec, U+70ef, U+70f1, U+70f4, U+70f7, U+70fa, U+70fd, U+70ff, U+7104, U+7106, U+7109, U+710c, U+7110;
}
/* [57] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.57.woff2) format("woff2");
  unicode-range: U+6ed6, U+6ed8, U+6eda-6edb, U+6edd-6ede, U+6ee2, U+6ee4-6ee5, U+6ee8-6ee9, U+6eeb, U+6eee, U+6ef3, U+6ef8-6efb, U+6f00, U+6f08-6f0a, U+6f0d-6f0e, U+6f11-6f13, U+6f15, U+6f19-6f1a, U+6f23, U+6f25-6f2a, U+6f2d-6f31, U+6f33-6f36, U+6f3a-6f3c, U+6f40-6f41, U+6f43-6f44, U+6f47, U+6f4d-6f4f, U+6f53, U+6f57, U+6f59-6f5a, U+6f5c, U+6f5e-6f61, U+6f63, U+6f66-6f67, U+6f69-6f6c, U+6f6f, U+6f72-6f78, U+6f7a-6f7f, U+6f81-6f82, U+6f87, U+6f89, U+6f8b-6f8d, U+6f90, U+6f92, U+6f94-6f97, U+6f9c, U+6f9f-6fa0, U+6fa2-6fa3, U+6fa5-6fa8, U+6faa-6fab, U+6fae-6faf, U+6fb4, U+6fb6, U+6fb9-6fba, U+6fbc, U+6fc2, U+6fc6-6fcb, U+6fce, U+6fd1-6fd2, U+6fd4, U+6fd8, U+6fda, U+6fde, U+6fe0-6fe2;
}
/* [58] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.58.woff2) format("woff2");
  unicode-range: U+6dd3, U+6dd5-6dd6, U+6dd9, U+6ddb-6de0, U+6de2-6de6, U+6de9, U+6dec, U+6def-6df0, U+6df2, U+6df4, U+6df6, U+6df8, U+6dfc-6dfd, U+6e00, U+6e02-6e03, U+6e07-6e0b, U+6e0d-6e0e, U+6e10, U+6e13-6e15, U+6e17, U+6e19-6e1a, U+6e1d, U+6e1f, U+6e22, U+6e24-6e25, U+6e27, U+6e2b, U+6e2d-6e2e, U+6e30-6e31, U+6e36, U+6e39-6e3a, U+6e3c-6e3d, U+6e40-6e41, U+6e44-6e45, U+6e47, U+6e49, U+6e4b, U+6e4d-6e4f, U+6e51, U+6e53-6e54, U+6e59, U+6e5c, U+6e5e-6e61, U+6e63-6e66, U+6e69-6e6b, U+6e6e, U+6e70-6e76, U+6e78, U+6e7c, U+6e7f-6e80, U+6e83, U+6e85-6e86, U+6e88-6e89, U+6e8b, U+6e8d-6e8f, U+6e93, U+6e98-6e9a, U+6e9f, U+6ea1, U+6ea4-6ea7, U+6eae, U+6eb1-6eb2, U+6eb4-6eb5, U+6eb7, U+6ebd, U+6ec1-6ec3, U+6ec7-6ec9, U+6ecd-6ed0, U+6ed3-6ed5;
}
/* [59] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.59.woff2) format("woff2");
  unicode-range: U+6cda, U+6cdc-6cde, U+6ce0, U+6ce7, U+6ce9-6cec, U+6cee-6cef, U+6cf1, U+6cf7-6cf8, U+6cfb-6cfe, U+6d00-6d02, U+6d04, U+6d06-6d07, U+6d09-6d0a, U+6d0c, U+6d0e-6d12, U+6d18-6d1a, U+6d1f, U+6d22-6d24, U+6d27-6d28, U+6d2b, U+6d2d-6d31, U+6d33-6d3a, U+6d3c, U+6d3f, U+6d43-6d47, U+6d4a-6d4b, U+6d4e-6d4f, U+6d51-6d53, U+6d57-6d58, U+6d5a, U+6d5c, U+6d5e-6d65, U+6d67, U+6d6c-6d6d, U+6d6f-6d70, U+6d72, U+6d75, U+6d79, U+6d7c, U+6d7f, U+6d82, U+6d85, U+6d87, U+6d8e-6d8f, U+6d91-6d95, U+6d97-6d99, U+6d9b, U+6d9d, U+6d9f, U+6da1, U+6da4, U+6da6-6dac, U+6db3-6db4, U+6db7-6db8, U+6dbe-6dc0, U+6dc2, U+6dc4-6dc5, U+6dc8-6dca, U+6dcc-6dcd, U+6dcf-6dd0, U+6dd2;
}
/* [60] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.60.woff2) format("woff2");
  unicode-range: U+6bbb, U+6bbd, U+6bc1-6bc2, U+6bcc, U+6bce, U+6bd0-6bd1, U+6bd5-6bd9, U+6bde, U+6be1, U+6bec, U+6bf3, U+6bf9, U+6bfd, U+6bff-6c00, U+6c02, U+6c05-6c06, U+6c0a, U+6c0c-6c0d, U+6c10, U+6c13, U+6c16, U+6c18-6c1a, U+6c1f, U+6c21-6c22, U+6c24, U+6c26, U+6c28-6c2a, U+6c2c, U+6c2e-6c33, U+6c35-6c37, U+6c39-6c3a, U+6c3d-6c3f, U+6c43, U+6c46, U+6c49-6c4f, U+6c54-6c55, U+6c58, U+6c5a-6c5c, U+6c5e, U+6c64-6c69, U+6c6b-6c6f, U+6c71-6c75, U+6c78-6c79, U+6c7c, U+6c7e, U+6c82, U+6c84-6c87, U+6c8c-6c8d, U+6c8f, U+6c93-6c94, U+6c98, U+6c9a, U+6c9d, U+6c9f, U+6ca2, U+6ca5-6ca8, U+6caa, U+6cac-6cb2, U+6cb4-6cb5, U+6cba, U+6cbc, U+6cc2-6cc3, U+6cc5-6cc7, U+6cd0-6cd2, U+6cd4, U+6cd6-6cd7, U+6cd9;
}
/* [61] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.61.woff2) format("woff2");
  unicode-range: U+6a31-6a32, U+6a35, U+6a3b, U+6a3e-6a40, U+6a47-6a48, U+6a50, U+6a52, U+6a55-6a57, U+6a5a-6a5b, U+6a5e, U+6a62, U+6a66, U+6a6a, U+6a71, U+6a79, U+6a7c, U+6a7e-6a81, U+6a84, U+6a87, U+6a89, U+6a8d-6a8e, U+6a90-6a92, U+6a97, U+6a9c, U+6a9e-6aa1, U+6aa3-6aa5, U+6aa8, U+6aab, U+6aae, U+6ab5, U+6aba, U+6abe, U+6ac2, U+6ac5-6ac6, U+6ac8-6ac9, U+6acc, U+6ad3, U+6ada-6adb, U+6add-6adf, U+6ae3, U+6ae7-6ae8, U+6aea-6aec, U+6af1, U+6af3, U+6af8, U+6afa, U+6afc, U+6b05, U+6b09, U+6b0e-6b13, U+6b17, U+6b1d-6b1e, U+6b25, U+6b2c, U+6b31, U+6b35-6b37, U+6b39, U+6b3b, U+6b40, U+6b43, U+6b46, U+6b48, U+6b53-6b55, U+6b59, U+6b5b, U+6b5f-6b60, U+6b68-6b69, U+6b6f, U+6b74, U+6b7a, U+6b7c, U+6b7f-6b84, U+6b86-6b87, U+6b89, U+6b8b, U+6b8d, U+6b91-6b93, U+6b9b, U+6b9e, U+6ba1-6ba2, U+6ba4, U+6baa-6bab, U+6bad-6bae, U+6bb2-6bb4;
}
/* [62] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.62.woff2) format("woff2");
  unicode-range: U+6900, U+6902, U+6904, U+6906-6907, U+6909, U+690b, U+690f-6910, U+6917, U+691a-691c, U+6925, U+692a, U+692c-692d, U+6932, U+6934, U+6939, U+693c-6940, U+6942, U+6949, U+6952, U+6954-6957, U+6959, U+695b-695f, U+6961-696c, U+696e-6970, U+6973-6974, U+6976, U+6978-697a, U+697c, U+6980, U+6984-6986, U+6988-698a, U+698d-698e, U+6990-6991, U+6994, U+6996-699b, U+699e, U+69a3-69a7, U+69ab, U+69ad, U+69af, U+69b1, U+69b3, U+69b6-69b7, U+69bb-69bc, U+69bf, U+69c1, U+69c3-69c5, U+69c7, U+69ca, U+69cc, U+69ce, U+69d0-69d1, U+69d4, U+69d8-69d9, U+69db, U+69df, U+69e4, U+69e8-69ea, U+69ed-69ee, U+69f1-69f4, U+69f6, U+69f8, U+69fa-69fb, U+69ff-6a00, U+6a05, U+6a0a-6a0b, U+6a17-6a18, U+6a1b, U+6a28-6a2b;
}
/* [63] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.63.woff2) format("woff2");
  unicode-range: U+67d9, U+67db-67e0, U+67e2, U+67e4, U+67e9-67ea, U+67f0, U+67f2, U+67f6-67f8, U+67fa-67fb, U+67fe, U+6800-6805, U+6808-6809, U+680b, U+680e-680f, U+6811-6812, U+6814, U+6816, U+6818, U+681c-681e, U+6820, U+6822, U+6825, U+6827-6829, U+682b, U+682e-682f, U+6831-6834, U+683a-683b, U+683e, U+6840-6841, U+6844-6845, U+6849, U+684e, U+6853, U+6855-6856, U+685c-685d, U+685f, U+6861-6863, U+6865-6869, U+686b, U+686d, U+686f, U+6871-6872, U+6874-6875, U+6877, U+6879, U+687b-687c, U+687e, U+6880, U+6882-6883, U+6886, U+688f, U+6891-6892, U+6894, U+6896, U+6898, U+689b-689c, U+689f-68a0, U+68a2-68a3, U+68a6, U+68a9, U+68b1-68b2, U+68b4, U+68b6, U+68c0, U+68c3, U+68c6, U+68c8, U+68ca, U+68d0-68d1, U+68d3, U+68d6, U+68e1, U+68e3, U+68e6, U+68e8-68ec, U+68ef-68f1, U+68f3, U+68f6-68f7, U+68f9, U+68fb-68fd;
}
/* [64] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.64.woff2) format("woff2");
  unicode-range: U+66a7, U+66aa, U+66b2-66b3, U+66b5, U+66b8-66bc, U+66be, U+66c1, U+66c4, U+66c7-66c8, U+66cc, U+66cf, U+66d5, U+66d8-66db, U+66df, U+66e1-66e2, U+66e8-66e9, U+66ef, U+66f1, U+66f5, U+66f7, U+66fa, U+66fd, U+6705, U+670a, U+670f-6710, U+6713-6715, U+6718-6719, U+6720, U+6722-6727, U+6729, U+672e, U+6733, U+6736, U+6738-6739, U+673f-6740, U+6742, U+6745, U+6747-6748, U+674b-674d, U+6753, U+6755, U+6759, U+675d-675e, U+6760, U+6762-6763, U+6767-676a, U+676c, U+676e, U+6772-6777, U+677a-677c, U+6782, U+6786-6787, U+678a-678c, U+678e-678f, U+6791-6793, U+6796, U+6798-6799, U+679f-67a3, U+67a5, U+67aa-67ae, U+67b0, U+67b2-67b5, U+67b7-67bc, U+67c0-67c3, U+67c5-67c6, U+67c8-67ca, U+67ce, U+67d2, U+67d8;
}
/* [65] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.65.woff2) format("woff2");
  unicode-range: U+655d, U+6561, U+6564-6565, U+6567, U+656b, U+656d-656e, U+6573, U+6576, U+6579-657b, U+6581, U+6586, U+6588-6589, U+658b, U+658e, U+6593, U+6595, U+659b, U+659d, U+659f-65a1, U+65a9, U+65ab, U+65ad, U+65b2-65b3, U+65b5, U+65bb, U+65be-65bf, U+65c2-65c4, U+65c6, U+65cc, U+65ce, U+65d2, U+65d6, U+65db, U+65e1, U+65e3, U+65e7, U+65ee-65f0, U+65f2-65f4, U+65f7-65f8, U+65fc-65fd, U+6600, U+6603-6605, U+6609, U+660d, U+6610-6611, U+6619, U+661c-661e, U+6621-6622, U+6624, U+6626, U+6629, U+662b, U+6630, U+6633-6636, U+6639-663d, U+6640-6641, U+6644-6645, U+664a-664c, U+6653-6657, U+6659, U+665b, U+665d-665e, U+6661-6667, U+6669, U+666c, U+6672-6673, U+6677-6679, U+667b-667e, U+6681-6684, U+668b-6690, U+6692, U+6698, U+669d, U+669f-66a0, U+66a6;
}
/* [66] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.66.woff2) format("woff2");
  unicode-range: U+6427-6428, U+642a-642b, U+642f-6430, U+6432, U+6434, U+6437, U+643a, U+643d-6444, U+6446-6447, U+644a-644b, U+644e, U+6450-6453, U+6456, U+6459, U+645b-645c, U+645e, U+6460-6461, U+6463-6465, U+6468, U+646c-646e, U+6470, U+6472-6477, U+6479, U+647b, U+647d, U+6480, U+6482, U+6485, U+648b-648c, U+6491, U+6493, U+6496-649a, U+649d, U+649f-64a0, U+64a2-64a3, U+64ac, U+64b1, U+64b3-64b4, U+64b7-64b9, U+64bb, U+64be, U+64c0, U+64c3-64c4, U+64d0, U+64d2, U+64d5, U+64d7-64d8, U+64e1-64e4, U+64e7, U+64e9, U+64ed, U+64ef-64f0, U+64f3, U+64f8, U+64fb-64fc, U+64ff, U+6504-6506, U+6509, U+6511-6512, U+6516, U+6518-6519, U+651b, U+6520-6523, U+6525-6526, U+6529, U+652b, U+652e, U+6530, U+6532, U+6534-6535, U+6537-6538, U+653a, U+653d, U+6542-6543, U+6549, U+654c-654e, U+6554-6555, U+655b;
}
/* [67] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.67.woff2) format("woff2");
  unicode-range: U+630d-630e, U+6310, U+6312-6313, U+6319-631b, U+631d-6321, U+6323-6325, U+632d-632e, U+6332, U+6334-6339, U+633b-633c, U+633e-6340, U+6342-6346, U+634b-634c, U+634e, U+6352, U+6357, U+635a, U+635c, U+635e-635f, U+6361, U+6363, U+6365, U+6369, U+636b-636d, U+636f-6370, U+6373, U+6375-6376, U+637a-637b, U+637d, U+6381, U+6384, U+6387, U+638a, U+638d-638e, U+6390, U+6394-6397, U+639e-639f, U+63a3-63a4, U+63a6, U+63ac-63af, U+63b1-63b4, U+63b7, U+63b9-63bb, U+63bd-63be, U+63c3-63c4, U+63c8, U+63cd-63ce, U+63d1, U+63d6, U+63dc, U+63de, U+63e0, U+63e3-63e4, U+63e6, U+63e9, U+63f0, U+63f2-63f3, U+63f5-63f8, U+63fa, U+63fc-63fe, U+6400-6402, U+6405-6406, U+6409-640c, U+6410, U+6414-6415, U+6418, U+641b, U+641f-6423, U+6425-6426;
}
/* [68] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.68.woff2) format("woff2");
  unicode-range: U+61c6, U+61ca-61cd, U+61d0, U+61d2, U+61de-61e0, U+61e3, U+61e6, U+61e8, U+61ed-61ee, U+61f5, U+61f9-61fa, U+61fd-61fe, U+6207, U+6209, U+620d-620e, U+6213-6215, U+6219, U+621b, U+621d-6223, U+6225-6227, U+6229, U+622b-622c, U+622e-622f, U+6231, U+6238, U+623b, U+623d-623e, U+6242-6243, U+6246, U+6248-6249, U+624c, U+6251, U+6255, U+6259-625a, U+625e, U+6260-6262, U+6265-6267, U+6269, U+626b-626c, U+6270-6273, U+6275, U+627a-627d, U+6283, U+6285-6286, U+6289, U+628c, U+628e, U+6294, U+629a-629e, U+62a0, U+62a2, U+62a6, U+62a8, U+62af, U+62b3, U+62b6, U+62ba-62bb, U+62be-62bf, U+62c2, U+62c4-62c5, U+62c8, U+62ca, U+62cf, U+62d1, U+62d5, U+62d7, U+62d9, U+62dd, U+62df-62e3, U+62e5-62e8, U+62ee, U+62f4-62fb, U+62fd, U+6300, U+6302, U+6308, U+630c;
}
/* [69] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.69.woff2) format("woff2");
  unicode-range: U+60ac-60ad, U+60af-60b1, U+60b3-60b5, U+60b8, U+60bb, U+60bd-60be, U+60c0, U+60c6-60c7, U+60ca-60cb, U+60d3-60d5, U+60d7-60db, U+60dd, U+60e2-60e3, U+60e6-60f0, U+60f2, U+60f4, U+60f6, U+60fa-60fb, U+60ff-6100, U+6103, U+6106, U+610a-610b, U+610d-610e, U+6110, U+6112-6116, U+6120, U+6123-6124, U+6128-6130, U+6134, U+6136, U+613c-613f, U+6144, U+6146-6147, U+6149-614a, U+614d, U+6151-6153, U+6159-615a, U+615c-615f, U+6164-6165, U+6169-616d, U+616f, U+6171-6175, U+6177, U+617a, U+617c, U+617f-6180, U+6187, U+618a-618e, U+6192-6194, U+6199-619b, U+619f, U+61a1, U+61a7-61a8, U+61aa-61af, U+61b8, U+61ba, U+61bf, U+61c3;
}
/* [70] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.70.woff2) format("woff2");
  unicode-range: U+5f78, U+5f7b, U+5f7d, U+5f82-5f84, U+5f87, U+5f89-5f8a, U+5f8d, U+5f93, U+5f95, U+5f98-5f99, U+5f9b-5f9c, U+5fa0, U+5fa4, U+5fa6-5fa8, U+5fab-5fad, U+5fb3-5fb4, U+5fbc, U+5fc4, U+5fc6, U+5fc9, U+5fcb, U+5fce-5fd6, U+5fdc-5fdf, U+5fe1, U+5fe4, U+5fe7, U+5fea, U+5fec-5fee, U+5ff1, U+5ff3, U+5ff8, U+5ffa-5ffc, U+5fff-6000, U+6002, U+6005, U+600a, U+600d, U+600f-6010, U+6014, U+6017, U+6019-601c, U+601e, U+6020, U+6022, U+6026, U+6029, U+602b-602c, U+602e-602f, U+6031, U+6033-6035, U+6039, U+603c, U+6040-6043, U+6045, U+6047, U+604a-604c, U+604f, U+6053, U+6059-605b, U+605d, U+6060, U+6063, U+6067, U+606a-606b, U+606e, U+6072-6076, U+6078, U+607a, U+607c, U+607f-6081, U+6083, U+6086, U+608a, U+608c, U+608e, U+6092-6093, U+6095-6097, U+609b, U+609d, U+60a2, U+60a6-60a7, U+60a9-60aa;
}
/* [71] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.71.woff2) format("woff2");
  unicode-range: U+5e34, U+5e37, U+5e3a, U+5e3c, U+5e40, U+5e42-5e44, U+5e47, U+5e54, U+5e57-5e5b, U+5e5e-5e5f, U+5e61-5e62, U+5e64, U+5e6a, U+5e6c, U+5e6e, U+5e75, U+5e77, U+5e7a, U+5e80-5e81, U+5e83, U+5e86, U+5e88, U+5e8b, U+5e90, U+5e92, U+5e96, U+5e99, U+5e9b, U+5e9d-5ea2, U+5ea4-5ea5, U+5eb3-5eb6, U+5eb9, U+5ebe, U+5ec3-5ec4, U+5ec6, U+5ecb-5ecd, U+5ed0-5ed2, U+5ed4-5ed5, U+5ed8-5ed9, U+5edb, U+5edd, U+5ee1, U+5ee8-5ee9, U+5eec, U+5eef-5ef0, U+5ef4-5ef5, U+5ef8, U+5efb-5efc, U+5efe, U+5f01-5f03, U+5f05, U+5f07-5f09, U+5f0b-5f0e, U+5f10-5f12, U+5f14, U+5f16, U+5f1b, U+5f1d, U+5f22, U+5f25, U+5f28-5f29, U+5f2d, U+5f2f-5f30, U+5f36, U+5f38-5f39, U+5f3c, U+5f3e, U+5f40-5f42, U+5f45-5f46, U+5f4a, U+5f50-5f52, U+5f54, U+5f56-5f58, U+5f5a-5f5e, U+5f61, U+5f63, U+5f66-5f67, U+5f6b, U+5f72-5f74, U+5f76;
}
/* [72] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.72.woff2) format("woff2");
  unicode-range: U+5ce0-5ce1, U+5ce5-5ce6, U+5ce8-5cea, U+5ced-5cee, U+5cf1, U+5cf4-5cf5, U+5cf8, U+5cfe-5d00, U+5d06, U+5d08, U+5d0a-5d0d, U+5d15, U+5d18, U+5d1a, U+5d1d, U+5d1f-5d22, U+5d24, U+5d26-5d28, U+5d2c-5d2e, U+5d33-5d35, U+5d3d, U+5d3f, U+5d42-5d43, U+5d46-5d47, U+5d49-5d4b, U+5d4e, U+5d52-5d53, U+5d57-5d59, U+5d5b-5d5c, U+5d65, U+5d68-5d69, U+5d6b-5d6c, U+5d6f, U+5d74-5d75, U+5d7e-5d7f, U+5d81-5d82, U+5d85-5d88, U+5d8b-5d8c, U+5d92, U+5d94, U+5d97, U+5d99, U+5d9d, U+5da0-5da2, U+5da7, U+5da9-5daa, U+5dae, U+5db2, U+5db4, U+5db7-5db8, U+5dbd, U+5dc2-5dc5, U+5dc9, U+5dcb-5dcd, U+5dd2, U+5dd6, U+5dd8, U+5ddb-5ddc, U+5de0, U+5de3, U+5de9, U+5df0, U+5df3, U+5df5, U+5df9, U+5dfb-5dfd, U+5e00-5e01, U+5e04-5e05, U+5e0a, U+5e11, U+5e14, U+5e18-5e1c, U+5e1f-5e22, U+5e27-5e28, U+5e2f-5e30;
}
/* [73] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.73.woff2) format("woff2");
  unicode-range: U+5b80-5b82, U+5b84, U+5b8d, U+5b92-5b93, U+5b95-5b96, U+5b9f-5ba1, U+5ba6-5ba8, U+5baa-5bad, U+5bbd-5bbe, U+5bc0-5bc1, U+5bc3, U+5bd0-5bd1, U+5bd4-5bd8, U+5bdb-5bdd, U+5be4-5be5, U+5bef, U+5bf3, U+5bfb, U+5bfe-5bff, U+5c02-5c03, U+5c05, U+5c09, U+5c0c, U+5c10, U+5c12-5c13, U+5c15, U+5c18-5c19, U+5c1b, U+5c1d-5c1f, U+5c22, U+5c25, U+5c27-5c28, U+5c2a-5c2b, U+5c34, U+5c38, U+5c3d, U+5c42, U+5c44, U+5c47, U+5c49-5c4a, U+5c50, U+5c53, U+5c58-5c59, U+5c5b, U+5c5d, U+5c61, U+5c63, U+5c68, U+5c6d-5c6e, U+5c74, U+5c79-5c84, U+5c86, U+5c88, U+5c8a-5c8d, U+5c92-5c9c, U+5ca0, U+5ca2-5ca3, U+5ca5-5ca7, U+5cab-5cad, U+5cb5, U+5cb7, U+5cba-5cbb, U+5cc1, U+5cc8, U+5cca-5ccb, U+5cce, U+5cd2, U+5cd6, U+5cd8-5cda, U+5cdf;
}
/* [74] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.74.woff2) format("woff2");
  unicode-range: U+5a51, U+5a53, U+5a56-5a57, U+5a5d-5a5e, U+5a60-5a62, U+5a65, U+5a67, U+5a6a, U+5a6c-5a6d, U+5a73-5a76, U+5a7a-5a7c, U+5a81-5a84, U+5a8c, U+5a90, U+5a93, U+5a96-5a97, U+5a9c, U+5a9e-5aa0, U+5aa4, U+5aa7, U+5aab-5aac, U+5aae-5aaf, U+5ab1, U+5ab4-5ab5, U+5ab8, U+5aba-5abc, U+5abe-5abf, U+5ac3-5ac4, U+5ac6-5acb, U+5acf-5ad2, U+5ad4-5adc, U+5ae0-5ae1, U+5ae3, U+5ae5-5ae6, U+5ae8, U+5aea-5aeb, U+5aee, U+5af0, U+5af2, U+5af5, U+5afa, U+5aff, U+5b01, U+5b05, U+5b08, U+5b0b, U+5b11, U+5b16-5b17, U+5b19, U+5b1b, U+5b1d, U+5b21-5b23, U+5b28, U+5b2a-5b2d, U+5b32, U+5b34, U+5b36-5b38, U+5b3e-5b40, U+5b43-5b46, U+5b4b-5b4c, U+5b51, U+5b53, U+5b59, U+5b5b-5b5c, U+5b62, U+5b65, U+5b6c-5b6e, U+5b70-5b73, U+5b75, U+5b7a-5b7b, U+5b7d, U+5b7f;
}
/* [75] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.75.woff2) format("woff2");
  unicode-range: U+590b-590c, U+590e, U+5910-5911, U+5914, U+5919, U+591b, U+591f, U+5923-5924, U+592c, U+5932, U+5938-593a, U+5940, U+5942, U+5944, U+594b-594c, U+594e, U+5950, U+5953, U+5956, U+5958, U+595a, U+5961, U+5966, U+5968-5969, U+596c-596d, U+5975, U+5977, U+597b-597c, U+597e, U+5980-5981, U+5986-5988, U+598a, U+598f, U+5997-5998, U+599f-59a3, U+59a6-59a7, U+59a9, U+59ab-59ac, U+59af, U+59b1-59b2, U+59b6, U+59b8, U+59ba, U+59be, U+59c1, U+59c3, U+59c7-59c9, U+59cd-59ce, U+59d2, U+59d6-59d9, U+59dd-59de, U+59e0, U+59e3-59e5, U+59e9-59eb, U+59ee, U+59f1-59f3, U+59f5-59f9, U+59fc-59fd, U+5a00, U+5a04-5a07, U+5a09, U+5a0c, U+5a11, U+5a13, U+5a16-5a17, U+5a1a, U+5a1e, U+5a20, U+5a23-5a24, U+5a29-5a2b, U+5a2d-5a2f, U+5a32-5a34, U+5a38, U+5a3c, U+5a3f-5a44, U+5a47-5a48, U+5a4a, U+5a4c-5a4d, U+5a50;
}
/* [76] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.76.woff2) format("woff2");
  unicode-range: U+57a1, U+57a4, U+57a6, U+57a9, U+57ab, U+57b5, U+57b8-57bb, U+57c2, U+57c5-57c8, U+57cc, U+57cf, U+57d2, U+57dc-57de, U+57e1-57e2, U+57e5, U+57e7, U+57ed-57ee, U+57f0, U+57f3-57f6, U+57f8, U+57fb-57fd, U+5800-5801, U+5803-5804, U+5807, U+5809-580b, U+580d-580e, U+5810-5811, U+5814-5815, U+5819, U+581d-581e, U+5820, U+5823, U+5826, U+582c-582d, U+5830, U+583a, U+583f-5841, U+5848, U+584b, U+584d, U+584f, U+5852, U+5859-585a, U+585c, U+5861, U+5864, U+5868-5869, U+586c-586d, U+5871-5872, U+5879, U+587c-5881, U+5887-5889, U+588e, U+5890-5892, U+5896-5899, U+589d, U+58a1, U+58a3, U+58a6, U+58a9, U+58ac, U+58b0-58b1, U+58bb-58bc, U+58c2, U+58c5-58c6, U+58ca, U+58cc, U+58ce, U+58d0-58d1, U+58d5, U+58d9-58da, U+58df-58e0, U+58e9, U+58ec, U+58ee, U+58f1-58f3, U+58f6-58f7, U+58fb-58fc, U+5900, U+5902, U+5906, U+5909-590a;
}
/* [77] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.77.woff2) format("woff2");
  unicode-range: U+566f, U+5671-5672, U+5676, U+567a-567c, U+5680, U+5684-5686, U+568c, U+568e-568f, U+5692-5693, U+5697-5699, U+569c, U+569e, U+56a1-56a7, U+56a9, U+56ab-56ad, U+56af, U+56b3, U+56b5-56b6, U+56b8, U+56bf-56c1, U+56c3, U+56c5, U+56c7-56c8, U+56cb-56cc, U+56d2-56d4, U+56d6-56d9, U+56df, U+56e1-56e5, U+56ea-56ec, U+56ee-56ef, U+56f1-56f4, U+56f7, U+56f9, U+56ff-5700, U+5703-5704, U+5706-5707, U+5709-570a, U+570c, U+570f, U+5711, U+5717, U+571c, U+5723-5724, U+5727, U+5729-572a, U+572c, U+572e-572f, U+5734-5735, U+573b, U+5741, U+574b-574d, U+574f, U+5752, U+5754, U+575a-5760, U+5763, U+5768-5769, U+576b, U+576d, U+576f-5770, U+5772-5775, U+5777, U+577b-577d, U+5780, U+5784, U+5788, U+578c, U+578e, U+5792-5793, U+5795, U+579a-579b, U+579f-57a0;
}
/* [78] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.78.woff2) format("woff2");
  unicode-range: U+5551, U+5553, U+5555-5557, U+555c, U+5562-5563, U+5567, U+5569, U+556b-556c, U+5570, U+5575-5579, U+557b-557c, U+557f, U+5581, U+5583, U+5586, U+5588, U+558b, U+558f, U+5591, U+5599, U+559f, U+55a1, U+55a3, U+55a5-55a6, U+55a8-55a9, U+55ab, U+55ad, U+55b0-55b1, U+55b3, U+55b6-55b7, U+55b9, U+55bc-55bd, U+55c4-55c5, U+55c7, U+55c9, U+55cc-55cd, U+55d0, U+55d2, U+55d4-55d9, U+55db, U+55dd-55df, U+55e1-55e6, U+55e9-55ea, U+55ec, U+55ee, U+55f1-55f3, U+55f5-55f7, U+55f9-55fa, U+55fe, U+5600-5602, U+5608, U+560c, U+560f, U+5612-5613, U+5615-5616, U+5618, U+561a, U+561c, U+561e, U+5620, U+5623-5625, U+5627, U+562a, U+562c-562e, U+5630-5631, U+5635-5636, U+5638-563a, U+5640, U+5642-5643, U+5649, U+564c-5650, U+5654, U+5658-565d, U+5664-5666, U+5669, U+566b, U+566d;
}
/* [79] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.79.woff2) format("woff2");
  unicode-range: U+543d, U+543f, U+5441, U+5444-5445, U+5447, U+5449, U+544b-544d, U+5450-5455, U+5457, U+545b-545c, U+545f-5460, U+5463-5464, U+546a-5472, U+5474, U+5476, U+5478, U+547b, U+547e-547f, U+5482-5488, U+548a, U+548d-5491, U+5494, U+5498-549d, U+54a1-54a5, U+54ab, U+54ad-54af, U+54b5, U+54b7, U+54bb-54bc, U+54be-54bf, U+54ca, U+54cc, U+54cf-54d2, U+54d4, U+54d6-54d7, U+54da, U+54de-54df, U+54e2, U+54e4, U+54e7, U+54eb, U+54f3, U+54fd, U+54ff, U+5501-5502, U+5504-5506, U+550a, U+550c, U+550e-550f, U+5511-5513, U+5516-5517, U+551a-551b, U+551e, U+5520, U+5524, U+5526-5527, U+552a, U+552c-552d, U+5530, U+5532-5533, U+5535-5536, U+553b-553c, U+553e-553f, U+5541-5542, U+5544-5545, U+5547, U+5549, U+554b, U+554d-554e, U+5550;
}
/* [80] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.80.woff2) format("woff2");
  unicode-range: U+52db, U+52e0, U+52e3, U+52e6-52e7, U+52eb, U+52ed-52ee, U+52f0-52f2, U+52f7, U+52f9-52fa, U+5300-5302, U+530a-530b, U+530d, U+530f-5310, U+5315, U+531a, U+531c-531d, U+532d-532e, U+5331, U+5338, U+533b-533e, U+5344-5345, U+534b-534d, U+534f-5350, U+5358, U+535e-535f, U+5362-5364, U+5367, U+5369, U+536b-536c, U+536e-536f, U+5372, U+5374, U+5379-537a, U+537c-537d, U+5382, U+5385, U+5389, U+538b-538c, U+538e, U+5392-5396, U+5399, U+53a0-53a2, U+53a4-53a6, U+53a8-53a9, U+53ae, U+53b0, U+53b3-53b4, U+53b6-53b7, U+53b9, U+53bf, U+53c1, U+53c4, U+53ce-53cf, U+53d2, U+53d5, U+53d9-53da, U+53df-53e1, U+53e7-53e9, U+53f1, U+53f5-53f6, U+53f9, U+53fb-53fd, U+5400-5402, U+5405-5407, U+540f, U+5412, U+5414-5417, U+541a, U+5420-5421, U+5424-5425, U+5428-5429, U+542c-542f, U+5431-5432, U+5434, U+5437;
}
/* [81] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.81.woff2) format("woff2");
  unicode-range: U+518f, U+5191, U+5193, U+5195-5197, U+519a-519c, U+519e, U+51a2, U+51a6-51a9, U+51ab, U+51ad-51af, U+51b1-51b6, U+51ba-51c0, U+51c3-51c5, U+51c7, U+51c9-51cb, U+51ce-51d1, U+51d4, U+51d6, U+51d9, U+51db-51dc, U+51df, U+51e4, U+51e6, U+51e9-51ea, U+51ed, U+51ef, U+51f4-51f5, U+51fc, U+51ff, U+5201-5202, U+5204-5205, U+5208, U+520b, U+520d-520e, U+5213, U+5215-5216, U+5218, U+521a, U+5220, U+5223, U+5226-5228, U+5232-5234, U+5239, U+523c, U+5241-5242, U+5244, U+5249, U+524c, U+5251-5252, U+5255, U+5257, U+525c, U+525e, U+5261, U+5263-5265, U+526e, U+5270, U+5273-5274, U+5277, U+527d, U+527f, U+5281-5282, U+5284, U+528a, U+528c, U+528f, U+5292-5294, U+529d, U+52a6, U+52ac-52ad, U+52b1-52b5, U+52b9, U+52bb-52bc, U+52be-52c0, U+52c5, U+52cb, U+52cd, U+52d0-52d1, U+52d6-52d7;
}
/* [82] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.82.woff2) format("woff2");
  unicode-range: U+5032, U+5036, U+503a-503b, U+503e, U+5040-5041, U+5043, U+5045-5046, U+5048, U+504a-504e, U+5051-5053, U+505d-5060, U+5063, U+506a, U+506f-5072, U+5078, U+507a-507b, U+507f-5080, U+5088-5089, U+508b-508c, U+508e, U+5092, U+5095-5096, U+509a-509d, U+50a3, U+50a5, U+50a8, U+50af, U+50b1, U+50b4, U+50ba, U+50c2, U+50c6-50ca, U+50cd-50ce, U+50d6, U+50d9, U+50dd-50df, U+50e1, U+50e3, U+50e5-50e6, U+50e8-50ea, U+50ec-50f0, U+50f3, U+50fb, U+50fe, U+5101-5102, U+5105-5109, U+510b-510e, U+5110, U+5113-5115, U+5117, U+511a-511c, U+511e, U+5120-5121, U+5125, U+512b, U+5131, U+5134-5135, U+5138-513c, U+5140, U+514e, U+5150-5151, U+5155-5157, U+515a, U+515f, U+5162, U+516a, U+516e, U+5172, U+5174, U+5179, U+517b, U+517d, U+5182, U+5186, U+5188-5189, U+518b;
}
/* [83] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.83.woff2) format("woff2");
  unicode-range: U+4f22, U+4f24, U+4f29-4f2b, U+4f2d, U+4f31-4f32, U+4f35, U+4f37, U+4f39, U+4f3b, U+4f3e, U+4f41-4f43, U+4f47, U+4f49, U+4f4c, U+4f52, U+4f57-4f58, U+4f5a, U+4f5d-4f5f, U+4f61, U+4f63-4f64, U+4f67, U+4f6a, U+4f6e-4f6f, U+4f72, U+4f74, U+4f76-4f7b, U+4f7d-4f7e, U+4f80-4f82, U+4f84, U+4f89-4f8a, U+4f8e-4f94, U+4f96-4f98, U+4f9a, U+4f9e, U+4fa0-4fa3, U+4fa5-4fa8, U+4faa-4fac, U+4fb2-4fb3, U+4fb7-4fba, U+4fc0-4fc1, U+4fc5-4fc7, U+4fcb, U+4fcd-4fce, U+4fd1, U+4fd3-4fd4, U+4fd8-4fdc, U+4fdf, U+4fe2-4fe5, U+4fe8-4fea, U+4fec-4fed, U+4ff3-4ff6, U+4ff8-4ffa, U+4ffd, U+5000, U+5002, U+5005, U+5008, U+500c, U+500f, U+5013-5015, U+501b-501c, U+501e, U+5022-5025, U+5027-5028, U+502c-502e, U+5030-5031;
}
/* [84] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.84.woff2) format("woff2");
  unicode-range: U+3a17, U+3a52, U+3b22, U+3bd7, U+3bff, U+3ca5, U+3d68, U+3ddb, U+3de7, U+3deb, U+3e03, U+3e74, U+3f08, U+3f0e, U+3f21, U+3f97, U+4102, U+411b, U+4181, U+43c8, U+4552, U+4576, U+46e5, U+4837, U+493d, U+4a3b, U+4d09, U+4e02, U+4e04-4e05, U+4e0c, U+4e0f-4e10, U+4e15, U+4e17, U+4e1b, U+4e21-4e22, U+4e25, U+4e27, U+4e31, U+4e34, U+4e36-4e37, U+4e3d, U+4e3f-4e42, U+4e44, U+4e47, U+4e49, U+4e4c, U+4e52-4e54, U+4e57, U+4e5a-4e5b, U+4e60-4e61, U+4e69, U+4e6d, U+4e78, U+4e80-4e81, U+4e85, U+4e87, U+4e89-4e8a, U+4e8d, U+4e8f, U+4e93, U+4e96, U+4e98-4e99, U+4e9c, U+4e9f-4ea0, U+4ea2, U+4ea5, U+4ea9, U+4eb0, U+4eb2-4eb3, U+4eb5-4eb7, U+4eb9, U+4ebb-4ebc, U+4ebf, U+4ec2-4ec6, U+4ec8-4ec9, U+4ecf, U+4ed1, U+4ed3, U+4edc-4ee1, U+4ee7-4eeb, U+4eee-4eef, U+4ef1, U+4ef3-4ef5, U+4efa, U+4efc, U+4f00, U+4f02-4f03, U+4f05, U+4f07-4f09, U+4f0b, U+4f0e, U+4f15, U+4f17, U+4f1d-4f1f;
}
/* [85] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.85.woff2) format("woff2");
  unicode-range: U+305c, U+3062, U+306d, U+307a, U+307c, U+3080, U+308e, U+3090-3091, U+3099-309e, U+30a5, U+30c2, U+30c5, U+30ee, U+30f0-30f2, U+30f4-30f6, U+30fd-30fe, U+3105-3106, U+3108, U+310a-310b, U+310d-3119, U+311b-311e, U+3120-3126, U+3128-3129, U+3131, U+3134, U+3137, U+3139, U+3141-3142, U+3145, U+3147-3148, U+314b, U+314d-314f, U+3153, U+315c, U+3160-3161, U+3163-3164, U+3181, U+318d, U+3192-3193, U+3196-3198, U+319d-319f, U+3220-3226, U+3231, U+3268, U+3281, U+328b, U+3291-3292, U+3295-3297, U+3299, U+329d, U+329f, U+32a3-32a4, U+32d6, U+32e1, U+3314, U+3322, U+337f, U+338e-338f, U+339c-339e, U+33a1, U+33c4, U+33d1-33d2, U+3440, U+3449, U+3479, U+3551, U+3569, U+35ad, U+35ce, U+36ac, U+373a, U+3863, U+38ec, U+39b8, U+3a02;
}
/* [86] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.86.woff2) format("woff2");
  unicode-range: U+2749-274f, U+2752-275b, U+275d-275e, U+2761, U+2763, U+2765-2769, U+276e-276f, U+2771, U+2776-277e, U+2780-2782, U+278a-278c, U+2794-2796, U+2798-2799, U+279c-27a6, U+27a8-27ab, U+27ad, U+27af-27b0, U+27b2-27b3, U+27b7-27b9, U+27bc-27bd, U+27bf, U+27e9-27eb, U+27f5-27f6, U+2800, U+28ec, U+2922, U+2934-2935, U+29bf, U+2a2f, U+2b05-2b07, U+2b1b, U+2b50, U+2b55, U+2cf5, U+2e1c-2e1d, U+2f00, U+2f08, U+2f12, U+2f24, U+2f29, U+2f2f, U+2f3c, U+2f3f, U+2f42, U+2f45, U+2f63-2f64, U+2f83, U+2f8f, U+3003-3007, U+3012-3013, U+3016-3019, U+3020-3025, U+3030, U+303d, U+3041, U+3043, U+3045, U+3047, U+3049, U+3052;
}
/* [87] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.87.woff2) format("woff2");
  unicode-range: U+264a-2656, U+2658-265c, U+265e-2660, U+2662-2664, U+2666-2669, U+266b-266f, U+267b, U+2692-2696, U+2698, U+269b-269c, U+26a0-26a1, U+26a3-26a5, U+26aa-26ac, U+26bd-26be, U+26c4-26c5, U+26c8, U+26d1, U+26d3-26d4, U+26e4, U+26e9-26ea, U+26f0-26f5, U+26f9-26fa, U+26fd, U+2701-2702, U+2704-2706, U+2708, U+270a-2712, U+2714, U+2716-2727, U+2729-273e, U+2740-2748;
}
/* [88] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.88.woff2) format("woff2");
  unicode-range: U+2567-2570, U+2572, U+2574, U+2579, U+2580-258a, U+258c-2595, U+2597, U+25a1, U+25a3-25a4, U+25a6-25ac, U+25b0, U+25b4, U+25b7-25b9, U+25bb, U+25bd, U+25bf-25c2, U+25c7-25ca, U+25cc-25cd, U+25d0-25d9, U+25dc-25e6, U+25ea-25eb, U+25ef, U+25fb-25fe, U+2600-2604, U+2607, U+2609-260b, U+260d-2615, U+2618, U+261a-2623, U+262a, U+262d-2630, U+2638-263e, U+2641-2642, U+2648-2649;
}
/* [89] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.89.woff2) format("woff2");
  unicode-range: U+2476-2481, U+2488-2491, U+24b6-24c5, U+24c7-24ca, U+24cc, U+24ce, U+24d0-24df, U+24e1-24ea, U+24f5, U+24ff, U+2501, U+2503-250d, U+250f-2511, U+2513-2515, U+2517-2518, U+251b-251d, U+2520, U+2523-2524, U+2528, U+252b-252c, U+252f, U+2533-2534, U+2537, U+253b-253c, U+2541, U+2543-2545, U+254b, U+2550-2566;
}
/* [90] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.90.woff2) format("woff2");
  unicode-range: U+2139, U+2153, U+2194-2197, U+2199, U+219d-219e, U+21a0, U+21a9-21aa, U+21ac, U+21af-21b1, U+21b3-21b5, U+21ba-21bb, U+21c4, U+21ca, U+21cc, U+21d0, U+21d2-21d4, U+21d8, U+21dd, U+21e2-21e9, U+2200, U+2202, U+2205-2208, U+220e-220f, U+2211-2212, U+2215, U+2217-221a, U+221d-2220, U+2225, U+2227-222b, U+222e, U+2234-2237, U+223c-223d, U+2248, U+2256, U+2260-2261, U+2264-2265, U+226a-226b, U+226e-226f, U+2282-2283, U+2295-2296, U+2299, U+22a5, U+22b0-22b1, U+22b9, U+22bf, U+22c5-22c6, U+22c8, U+22d0-22d1, U+22ee, U+2312-2313, U+2318, U+231a-231b, U+2323, U+2328, U+239d, U+23a0, U+23af, U+23e4, U+23e9-23ea, U+23ec, U+23f0-23f3, U+23fa, U+2445, U+2461-2471, U+2474-2475;
}
/* [91] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.91.woff2) format("woff2");
  unicode-range: U+1ec0-1ec3, U+1ec5-1ec6, U+1ec9, U+1ecb-1ecd, U+1ecf-1ed1, U+1ed3-1ed5, U+1ed7-1edd, U+1edf, U+1ee1, U+1ee3-1ee7, U+1ee9, U+1eeb, U+1eed, U+1eef-1ef1, U+1ef3, U+1ef7, U+1ef9, U+1f62, U+1fa2, U+2001-2006, U+2009-200a, U+200c-200d, U+200f-2012, U+2015-2016, U+201a, U+201e, U+2020-2021, U+2023-2025, U+2028, U+202a-202d, U+202f-2030, U+2032-2033, U+2035, U+2038, U+203e-203f, U+2042-2044, U+2049, U+204d-204e, U+2060-2061, U+2063, U+2070, U+2074-207b, U+207d-2083, U+208a, U+208d-208e, U+20a1, U+20a4, U+20a6, U+20a8-20ab, U+20ad-20ae, U+20b1-20b3, U+20b5, U+20b8-20ba, U+20bd, U+20dd, U+20e3, U+2105, U+2109, U+2112-2113, U+2115-2117, U+2120-2121, U+2126, U+212b;
}
/* [92] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.92.woff2) format("woff2");
  unicode-range: U+11af, U+11b7, U+11bc, U+121b, U+122d, U+129b, U+12a0, U+13a6, U+13a9, U+13ac, U+13af, U+13b3, U+13c2, U+13e3, U+141b, U+1555, U+1557, U+15dc, U+15e8, U+1780-1782, U+1784-1785, U+1787, U+178a, U+178e-1791, U+1793-179c, U+179f, U+17a2, U+17b6-17b9, U+17bb-17bc, U+17bf-17c7, U+17c9, U+17cb, U+17d0, U+17d2, U+17db, U+1871, U+18fa, U+1bff, U+1d00, U+1d04-1d05, U+1d07, U+1d0a-1d0b, U+1d0d, U+1d0f, U+1d17-1d18, U+1d1b-1d1c, U+1d20-1d22, U+1d25, U+1d2c, U+1d2e, U+1d30-1d31, U+1d33-1d3a, U+1d3c, U+1d3e-1d42, U+1d52, U+1d55, U+1d5b, U+1d5e, U+1d9c, U+1da0, U+1dc4-1dc5, U+1e3b, U+1e43, U+1e45, U+1e47, U+1e63, U+1e6d, U+1e73, U+1ea0, U+1ea2, U+1ea4-1ea9, U+1eab-1eaf, U+1eb1, U+1eb3, U+1eb5, U+1eb7, U+1eb9, U+1ebb, U+1ebd-1ebe;
}
/* [97] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.97.woff2) format("woff2");
  unicode-range: U+2c8-2c9, U+2cc-2cd, U+2d0, U+2d8, U+2da, U+2dc, U+2e1-2e3, U+2e7, U+2eb, U+2ee, U+2f1-2ff, U+302-304, U+306-309, U+30c-30d, U+311, U+31b, U+321, U+323-325, U+328-329, U+32b-32c, U+32e-32f, U+331-33a, U+33c-33f, U+348, U+353, U+358-359, U+35c, U+35e-35f, U+361, U+363, U+367-368, U+36c, U+36f, U+530-535, U+537-540, U+55e, U+561-565, U+568-56d, U+56f-576, U+578-582, U+584, U+5a1, U+5a3-5a4, U+5aa, U+5ae, U+5b0-5b4;
}
/* [98] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.98.woff2) format("woff2");
  unicode-range: U+1af, U+1b1, U+1b4-1be, U+1d0, U+1d2, U+1d4, U+1da, U+1dc-1dd, U+1e1, U+1e3-1e4, U+1e7, U+1e9, U+1eb-1ec, U+1f0-1f1, U+1f3-1f5, U+1f7, U+1f9-1ff, U+219, U+221, U+225-226, U+228-22b, U+22e-22f, U+231-235, U+239, U+23b, U+23e, U+250-252, U+254-255, U+259-25e, U+261-263, U+268-26b, U+26d, U+26f-277, U+279-27a, U+27d-281, U+283, U+28a-28c, U+28f, U+292, U+294-296, U+298-29a, U+29c, U+29f, U+2a1-2a2, U+2a4-2a7, U+2a9-2aa, U+2ae-2b3, U+2b5-2b7, U+2b9-2bf, U+2c2-2c4, U+2c6-2c7;
}
/* [99] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.99.woff2) format("woff2");
  unicode-range: U+a1-a2, U+a4, U+a6-a8, U+aa, U+ac, U+af, U+b1, U+b5-b6, U+b8-ba, U+bc-be, U+c0-c8, U+ca-cc, U+ce-d5, U+d8-df, U+f0, U+f5, U+f8, U+fb, U+fe-100, U+102, U+105, U+107, U+109-10b, U+10f, U+112, U+115, U+117, U+119, U+11b, U+11f, U+121, U+123-124, U+127, U+129, U+12c-12d, U+130-13f, U+141-142, U+144, U+148, U+14b-14c, U+14f-153, U+159-15b, U+15e-160, U+163-166, U+169-16a, U+16d-171, U+173-17e, U+192, U+1a0, U+1a4, U+1aa, U+1ac-1ad;
}
/* [100] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.100.woff2) format("woff2");
  unicode-range: U+a3, U+2ca, U+2223, U+2460, U+2640, U+273f, U+301c, U+3107, U+310c, U+4e30, U+4e3e, U+4e5e, U+4e71, U+4f26, U+4f7c, U+4f83, U+50da, U+5243, U+5267, U+529e, U+5321, U+5352, U+5477, U+548b, U+54a6, U+54b2, U+54c2, U+54c4, U+54c6, U+54cd, U+54ee, U+5543, U+55d1, U+55d3, U+55f0, U+55fd, U+560d, U+5629, U+5660, U+56d1, U+56dd, U+57ae, U+57e0, U+57e4, U+5904, U+592d, U+5965, U+5a31, U+5a7f, U+5b5a, U+5bb8, U+5c14, U+5c3b, U+5c5c, U+5c5e, U+5d10, U+5e10, U+5e4c, U+603b, U+604d, U+611c, U+6137, U+61c8, U+62c7, U+6371, U+6382, U+645f, U+64ae, U+64c2, U+651e, U+65f1, U+660a, U+663e, U+673d, U+6784, U+6789, U+67b1, U+67ff, U+6813, U+6854, U+68d8, U+68fa, U+697d, U+6a01, U+6a1e, U+6baf, U+6c08, U+6c17, U+6c2b, U+6c81, U+6cbd, U+6dc6, U+6df9, U+6ed9, U+6ee1, U+6f86, U+6fc1, U+6fdb, U+701f, U+7076, U+715c, U+7194, U+71ee, U+71fb, U+720d, U+72b6, U+7396, U+73af, U+745b, U+746f, U+748b, U+7647, U+7699, U+76ce, U+76de, U+77aa, U+786b, U+7881, U+78ca, U+793c, U+797a, U+79b9, U+79bb, U+79bf, U+7a92, U+7ac7, U+7ae3, U+7b19, U+7b20, U+7b51, U+7b94, U+7cbd, U+7cde, U+7cef, U+7d46, U+7dde, U+7f88, U+80da, U+814b, U+81cd, U+8235, U+8282, U+82b9, U+846b, U+84c1, U+84d3, U+8518, U+8611, U+8783, U+8814, U+8a15, U+8aa6, U+8b2c, U+8ba8-8ba9, U+8bc6, U+8be2, U+8be5-8be6, U+8c22, U+8d05, U+8d27, U+8dbe, U+8e34, U+8e66, U+8ec0, U+9005, U+9082, U+914b, U+916f, U+92c5, U+92f0, U+9318, U+938a, U+93e2, U+964b, U+96c1, U+96cc-96cd, U+96db, U+973e, U+97a0, U+9803, U+9876, U+9879, U+9955, U+99f1, U+9a5b, U+9ab7, U+9abc, U+9c57, U+9c9c, U+9d1b, U+9d26, U+9d51, U+9eef, U+9f99, U+c2a4, U+e253, U+e313-e314, U+e5c7, U+e5c9, U+e8db-e8dc, U+ff2d-ff2e, U+ff34, U+ffe5, U+1f60a, U+1f618, U+1f62d;
}
/* [101] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.101.woff2) format("woff2");
  unicode-range: U+b4, U+10d, U+2d9, U+641, U+6cc, U+e20, U+e29, U+200e, U+20ac, U+2266, U+25be, U+301d-301e, U+3058, U+4e07, U+4e1d, U+4e66, U+4ece, U+4fde, U+5016, U+5180, U+5199, U+51aa, U+5306, U+5386, U+53d8, U+5413, U+541d, U+54ce, U+54e8, U+54fc, U+5571, U+557e, U+558e, U+55a7, U+56a8, U+57a2-57a3, U+58b3, U+5960, U+5992-5993, U+59a4, U+5a55, U+5ab2, U+5afb, U+5b56, U+5bc5, U+5bc7, U+5bf0, U+5cb1, U+5cc7, U+5dff, U+5e93, U+5ed3, U+5f6a, U+60bc, U+61ff, U+6218, U+6292, U+634d, U+6467, U+64f1-64f2, U+6582, U+65fb, U+6615, U+6687, U+66e6, U+66f0, U+6781, U+67f5, U+68a7, U+6a1f, U+6b27, U+6b4e, U+6b73, U+6b79, U+6bcb, U+6c5d, U+6cf5, U+6dee, U+6ec4, U+6ecc, U+6f88, U+701d, U+703e, U+707c, U+7099, U+710a, U+72d9, U+72e9, U+731d, U+739f, U+7463, U+7480, U+74a8, U+7523, U+7526, U+75e0, U+7613, U+7656, U+76bf, U+76d4, U+773a, U+775c, U+775e, U+780c, U+78e1, U+78f7, U+7960, U+7a20, U+7aaf, U+7b08, U+7b71, U+7be4, U+7cec, U+7cf0, U+7d5e, U+7d62, U+7dbe, U+7e1b, U+7ea2, U+7ec4, U+7ec6, U+7edc, U+7eed, U+7efc, U+7f16, U+7f57, U+7fb9, U+7fca, U+803d, U+816e, U+8258, U+82a5, U+82b7, U+8317, U+8338, U+834a, U+83d3, U+8401, U+8469, U+849e, U+854a, U+8559, U+865e, U+8700, U+8759, U+8760, U+8778, U+8782, U+879e, U+87d1, U+8836, U+8944, U+89c8, U+8aac, U+8b74, U+8ba2, U+8ba4, U+8bae, U+8bfb, U+8c4e, U+8cb3, U+8d16, U+8d28, U+8e44, U+8f3b, U+8f3f, U+8f91, U+8fb9, U+8fc4, U+8fde, U+8ff9, U+9076, U+9091, U+90ae, U+90b8, U+9257, U+9310, U+9382, U+93df, U+94fe, U+95a5, U+962e, U+968f-9690, U+9704, U+9713, U+97f6, U+9824, U+986b, U+9884, U+9886, U+98e2, U+991a, U+9986, U+99a5, U+99dd, U+9ab8, U+9b41, U+9b77, U+9bad, U+c774, U+e5d4, U+fe52, U+ff02, U+ff25, U+1f389, U+1f449, U+1f495;
}
/* [102] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.102.woff2) format("woff2");
  unicode-range: U+2cb, U+5d1, U+5d9, U+5e2, U+5e8, U+5ea, U+633, U+e32, U+2252, U+2267, U+2573, U+25b3, U+25c4, U+2713, U+2715, U+30e2, U+4e28, U+4e3c, U+4e4d, U+4e70, U+4f88, U+4fef, U+5018, U+501a, U+5026, U+5137, U+513f, U+51f3, U+524b, U+5254, U+52d8, U+5308, U+5384, U+53cc, U+5436, U+5443, U+5466, U+54a8, U+54bd, U+54c9, U+54cb, U+555e, U+5580, U+560e, U+561f, U+562f, U+566c, U+5679, U+56bc, U+56cd, U+56e7, U+56ed, U+572d, U+57d7, U+582f, U+589f, U+5b09, U+5ba5, U+5c51, U+5c90, U+5cef, U+5d16, U+5d84, U+5dd4, U+5e08, U+5e26, U+5f0a, U+5f20, U+606c, U+61c7, U+620f, U+6254, U+625b, U+62a4, U+62d0, U+62f1, U+63a0, U+63c6, U+63f9, U+6413, U+6417, U+6483, U+64f7, U+650f, U+65a7, U+665f, U+66ae, U+66d6, U+66e0, U+6746, U+6756, U+67d1, U+6837, U+68e0, U+68f5, U+6995, U+69a8, U+69b4, U+69d3, U+6a3d, U+6abb, U+6bb7, U+6bd3, U+6c47, U+6cc4, U+6cd3, U+6dae, U+6e26, U+6e29, U+6eaf, U+6eba, U+6fef, U+7028, U+70b3, U+711a, U+725f, U+7325, U+733f, U+73c0, U+73ee, U+7444, U+745a, U+7487, U+7540, U+75a4, U+7729, U+779e, U+798e, U+79e9, U+7a3d, U+7a4c, U+7a9f, U+7ac4, U+7aff, U+7c27, U+7cd9, U+7d76, U+7e43, U+7ea6, U+7ed9, U+7ff1, U+808b, U+809b, U+80fa, U+827a, U+8309, U+8328, U+832b, U+8396, U+83e0, U+840e, U+8425, U+852d, U+853b, U+8588, U+85e9, U+86b5, U+86e4, U+8718, U+87ec, U+880d, U+8910, U+893b, U+89c1-89c2, U+8b3e, U+8baf, U+8bc1, U+8bdd, U+8c41, U+8c48, U+8cb6, U+8d2d, U+8d5e, U+8fbe, U+9015, U+90a8, U+90b5, U+90e1, U+9169, U+9183, U+91d0, U+91dc, U+9293, U+92f8, U+9472, U+9598, U+95a9, U+95ed, U+95fb, U+9605, U+96c7, U+9739, U+9742, U+9761, U+99ad, U+9ae6, U+9b1a, U+9b44, U+9bc9, U+9d3f, U+9dd7, U+9e7c, U+9e92, U+fe5d-fe5e, U+ff22-ff24, U+ff2f-ff30, U+ff33;
}
/* [103] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.103.woff2) format("woff2");
  unicode-range: U+60, U+f7, U+2198, U+2571, U+258b, U+25b6, U+2661, U+3051, U+3109, U+311a, U+4e11, U+4e24, U+4e2b, U+4ef7, U+4f18, U+4f36, U+4fd0, U+5029-502a, U+5055, U+508d, U+50ad, U+50e7, U+50f1, U+50f5, U+51a5, U+51c8, U+51fb, U+5203, U+524e, U+5288, U+5323, U+53c2, U+5458, U+54a7, U+54b1, U+54b3, U+54b8, U+556a, U+5582, U+55b2, U+55ba, U+55da, U+55dc, U+5614, U+5662, U+5678, U+56c2, U+5742, U+57d5, U+5862, U+58e4, U+58f0, U+5907, U+590d, U+5934, U+5978, U+5984, U+5a25, U+5c06, U+5c62, U+5c91, U+5d01, U+5d1b, U+5e87, U+5eff, U+5f27, U+5f3a, U+5f53, U+6001, U+6168, U+61a9, U+6233, U+62a5, U+62ce, U+62ed, U+638f, U+6399, U+646f, U+6590, U+6631, U+664f, U+6689, U+66dc, U+672f, U+67af, U+67ec, U+68d7, U+6977, U+6a44, U+6c14, U+6c40, U+6c70, U+6c76, U+6cb8, U+6ce3, U+6df3, U+6e20, U+6e43, U+6e5b, U+6ebc, U+6eec, U+6f2c, U+6fb1, U+7009, U+7011, U+701a, U+7117, U+7184, U+72f9, U+7426, U+74bd, U+74cf, U+752b, U+7554, U+75b9, U+7621, U+7671-7672, U+7693, U+76ef, U+7737, U+77a7, U+77b3, U+77bb, U+77da, U+77e2, U+77e9, U+77ef, U+7801, U+7940, U+797f, U+79a7, U+79b1, U+79bd, U+79cd, U+7a6b, U+7ac5, U+7b1b, U+7b77, U+7ca7, U+7dab, U+7db4, U+7db8, U+7dcb, U+7ddd, U+7de0, U+7e55, U+7e9c, U+7ed3, U+7ef4, U+803f, U+8046, U+8087, U+8116, U+81a8, U+8214, U+821c, U+82d4, U+831c, U+8339, U+8350, U+8354, U+8526, U+860a, U+86db, U+8713, U+873b, U+8822, U+8993, U+8a1f, U+8ab9, U+8ad7, U+8bcd, U+8e72, U+8f4e, U+8f9c, U+8fd0, U+8fd8, U+8fe6, U+9042, U+907c, U+91ba, U+9452, U+95e2, U+9631, U+9699, U+96b8, U+9709, U+978d, U+9830, U+98ce, U+9945, U+99ed, U+9a8c, U+9ad3, U+9baa, U+9be8, U+9c77, U+9cf6, U+9d72, U+9e1f, U+9ec4, U+fe31, U+fe55, U+ff03, U+ff20, U+ff3b, U+ff3d, U+1f3fb, U+1f44d, U+1f60d;
}
/* [104] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.104.woff2) format("woff2");
  unicode-range: U+10c, U+161, U+e44, U+2728, U+3081, U+4e13, U+4e19, U+4e1c, U+4e1e, U+4e5c, U+4ea7, U+4ed7, U+4f20, U+4f8d, U+4ffe, U+5021, U+50d5, U+515c, U+51a4, U+51e0, U+521b, U+522b, U+532a, U+534e, U+5355, U+537f, U+5398, U+541f, U+543c, U+544e, U+5509, U+5598, U+5622, U+563f, U+5641, U+566a, U+5695, U+569f, U+56ae, U+56da, U+573a, U+574e, U+584c, U+5885, U+58ae, U+5a1f, U+5ac2, U+5b24, U+5bb0, U+5bde, U+5be1, U+5bfc, U+5c39, U+5c4c, U+5c60, U+5cfb, U+5d11, U+5e76, U+5e7f, U+5e9a, U+5eb8, U+5f13, U+5f64, U+5f6c, U+6127, U+61f2, U+6208, U+620a, U+620c, U+6252, U+62ef, U+6328, U+633d, U+6362, U+63b0, U+63c0, U+63c9, U+640f, U+64a9, U+6514, U+652c, U+655e, U+6583, U+658c, U+6627, U+66f3, U+6734, U+6743, U+676d, U+67c4, U+67da, U+6807, U+68cd, U+68f2, U+690e, U+6b16, U+6b38, U+6b3d, U+6bc6, U+6ca1, U+6cab, U+6d8c, U+6e32, U+6e3e, U+6e58, U+6eef, U+6ef2, U+708a, U+7130, U+7165, U+7172, U+71ed, U+7232, U+7239, U+7261, U+7280, U+72a7, U+72f8, U+73c8, U+7464, U+753b, U+754f, U+755c, U+75d8, U+76ea, U+776b, U+7779, U+777f, U+7784, U+778e, U+77ee, U+79e4, U+7a46, U+7a57, U+7aba, U+7aed, U+7b4d, U+7c7b, U+7c7d, U+7d13, U+7d33, U+7dbb, U+7df9, U+7e46, U+7ea7, U+8085, U+8165, U+81fb, U+82b8, U+82d3, U+8305, U+8335, U+8343, U+83e9, U+840d, U+851a, U+853d, U+8543, U+859b, U+85fb, U+87fb, U+888d, U+88c5, U+8adc, U+8b0a, U+8bb0, U+8bbe, U+8bc4, U+8bf4, U+8c5a, U+8cc3, U+8ce4, U+8d44, U+8e81, U+8f44, U+8f66, U+8fdb, U+900d, U+9063, U+914c, U+9223, U+9226, U+923a, U+925b, U+9264, U+929c, U+92b9, U+9320, U+934d, U+9444, U+957f, U+9591, U+96a7, U+97ad, U+97cc, U+9811, U+9898, U+98ea, U+9921, U+9952, U+9a55, U+9b0d, U+9bca, U+9ebd, U+e60f, U+ff1c-ff1d, U+ff21, U+ff38, U+ff9f, U+fffd, U+1f602;
}
/* [105] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.105.woff2) format("woff2");
  unicode-range: U+e17, U+e22, U+2103, U+25a0, U+266a, U+3014-3015, U+311f, U+4e1a, U+4e50, U+4f10, U+4f6c, U+4f70, U+5006, U+50d1, U+5170, U+518c, U+51f0, U+51f6, U+51f9, U+5219, U+5256, U+525d, U+52c9, U+5349, U+5351, U+5356, U+5375, U+539d, U+53db, U+53ee, U+53f7, U+5492, U+54fa, U+5538, U+55bb, U+55e8, U+5632, U+5757, U+5835, U+58be, U+5937, U+59dc, U+59e8, U+5a49, U+5a9a-5a9b, U+5ab3, U+5b9b, U+5b9e, U+5be8, U+5c37, U+5c4e, U+5d14, U+5d19, U+5d4c, U+5d50, U+5deb, U+5e84, U+5e94, U+5ec2, U+5f17, U+5f26, U+5f55, U+5f77, U+5f7f, U+5fbd, U+6052, U+6064-6065, U+608d, U+609a, U+6101, U+614c, U+621a, U+6237, U+6296, U+62e9, U+632a-632b, U+6488, U+6500, U+652a, U+6556, U+65e0, U+65ec, U+6643, U+6850, U+6893, U+6897, U+68b3, U+68d5, U+6930, U+6960, U+6a11, U+6a3a, U+6ab3, U+6b22, U+6c59, U+6c83, U+6ccc, U+6dea, U+6df5, U+6ef7, U+6f3e, U+6f80, U+6fe4, U+7164, U+71c9, U+722a, U+7260, U+7272, U+73b0, U+74e3, U+7538, U+7586, U+75b5, U+7661-7662, U+77db, U+7838, U+786e, U+788c, U+7950, U+79a6, U+79aa, U+7a40, U+7bf7, U+7c3e, U+7c98, U+7ca5, U+7d21, U+7d2e, U+7dba, U+7dec, U+7e79, U+7ecf, U+7edf, U+7f79, U+8086, U+810a, U+8139, U+813e, U+817a, U+81b3, U+821f, U+8247, U+8259, U+8271, U+839e, U+8431, U+846c, U+849c, U+84b2, U+84c4, U+8513-8514, U+8755, U+8877, U+8881, U+88f9, U+8a1d, U+8a3c, U+8a6d-8a6e, U+8a93, U+8ae7, U+8af7, U+8b17, U+8b5a, U+8ba1, U+8bba, U+8cdc, U+8dea, U+8f6c, U+8f7d, U+8fc7, U+8fd9, U+902e, U+90ca, U+916a, U+916c, U+921e, U+9245, U+935b, U+9594, U+95a8, U+95ee, U+95f4, U+9706, U+971e, U+9756, U+980c, U+9891, U+98b1, U+98fc, U+9903, U+9957, U+99ff, U+9b91, U+9db4, U+9f4b, U+e602-e605, U+e610-e611, U+ff16-ff19;
}
/* [106] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.106.woff2) format("woff2");
  unicode-range: U+2190-2191, U+2193, U+22c1, U+2302, U+25cb, U+2699, U+2709, U+3127, U+4e0e, U+4e18, U+4e3a, U+4e48, U+4e91, U+4eec, U+4f3d, U+4fcf, U+5112, U+524a, U+52a3, U+52ab, U+52c3, U+52f3, U+52fb, U+5320, U+5339, U+533f, U+53e2, U+543e, U+5480, U+5495, U+5497, U+5564, U+5572, U+55c6, U+55ef, U+563b, U+5653, U+5657, U+56b7, U+5824, U+58d8, U+5955, U+5983, U+598d, U+59da, U+59e6, U+5a36, U+5bb5, U+5bc2, U+5bf9, U+5cb3, U+5d17, U+5dbc, U+5e2e, U+60df, U+611a, U+6190, U+61a4, U+61be, U+61fc, U+6284, U+62ac, U+62bc, U+634f, U+636e, U+6398, U+63a9, U+6487, U+6495, U+64ab, U+6577, U+65ac, U+6602, U+6652, U+66f9, U+6761, U+679a, U+683d, U+68ad, U+68b5, U+68da, U+68e7, U+6a38, U+6a59, U+6ae5, U+6b47, U+6b67, U+6b6a, U+6bef, U+6c50, U+6c9b, U+6e23, U+6e34, U+6e4a, U+6e67, U+6ea2, U+6eb6, U+6f20, U+6feb, U+70ed, U+714c, U+715e, U+7199, U+71ac, U+7231, U+7262, U+7409, U+745f, U+7469, U+74ca, U+7535, U+753a, U+75f4, U+7624, U+7682, U+76ba, U+76f2, U+77fd, U+780d, U+7832, U+78c5, U+78ef, U+7901, U+79be, U+79c9, U+79e6, U+7a1a, U+7a62, U+7a84, U+7aca, U+7cb5, U+7cb9, U+7cdf, U+7ce7, U+7d6e, U+7db1, U+7def, U+7e61, U+7e7d, U+7e8f, U+7f38, U+7f77, U+7fa8, U+7fc5, U+7fe1, U+7ff9, U+800d, U+8015, U+8054, U+80a2, U+80aa, U+80ba, U+814e, U+8180, U+819d, U+81c0, U+828b, U+82ad, U+82af, U+83f1, U+83f8, U+8403, U+84bc, U+84c9, U+84ec, U+8523, U+8549, U+8569, U+8591, U+85b0, U+881f, U+884d, U+88d4, U+89c4, U+89c6, U+8a60, U+8a79, U+8b19, U+8bd5, U+8bf7, U+8c03, U+8c79, U+8cc8, U+8d9f, U+8e10, U+8e48, U+8faf, U+9009, U+9017, U+9175, U+9187, U+918b, U+9214, U+946b, U+9470, U+947c, U+9640, U+9675, U+96ef, U+97cb, U+97e9, U+985b, U+99ae, U+99b3, U+9b4f, U+9d09, U+9e9f, U+9edb, U+9f90, U+ff14, U+1f464;
}
/* [107] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.107.woff2) format("woff2");
  unicode-range: U+a5, U+25ce, U+4e56, U+4e9a, U+4ea8, U+4ead, U+4f3a, U+4f51, U+4f62, U+4faf, U+5098, U+50ac, U+5147, U+5173, U+5187, U+51f8, U+52a1, U+52a8, U+52f8, U+535c, U+53ed, U+541e, U+5435, U+5475, U+54a9, U+54c0, U+54c7, U+5589, U+5605, U+5733, U+5764, U+5782, U+57c3, U+5858, U+5893, U+589c, U+58e2, U+5974, U+599e, U+59a8, U+59ec, U+5b66, U+5b99, U+5b9d, U+5bee, U+5c2c, U+5c65, U+5cfd, U+5d0e, U+5dba, U+5de2, U+5e06, U+5e15, U+5ec1, U+5ed6, U+5f00, U+5f4c, U+5f65, U+6055, U+6070, U+609f, U+60b6, U+6241, U+624e, U+626f, U+6291, U+62cc, U+62d8, U+62da, U+62fe, U+6349, U+6367, U+63ea, U+6435, U+6454, U+64a4, U+64b2, U+64bc, U+64bf, U+64c5, U+64ce, U+6558, U+6572, U+65a5, U+65e8, U+65ed, U+6614, U+6670, U+6688, U+672d, U+673a, U+6770, U+68cb, U+6912, U+6953, U+6a61, U+6aac, U+6aaf, U+6ab8, U+6b20, U+6b96, U+6bbf, U+6bc5, U+6c6a, U+6cbe, U+6d59, U+6d78, U+6dc7, U+6deb, U+6e7e, U+6e9c, U+6f3f, U+6f51, U+6f84, U+704c, U+7051, U+70ab, U+70ad, U+70f9, U+7119, U+7149, U+714e, U+71e6, U+72c4, U+72d0, U+72e0, U+7334, U+744b, U+7455, U+7504, U+75ab, U+75b2, U+766e, U+76c3, U+76fc, U+76fe, U+7891, U+7948, U+7a74, U+7b28, U+7c72, U+7cca, U+7ebf, U+7f55, U+7ff0, U+8154, U+81c2, U+81d8, U+81e3, U+81e5, U+8292, U+8299, U+8302, U+8304, U+8332, U+83c1, U+83c7, U+83ca, U+845b, U+8475, U+8490, U+85af, U+8650, U+8667, U+86d9, U+8774, U+8abc, U+8b0e, U+8b39, U+8bed, U+8c54, U+8c6b, U+8c9e, U+8ca7, U+8caa-8cab, U+8ce6, U+8cec-8ced, U+8eb2, U+8eba, U+8fb0, U+901d, U+908f, U+9127, U+91c0, U+91d8, U+9215, U+92b3, U+932b, U+93fd, U+95ca, U+964c, U+96c0, U+970d, U+9716, U+9774, U+97fb, U+9812, U+9913, U+9935, U+99c1, U+9b31, U+9d5d, U+9d6c, U+9e79, U+fe0f, U+fe30, U+ff05, U+ff0b, U+ff10, U+ff15;
}
/* [108] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.108.woff2) format("woff2");
  unicode-range: U+b0, U+926, U+928, U+939, U+93f-940, U+94d, U+200b, U+22ef, U+25ba, U+25c6, U+2665, U+4e08, U+4e2a, U+4e4f, U+4e59, U+4ec7, U+4f0d, U+4f0f, U+4f19, U+4f59, U+4fae, U+5075, U+507d, U+50b2, U+50b5, U+511f, U+5141, U+5146, U+514c, U+5185, U+51dd, U+51fd, U+522e, U+5319, U+533a, U+5378, U+53ad, U+53c9, U+53d1, U+53d4, U+543b, U+5442, U+5446, U+5481, U+54e9, U+5507, U+5565, U+559a, U+55aa, U+5690, U+56ca, U+56fe, U+582a, U+5915, U+5949, U+5962, U+5996, U+59a5, U+59fb, U+5a77, U+5b0c, U+5b5f, U+5bd3, U+5be2, U+5bfa, U+5c41, U+5c48, U+5ca9, U+5d07, U+5ec8, U+5eca, U+5f18, U+5f4e, U+5f59, U+5f6d, U+5f79, U+5fb9, U+6028, U+6068, U+606d, U+6094, U+60f1, U+6108-6109, U+614e, U+6170, U+617e, U+61b2, U+61f8, U+6247, U+626d, U+6276, U+62ab, U+62cb, U+62d3, U+62f3, U+6368, U+6380, U+6492, U+64e0, U+6570, U+6606, U+660f, U+6649, U+6691, U+66a8, U+6749, U+674f, U+6795, U+67f1, U+67f3, U+6842, U+6851, U+687f, U+68df, U+69fd, U+6a58, U+6c27, U+6c88, U+6cca, U+6d29, U+6d66, U+6daf, U+6f01, U+6f58, U+6f6d, U+6f70, U+6fa1, U+6ff1, U+6ffe, U+7058, U+70ae, U+71d9, U+71e5, U+7235, U+7267, U+73ca, U+742a, U+74f7, U+7529, U+758f, U+75bc, U+76c6, U+7955, U+7a00, U+7a3b, U+7b4b, U+7bad, U+7be9, U+7c4c, U+7c60, U+7cfe, U+7dbf, U+7e2b, U+7f9e, U+7fc1, U+7ffc, U+8096, U+809d, U+80de, U+8155, U+816b, U+81df, U+82bd, U+8352, U+8393, U+8404, U+8525, U+856d, U+8606, U+868a, U+87f9, U+886b, U+8870, U+896a, U+896f, U+8a23, U+8a87, U+8ad2, U+8b00, U+8b20, U+8cb8, U+8cca, U+8ce0, U+8d39, U+8d6b, U+8db4, U+8e29, U+8ef8, U+8f1b, U+8f5f, U+8fa8, U+906e, U+9077, U+90aa, U+90b1, U+90c1, U+9165, U+919c, U+92c1, U+95d6, U+95e8, U+975a, U+9817, U+98c6, U+9ecf, U+feff, U+ff06, U+ff0a, U+ff12-ff13;
}
/* [109] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.109.woff2) format("woff2");
  unicode-range: U+627-629, U+631, U+639, U+644, U+64a, U+25cf, U+2606, U+2764, U+3008-3009, U+4e38, U+4e43, U+4ed5, U+4ef0, U+4eff, U+4fe0, U+5085, U+50a2, U+50be, U+5118, U+5211-5212, U+5272, U+52fe, U+5366, U+53b2, U+53ec, U+54ac, U+5587, U+55b5, U+5606, U+561b, U+5751, U+576a, U+57cb, U+58ef, U+58fa, U+594f, U+5951, U+596e, U+59d1, U+5ac1, U+5acc, U+5b8b, U+5c4d, U+5ca1, U+5d29, U+5dfe, U+5e7d, U+5edf, U+5ef7, U+5fa1, U+5faa, U+5fcc, U+5ffd, U+6021, U+6046, U+6062, U+62b9, U+6316, U+6350, U+6478, U+647a, U+6490, U+64b0, U+64e6, U+6524, U+6591, U+659c, U+65a4, U+65e6, U+65f6, U+6607, U+6674, U+6765, U+679d, U+67f4, U+68a8, U+6b3a, U+6c57, U+6c61, U+6c90, U+6cbf, U+6cdb, U+6d69, U+6db5, U+6dd1, U+6e21, U+6f06, U+6f62, U+70d8, U+71c3, U+71d5, U+722c, U+727d, U+72ac, U+72fc, U+731c, U+7336, U+7344, U+7384, U+73ab, U+7433, U+745c, U+7470, U+75d5, U+7652, U+76c8, U+76e7, U+7709, U+7720, U+7740, U+7747, U+7763, U+77ac-77ad, U+7802, U+78a7, U+78b3, U+78c1, U+78da, U+7926, U+796d, U+798d, U+7aae, U+7b52, U+7c92, U+7d68, U+7d81, U+7e31, U+7e5e, U+7e69, U+7e73, U+7f50, U+7f70, U+7f75, U+8058, U+8070, U+80c3, U+8105-8106, U+8108, U+8179, U+818f, U+81a9, U+81ed, U+820c-820d, U+8277, U+82d1, U+8461, U+84b8, U+852c, U+857e, U+8587, U+85e4, U+863f, U+8679, U+86c7, U+8702, U+8776, U+87ba, U+8896, U+88c2, U+88d5, U+88f8, U+8af8, U+8b7d, U+8ca2, U+8d64, U+8d74, U+8d81, U+8d99, U+8e5f, U+8e8d, U+8ecc, U+8ed2, U+8fb1, U+8fc5, U+9022, U+9038, U+905c, U+9081, U+9189, U+9234, U+92d2, U+934a, U+95a3, U+962a, U+9646, U+96d5, U+971c, U+9838, U+9875, U+98c4, U+99db, U+9a45, U+9a5f, U+9a6c, U+9ad2, U+9cf4, U+9d28, U+9daf, U+9df9, U+9e7d, U+9f0e, U+9f52, U+9f9c, U+ff11, U+ff1e;
}
/* [110] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.110.woff2) format("woff2");
  unicode-range: U+2022, U+2500, U+25bc, U+4e1f, U+4e95, U+4f50, U+4f54, U+4f69, U+4fb6, U+4fc4, U+4fca, U+5009, U+50bb, U+5154, U+51cc, U+528d, U+5291, U+52d2, U+52e4, U+5353, U+5360, U+540a-540b, U+5410, U+54f2, U+5514, U+5537, U+558a, U+55ac, U+5617, U+56fd, U+573e, U+5766, U+5783, U+57d4, U+5806, U+5821, U+5857, U+5875, U+58f9, U+592f, U+5954, U+596a, U+59ae, U+59c6, U+59ca, U+59ff, U+5a03, U+5ae9, U+5b64, U+5bb4, U+5c3f, U+5c6f, U+5de1, U+5e16, U+5e45, U+5e72, U+5ec9, U+5f7c, U+5f81, U+5f90-5f92, U+6016, U+6085, U+6089, U+60a0, U+60a3, U+60b2, U+60d1, U+60f9, U+6155, U+6158, U+6191, U+6212, U+626e, U+62d4, U+632f, U+633a, U+63aa, U+642c, U+64a5, U+64cb, U+6566, U+6597, U+66b1, U+66ec, U+6731, U+6735, U+67ef, U+6846, U+6876, U+6881, U+68af-68b0, U+68c9, U+6905, U+6bc0, U+6beb, U+6c0f, U+6c1b, U+6c41, U+6ce5, U+6cf3, U+6d25, U+6d2a, U+6d3d, U+6dcb, U+6dd8, U+6dfa, U+6e9d, U+6eaa, U+6ec5, U+6ecb, U+6f0f, U+6f32, U+707d, U+708e, U+7092, U+716e, U+723a, U+731b, U+7345, U+7375, U+73b2, U+7434, U+74e6, U+758a, U+75be, U+75de, U+764c, U+76dc, U+788e, U+7897, U+789f, U+78a9, U+78b0, U+790e, U+7965, U+7a4e, U+7c43, U+7d17, U+7dd2, U+7e96, U+7f51, U+7f69, U+7f72, U+7fd4, U+7fe0, U+8017, U+80a9, U+80d6, U+8150, U+8178, U+81bd, U+829d, U+82ac, U+8303, U+838e, U+83cc, U+840c, U+8482, U+8499, U+85a9-85aa, U+883b, U+8861, U+88c1, U+88cf, U+88d9, U+8a3a, U+8a98, U+8aee, U+8c8c, U+8cc0, U+8ce2, U+8d0f, U+8da8, U+8dcc, U+8e0f, U+8f1d, U+8f29, U+8fad, U+9003, U+9006, U+903c, U+903e, U+9059, U+9072, U+9075, U+90ce, U+9130, U+91ac, U+91e3, U+9285, U+9298, U+92ea, U+9326, U+937e, U+95c6, U+9676-9677, U+9727, U+994b, U+99a8, U+99d0, U+9a30, U+9b42, U+9b45, U+9d3b, U+9e7f, U+9ee8, U+9f3b;
}
/* [111] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.111.woff2) format("woff2");
  unicode-range: U+5e, U+3042, U+3044, U+3046, U+3048, U+304a-3050, U+3053-3057, U+3059-305b, U+305d-3061, U+3063-306c, U+306e-3079, U+307b, U+307d-307f, U+3082-308d, U+308f, U+3092-3093, U+30a1-30a4, U+30a6-30c1, U+30c3-30c4, U+30c6-30e1, U+30e3-30ed, U+30ef, U+30f3, U+30fb-30fc, U+4e32, U+4ef2, U+4fd7, U+501f, U+5144, U+51c6, U+52c1, U+5440, U+54e6, U+54ed, U+5510, U+5687, U+58c1, U+5b5d, U+5bd2, U+5ee2, U+5f31, U+6012, U+6084, U+6148, U+6182, U+622a, U+6355, U+6372, U+63ee, U+6416, U+6575, U+660c, U+66c9, U+675c, U+6a6b, U+6b32, U+6b49, U+6b98, U+6d6e, U+6dda, U+6ef4, U+6efe, U+7159, U+7378, U+775b, U+78e8, U+7aa9, U+7d1b, U+7de9, U+7f6a, U+7f8a, U+7fbd, U+8000, U+809a, U+8102, U+8170, U+819c, U+8266, U+82b3, U+87f2, U+8972, U+8a17, U+8b02, U+8e22, U+904d, U+90ed, U+91c7, U+93c8, U+9451, U+9a37, U+9b27, U+ad6d, U+c5b4, U+d55c;
}
/* [112] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.112.woff2) format("woff2");
  unicode-range: U+2502, U+2605, U+4e39, U+4e58, U+4ea1, U+4ed9, U+4f2f-4f30, U+4f38, U+4f75, U+4fc3, U+4ff1, U+500d, U+5049, U+5074, U+5077, U+5091, U+5175, U+517c, U+51cd, U+51e1, U+5269-526a, U+52aa, U+52c7, U+52df, U+5377, U+541b, U+5439, U+5448, U+54aa, U+5674, U+56f0, U+5761, U+585e, U+588a, U+58a8, U+58fd, U+5925, U+592e, U+5948, U+5999, U+59b3, U+5a18, U+5a1c, U+5a46, U+5b30, U+5b54, U+5b6b, U+5b8f, U+5be9, U+5bf8, U+5c0a, U+5c16, U+5c24, U+5c46, U+5cf0, U+5e1d, U+5e25, U+5e2d, U+5e3d, U+5e79, U+5f04, U+5fcd, U+5fe0, U+60dc, U+6163, U+616e, U+61f6, U+6258, U+6293, U+62c6, U+62d2, U+62d6, U+62fc, U+63da, U+63ed, U+640d, U+6458, U+649e, U+64ec, U+64f4, U+64fe, U+651c, U+6562, U+65cb, U+65e2, U+65fa, U+6628, U+6668, U+66a2, U+66dd, U+66fc, U+66ff, U+6717, U+67cf, U+67d4, U+6817, U+6885, U+695a, U+69cd, U+6afb, U+6bbc, U+6c89, U+6c96, U+6cc9, U+6d1b, U+6d1e, U+6d74, U+6db2, U+6dbc, U+6df7, U+6dfb, U+6e38, U+6f38, U+6f5b, U+6f64, U+6f8e, U+6fa4, U+6fc3, U+6fd5, U+7070, U+70b8, U+70cf, U+70e4, U+7169, U+7210, U+721b, U+7238, U+737b, U+73bb, U+746a, U+7483, U+74dc, U+74f6, U+7518, U+756a, U+75c7, U+7919, U+7956, U+795d, U+7a05, U+7a0d, U+7a3f, U+7bc9, U+7c97, U+7cd5, U+7d0b, U+7d10, U+7dfb, U+7e3e, U+7e6a, U+8036, U+808c, U+80af, U+80ce, U+80e1, U+80f8, U+817f, U+8216, U+8239, U+827e, U+8377, U+8389, U+83ab, U+83f2, U+840a, U+8584, U+85c9, U+865b, U+8766, U+87a2, U+8932, U+8a50, U+8a69, U+8a95, U+8b6f, U+8c37, U+8c6c, U+8ca9, U+8cfa, U+8d95, U+8de1, U+8f14, U+8f9b, U+8fa3, U+8feb, U+8ff4, U+9010, U+901b, U+905e, U+9080, U+912d, U+9177, U+9336, U+947d, U+963b, U+966a, U+9670, U+9769, U+9813, U+9846, U+98fd, U+99d5, U+9a0e, U+9a19, U+9b6f, U+9ce5, U+9cf3, U+9ece, U+9ed8, U+9f13, U+9f20;
}
/* [113] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.113.woff2) format("woff2");
  unicode-range: U+201c-201d, U+203b, U+2192, U+25b2, U+300e-300f, U+4e01, U+4e73, U+4e82, U+4e88, U+4e8e, U+4f0a, U+4f5b, U+502b, U+504f, U+5076, U+5100, U+5104, U+5132, U+5192, U+51a0, U+51ac, U+51f1, U+5200, U+5224, U+522a, U+5237-5238, U+523a, U+526f, U+5289, U+52de, U+52f5, U+52ff, U+5371, U+539a, U+53e5, U+540e, U+5433, U+547c, U+552f, U+5531, U+5634, U+56c9, U+574a, U+57f7, U+57f9, U+5805, U+5851, U+5854, U+586b, U+58c7, U+58de, U+5967, U+59bb, U+59d3-59d4, U+5b55, U+5b87, U+5b97, U+5bae, U+5bbf, U+5be7, U+5bec, U+5cb8, U+5df7, U+5e7b-5e7c, U+5f1f, U+5f70, U+5fd9, U+60e1, U+61b6, U+61c2, U+6200, U+6234, U+6263, U+62b5, U+62dc, U+62ec, U+6383, U+638c, U+63cf, U+63d2, U+63e1, U+63f4, U+641e, U+64cd, U+64fa, U+654f, U+6557, U+656c, U+65c1, U+65d7, U+6620, U+6676, U+6696-6697, U+66ab, U+66c6, U+671d, U+672b, U+676f, U+677e, U+67d0, U+67d3, U+684c, U+68c4, U+690d, U+694a, U+699c, U+6a4b, U+6ac3, U+6b04, U+6b23, U+6b78, U+6b8a, U+6bd2, U+6c60, U+6cb3, U+6d89, U+6de1, U+6de8, U+6e6f, U+6f02, U+70c8, U+7126, U+718a, U+7236, U+723d, U+7246, U+72af, U+72d7, U+73cd, U+7532, U+760b, U+7626, U+7687, U+76df, U+7761, U+79cb, U+79d2, U+79df, U+7a69, U+7af6, U+7b80, U+7c3d, U+7c3f, U+7c4d, U+7cd6, U+7d2b, U+7de3, U+7e2e, U+7e54, U+7e6b, U+8010, U+80a5, U+812b, U+819a, U+81a0, U+82d7, U+838a, U+8463, U+84cb, U+8521, U+8607, U+860b, U+864e, U+871c, U+878d, U+885d, U+89f8, U+8a13, U+8aa0, U+8afe, U+8b5c, U+8c46, U+8cbf, U+8cd3, U+8cf4, U+8d08, U+8d0a, U+8ddd, U+8de8, U+8fea, U+9014, U+9055, U+906d, U+907a, U+907f, U+90a6, U+9178, U+92fc, U+934b, U+9396, U+93ae, U+9583, U+9663, U+9694, U+96bb, U+9707, U+9738, U+9905, U+9aa8, U+9b25, U+9b3c, U+9ea5, U+9eb5, U+9f4a, U+9f61, U+ff0d-ff0e;
}
/* [114] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.114.woff2) format("woff2");
  unicode-range: U+3c, U+d7, U+2027, U+4e4e, U+4e92, U+4ea6, U+4ec1, U+4ecd, U+4f34, U+4f48, U+4f53, U+4fb5, U+5012, U+50b7, U+51b0, U+5283, U+52dd, U+532f, U+5354, U+53eb, U+53f3, U+5409, U+5496, U+54c8, U+54e5, U+554a, U+5561, U+5594, U+559d, U+56b4, U+56fa, U+5713, U+5750, U+57df, U+584a, U+590f, U+592b, U+593e, U+5976, U+599d, U+59b9, U+59d0, U+5a5a, U+5a66, U+5b85, U+5b88, U+5ba3, U+5bdf, U+5c01, U+5c04, U+5c3a, U+5c3e, U+5c4f, U+5ddd-5dde, U+5de7-5de8, U+5e63, U+5e8a, U+5e9c, U+5eda, U+5ef3, U+5ef6, U+5f48, U+5fb5, U+6015, U+6025, U+602a, U+6050, U+6069, U+6162, U+6176, U+61f7, U+6279, U+627f, U+6297, U+62b1, U+62bd, U+6311, U+6377, U+6388-6389, U+63a1-63a2, U+6436, U+64c1, U+64d4, U+6551, U+6563, U+65bd, U+66b4, U+66f2, U+6751, U+675f, U+6790, U+6838, U+68d2, U+68ee, U+6982, U+69ae, U+69cb, U+6a39, U+6b66, U+6bba, U+6c5f, U+6ce1, U+6d0b, U+6d3e, U+6d6a, U+6e1b, U+6ed1, U+6f22, U+6f54, U+6f6e, U+6fc0, U+6fdf, U+719f, U+71c8, U+71d2, U+7259, U+72c2, U+7389, U+73e0, U+745e, U+751a, U+751c, U+7530, U+7533, U+7562, U+7591, U+75c5, U+75db, U+7642, U+7686, U+76d2, U+76db, U+76e1, U+76e3, U+7701, U+786c, U+7981, U+79c0, U+79fb, U+7a81, U+7a97, U+7adf, U+7aef, U+7b26, U+7c64, U+7d0d, U+7d14, U+7d2f, U+7d9c, U+7dca, U+7df4, U+7e23, U+7f3a, U+8033, U+804a, U+8056, U+805a, U+8173, U+81e8, U+8212, U+821e, U+822a, U+82e6, U+8336, U+83dc, U+8449, U+84ee, U+85e5, U+885b, U+888b, U+8907, U+8a34, U+8a5e, U+8aa4, U+8ab0, U+8ab2, U+8ac7, U+8b66, U+8c6a, U+8c93, U+8c9d, U+8cb4, U+8dd1, U+8f2a, U+8fb2, U+9109, U+9192, U+91cb, U+91dd, U+93e1, U+964d, U+9686, U+968e, U+969c, U+96de, U+96e8, U+96ea, U+96f7, U+975c, U+9760, U+978b, U+9858, U+98ef, U+9918, U+9aee, U+9b54, U+9ebb, U+ff0f, U+ff5c;
}
/* [115] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.115.woff2) format("woff2");
  unicode-range: U+23-25, U+3d, U+4e45, U+4e5d, U+4e7e, U+4eac, U+4eae, U+4ed4, U+4ee4, U+4f01, U+4f3c, U+4f8b, U+4fc2, U+505c, U+50c5, U+5145, U+51b7, U+521d, U+523b, U+525b, U+5287, U+52e2, U+5348, U+535a, U+537b, U+5426, U+542b, U+5438, U+5462, U+54ea, U+555f, U+5566, U+5584, U+5609, U+5708, U+570d, U+571f, U+5747, U+5802, U+58d3, U+5920, U+5922, U+5957, U+5979, U+5a01, U+5a92, U+5abd, U+5b63, U+5b69, U+5b83, U+5bb3, U+5bc4, U+5bf5, U+5c3c, U+5c40, U+5c4b, U+5c64, U+5cf6, U+5de6, U+5e0c, U+5e55, U+5e78, U+5eab, U+5ead, U+5ee0, U+5f85, U+5f8b, U+5fd7-5fd8, U+6167, U+6298, U+62db, U+62ff, U+639b, U+63a7, U+642d, U+6469, U+64ad, U+651d, U+653b, U+65b7, U+65cf, U+665a, U+666e, U+66fe, U+6728, U+674e, U+67b6, U+6821, U+6839, U+6843, U+6a94, U+6b4c, U+6b50, U+6b62, U+6b72, U+6b7b, U+6bcd, U+6bdb, U+6c38, U+6c7a, U+6c7d, U+6c99, U+6cb9, U+6ce2, U+6cf0, U+6d17, U+6d32, U+6e2c, U+6e56, U+6fb3, U+722d, U+723e, U+725b, U+734e, U+7387, U+73ed, U+7565, U+7570, U+76ca, U+76e4, U+773e, U+77ed, U+77f3, U+7814, U+7834, U+7968, U+79d8, U+7a7f, U+7af9, U+7b11, U+7b46, U+7b54, U+7bc4, U+7d19, U+7d22, U+7d42, U+7d55, U+7da0, U+7e41, U+7e7c, U+7f85, U+7ffb, U+8077, U+8089, U+80cc, U+81c9, U+81f4, U+81fa, U+820a, U+822c, U+826f, U+8349, U+85cd, U+86cb, U+8840, U+88dc, U+8986, U+8a0e, U+8a73, U+8a8c, U+8b1b, U+8b9a, U+8c50, U+8ca0, U+8cde, U+8cfd, U+8d8a, U+8df3, U+8e64, U+8ecd, U+8edf, U+8f38, U+8fd4, U+8ff0, U+8ff7, U+9000, U+9047, U+9060, U+90f5, U+9152, U+91ce, U+9280, U+9418, U+9435, U+9589, U+9592, U+9678, U+968a, U+96aa, U+96c5, U+96d6, U+96dc, U+96f6, U+9732, U+9748, U+9802, U+9806, U+9808, U+983b, U+984d, U+984f, U+9867, U+98db, U+98f2, U+98fe, U+9a5a, U+9b06, U+9b5a, U+9bae, U+ff5e;
}
/* [116] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.116.woff2) format("woff2");
  unicode-range: U+40, U+2026, U+4e03, U+4e14, U+4e9e, U+4ec0, U+4ed8, U+4f11, U+4f4e-4f4f, U+4f73, U+4fee, U+5019, U+503c, U+5047, U+514b, U+516b, U+5178, U+5207, U+520a, U+5236, U+5343, U+5347, U+534a, U+5370, U+53cd, U+53e4, U+53e6, U+53f2, U+5403, U+5411, U+5427, U+5468, U+5473, U+547d, U+552e, U+5740, U+5883, U+589e, U+591c, U+5931, U+59cb, U+5a1b, U+5b58, U+5b98, U+5b9c, U+5ba4, U+5bcc, U+5beb, U+5c45, U+5c6c, U+5dee, U+5df4, U+5e03, U+5e33, U+5e6b, U+5e7e, U+5e8f, U+5e95, U+5ea7, U+5f15, U+5f62, U+5f69, U+5f80, U+5fa9, U+5fae, U+5fb7, U+5ff5, U+600e, U+601d, U+60e0, U+614b, U+6230, U+623f, U+628a, U+6295, U+62c9, U+6309, U+64c7, U+64ca, U+652f, U+6545, U+6548, U+65af, U+65e9, U+6625, U+666f, U+667a, U+670b, U+671b, U+6750, U+677f, U+6975, U+6a13, U+6a21, U+6aa2, U+6b65, U+6b77, U+6bb5, U+6cc1, U+6ce8, U+6df1, U+6e90, U+6e96, U+6eab, U+6f14, U+6f2b, U+700f, U+706b, U+7206, U+724c, U+72c0, U+7368, U+7372, U+74b0, U+756b, U+76ae, U+773c, U+78ba, U+798f, U+79ae, U+7a4d, U+7a76, U+7ae5, U+7b56, U+7b97, U+7bb1, U+7bc7, U+7c73, U+7c89, U+7d00, U+7d20, U+7d39, U+7d72, U+7dad, U+7e8c, U+7fa4, U+7fd2, U+8003, U+807d, U+80a1, U+80b2, U+8166, U+8208-8209, U+82e5, U+843d, U+85cf, U+85dd, U+862d, U+8857, U+8863, U+89ba, U+89d2, U+8a2a, U+8a31, U+8a62, U+8a66, U+8a72, U+8abf, U+8b1d, U+8b58, U+8c61, U+8ca1, U+8ca8, U+8cac, U+8cbc, U+8d70, U+8da3, U+8db3, U+8ddf, U+8f03, U+8f15, U+8f2f, U+8fa6, U+8fce, U+8ffd, U+900f, U+9031, U+9069, U+908a, U+91ab, U+91cc, U+92b7, U+9322, U+932f, U+9375, U+9632, U+963f, U+9644, U+9662, U+9673, U+967d, U+96a8, U+96c4, U+96d9, U+96e2-96e3, U+96f2, U+9752, U+97d3, U+97ff, U+9805, U+9810, U+9818, U+986f, U+990a, U+9910, U+9928, U+9e97, U+9ec3, U+9f8d, U+ff1b;
}
/* [117] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.117.woff2) format("woff2");
  unicode-range: U+26, U+3e, U+5f, U+7e, U+3000, U+300a-300b, U+3010-3011, U+4e16, U+4e26, U+4e94, U+4e9b, U+4ea4, U+4eca-4ecb, U+4efb, U+4efd, U+4f46, U+4f55, U+4f9b, U+4f9d, U+4fbf, U+505a, U+5065, U+5099, U+50cf, U+512a, U+5143, U+5148, U+5152, U+5169, U+516d, U+5171, U+5177, U+518a, U+5225, U+5247, U+5275, U+529f, U+52a9, U+5305, U+5341, U+5357, U+5361, U+5373, U+53bb, U+53c3, U+53c8, U+53d6-53d7, U+53e3, U+5404, U+559c, U+55ce, U+5668, U+56db, U+5712, U+5718, U+57ce, U+57fa, U+58eb, U+592a, U+5947, U+5bc6, U+5bf6, U+5c0e, U+5c11, U+5c1a, U+5c55, U+5c71, U+5df1, U+5e2b, U+5e36, U+5e97, U+5eb7, U+5ee3, U+5efa, U+5f35, U+5f37, U+5f88, U+5f9e, U+5fc5, U+606f, U+60a8, U+6232, U+6236, U+624d, U+627e, U+6280, U+62cd, U+6301, U+6307, U+6392, U+63db, U+64da, U+6539, U+653e-653f, U+6559, U+6574, U+65c5, U+6613, U+66f8, U+6797, U+67e5, U+6848, U+6a19, U+6a23, U+6b61, U+6bcf, U+6c11, U+6c42, U+6d41, U+6d77, U+6e2f, U+6eff, U+7167, U+71df, U+738b, U+73a9, U+7403, U+7537, U+754c, U+7559, U+767d-767e, U+78bc, U+793a, U+795e, U+79c1, U+79d1, U+7a2e, U+7a31, U+7a7a, U+7ae0, U+7ba1, U+7bc0, U+7c21, U+7cfb, U+7d04-7d05, U+7d1a, U+7d30, U+7d44, U+7d66, U+7d71, U+7de8, U+7e3d, U+7f6e, U+7fa9, U+8001, U+805e, U+8072, U+81f3, U+82f1, U+83ef, U+842c, U+8457, U+85a6, U+8655, U+8853, U+88ab, U+88dd, U+88e1, U+88fd, U+897f, U+898f, U+89aa, U+89bd, U+89c0, U+89e3, U+8a02, U+8a3b, U+8a55, U+8a8d, U+8a9e, U+8ad6, U+8b49, U+8b70, U+8b77, U+8b80, U+8b8a, U+8b93, U+8cb7, U+8ce3, U+8cea, U+8cfc, U+8f09, U+8fd1, U+9001, U+901f-9020, U+9054, U+90a3, U+914d, U+91cf, U+9304, U+95b1, U+9664, U+969b, U+96b1, U+96c6, U+9700, U+975e, U+97f3, U+98a8, U+98df, U+9999, U+99ac, U+9a57, U+9ebc, U+9ed1;
}
/* [118] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.118.woff2) format("woff2");
  unicode-range: U+d, U+2b, U+7c, U+a9, U+300c-300d, U+4e09, U+4e3b, U+4e4b, U+4e5f, U+4e86, U+4e8b-4e8c, U+4eab, U+4ed6, U+4ee3, U+4ef6, U+4f1a, U+4f4d, U+4f60, U+4f7f, U+4fdd, U+4fe1, U+50b3, U+50f9, U+5149, U+514d, U+5167, U+5176, U+518d, U+5217, U+5229, U+524d, U+529b, U+52a0, U+52d9, U+5316-5317, U+5340, U+539f, U+53ca-53cb, U+53ea, U+53f8, U+5408, U+540c-540d, U+544a, U+548c, U+54c1, U+54e1, U+5546, U+554f, U+55ae, U+56de, U+56e0, U+5716, U+578b, U+5831, U+5834, U+5916, U+5973, U+5982, U+5b57, U+5b78, U+5b89, U+5b8c, U+5b9a, U+5ba2, U+5bb9, U+5be6, U+5c07-5c08, U+5c0b, U+5c0d, U+5c31, U+5de5, U+5df2, U+5e02, U+5e38, U+5ea6, U+5f0f, U+5f71, U+5f8c, U+5f97, U+5feb, U+6027, U+60c5, U+60f3, U+610f, U+611b, U+611f, U+61c9, U+6216, U+624b, U+6253, U+63a5, U+63a8, U+63d0, U+641c, U+6536, U+6578, U+6599, U+661f, U+662d, U+670d, U+671f, U+672a, U+6771, U+679c, U+682a, U+683c, U+689d, U+696d, U+6a02, U+6a5f, U+6b0a, U+6b21, U+6b3e, U+6b64, U+6bd4, U+6c23, U+6c34, U+6c92, U+6cd5, U+6d3b, U+6d88, U+6e05, U+7063, U+7121, U+7136, U+71b1, U+7247-7248, U+7269, U+7279, U+73fe, U+7406, U+7522, U+7531, U+7576, U+767b, U+76ee, U+76f4, U+770b, U+771f, U+77e5, U+793e, U+7a0b, U+7acb, U+7ad9, U+7b2c, U+7b49, U+7cbe, U+7d50, U+7d61, U+7d93, U+7dda, U+8005, U+800c, U+806f, U+8207, U+8272, U+82b1, U+865f, U+8868, U+898b, U+8996, U+8a00, U+8a08, U+8a0a, U+8a18, U+8a2d, U+8a71, U+8aaa, U+8acb, U+8cbb, U+8cc7, U+8d77, U+8d85, U+8def, U+8eab, U+8eca, U+8f49, U+9019-901a, U+9023, U+9032, U+904a-904b, U+904e, U+9053, U+9078, U+9084, U+90e8, U+90fd, U+91cd, U+91d1, U+9577, U+9580, U+9593, U+9650, U+9762, U+982d, U+984c, U+985e, U+9ad4, U+9ad8, U+9ede, U+ff01, U+ff08-ff09, U+ff1f;
}
/* [119] */
@font-face {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDt1T39Cx8GLqqFgF1gfCybqlyVAxgTa5Jtz_zCzHs2CN4HYM28s.119.woff2) format("woff2");
  unicode-range: U+20-22, U+27-2a, U+2c-3b, U+3f, U+41-5d, U+61-7b, U+7d, U+a0, U+ab, U+ae, U+b2-b3, U+b7, U+bb, U+bf, U+c9, U+cd, U+d6, U+e0-ef, U+f1-f4, U+f6, U+f9-fa, U+fc-fd, U+101, U+103, U+110-111, U+113, U+12b, U+14d, U+16b, U+1a1, U+1b0, U+1ce, U+300-301, U+1ea1, U+1ea3, U+1ebf, U+1ec7, U+2013-2014, U+2039-203a, U+203c, U+2122, U+3001-3002, U+4e00, U+4e0a-4e0b, U+4e0d, U+4e2d, U+4eba, U+4ee5, U+4f5c, U+4f86, U+500b, U+5011, U+5165, U+5168, U+516c, U+51fa, U+5206, U+5230, U+52d5, U+53ef-53f0, U+570b, U+5728, U+5730, U+591a, U+5927, U+5929, U+597d, U+5b50, U+5bb6, U+5c0f, U+5e73-5e74, U+5fc3, U+6210-6211, U+6240, U+6587, U+65b0, U+65b9, U+65bc, U+65e5, U+660e, U+662f, U+6642, U+66f4, U+6700, U+6703, U+6708-6709, U+672c, U+6b63, U+6cbb, U+70b9-70ba, U+751f, U+7528, U+767c, U+7684, U+76f8, U+7db2, U+7f8e, U+80fd, U+81ea, U+884c, U+8981, U+958b, U+95dc, U+96fb, U+9801, U+9996, U+ff0c, U+ff1a;
}
.courses-8s .text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.courses-8s .fancy-banner {
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.courses-8s .fancy-banner .cover {
  position: absolute;
  left: 20%;
  bottom: -130px;
  border-style: solid;
  border-width: 1500px 1500px 0px 0px;
  border-right-color: white;
  border-top-color: rgba(0, 0, 0, 0);
}
.courses-8s .fancy-banner .header-featrue {
  position: relative;
  z-index: 1;
}
.courses-8s .orange-leaf,
.courses-8s .green-leaf {
  position: relative;
  z-index: 1;
}
.courses-8s .orange-leaf:before,
.courses-8s .green-leaf:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 130%;
  height: 400%;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.courses-8s .orange-leaf:before {
  background-image: image-url("courses/stages/else/orange_leaf.png");
}
.courses-8s .green-leaf:before {
  background-image: image-url("courses/stages/else/green_leaf.png");
}
.courses-8s .fancy-block {
  position: absolute;
  top: 0;
  right: 2rem;
  height: 100%;
  width: 500%;
}
.courses-8s .dialogue {
  margin: -0.5rem 0rem;
}
.courses-8s .dialogue img, .courses-8s .dialogue span {
  vertical-align: middle;
  display: inline-block;
}
.courses-8s .dialogue span {
  max-width: 280px;
}
.courses-8s .feature-box img {
  display: block;
  margin: 1.5rem auto;
  max-width: 200px;
}

.camp .banner {
  overflow: hidden;
  min-height: 480px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.camp .banner.minecraft {
  background-image: image_url("camps/minecraft/banner.jpg");
}
.camp .banner.app {
  background-image: image_url("camps/app/banner.jpg");
}
.camp .banner.stemkids {
  background-image: image_url("camps/stemkids/banner.jpg");
}
.camp .banner.ai {
  background-image: image_url("camps/ai/header_bg.jpg");
}
.camp .banner.topic {
  background-image: image_url("camps/topic/header_bg.jpg");
}
.camp .banner.iot {
  background-image: image_url("camps/iot/header_bg.jpg");
}
.camp .banner.math {
  background-image: image_url("camps/math/header_bg.jpg");
}
.camp .banner h1, .camp .banner .h1, .camp .banner h2, .camp .banner .h2, .camp .banner p {
  color: white;
  text-shadow: 0px 0px 5px black;
  font-weight: bold;
}
.camp .good-comments {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 1rem;
  color: #555555;
}
.camp .good-comments .comment {
  position: relative;
  padding-left: 70px;
  font-weight: bold;
}
.camp .good-comments .comment > div {
  border-radius: 10px;
}
.camp .good-comments .comment .avator {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
}
.camp .good-comments .comment .stars > img {
  width: 25px;
  margin-right: 5px;
}

.camp-app .camp-stories {
  overflow: hidden;
}
.camp-app .story-image {
  position: relative;
  min-height: 350px;
}
.camp-app .story-image > div {
  position: absolute;
  height: calc(100% + 40px);
  width: calc(100% + 300px);
  top: 0;
}
.camp-app .story-image .bg-pic-1 {
  background-image: image_url("camps/app/pic_1.jpg");
}
.camp-app .story-image .bg-pic-2 {
  background-image: image_url("camps/app/pic_2.jpg");
}
.camp-app .story-image .bg-pic-3 {
  background-image: image_url("camps/app/pic_3.jpg");
}
.camp-app .story-image .bg-pic-4 {
  background-image: image_url("camps/app/pic_4.jpg");
}
.camp-app .story-content {
  padding-top: 50px;
  font-size: 1rem;
  line-height: 2rem;
  color: #646569;
}
.camp-app .story-icon {
  top: 15px;
  display: block;
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 1;
}
.camp-app .story-right .story-content {
  padding-left: 150px;
  position: relative;
}
.camp-app .story-right .story-image > div {
  right: -60px;
  border-radius: 5px;
}
.camp-app .story-right .story-icon {
  left: 15px;
}
.camp-app .story-left .story-content {
  padding-right: 150px;
  position: relative;
}
.camp-app .story-left .story-image > div {
  left: -60px;
  border-radius: 5px;
}
.camp-app .story-left .story-icon {
  right: 15px;
}
@media (max-width: 768px) {
  .camp-app .story-image {
    min-height: 250px;
  }
}
.camp-app .fancy-grid {
  display: inline-flex;
  flex-wrap: wrap;
}
.camp-app .fancy-grid .fancy-block {
  background-color: #ff5959;
  border-radius: 5px;
  width: 170px;
  height: 170px;
  color: #ffffff;
  box-sizing: border-box;
  margin: 2px auto;
}

.camp-minecraft {
  color: #333333;
}
.camp-minecraft .fancy-bg-right, .camp-minecraft .fancy-bg-left {
  position: absolute;
  height: 350px;
  bottom: -250px;
  width: 100vw;
  z-index: -1;
}
.camp-minecraft .fancy-bg-right {
  left: 0px;
}
.camp-minecraft .fancy-bg-left {
  right: 0px;
}
@media (max-width: 768px) {
  .camp-minecraft .fancy-bg-right, .camp-minecraft .fancy-bg-left {
    height: 250px;
    bottom: -80px;
  }
}
.camp-minecraft .fancy-grid {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
.camp-minecraft .fancy-grid .fancy-block {
  background-color: #ffa400;
  border-radius: 5px;
  width: 150px;
  height: 150px;
  color: #ffffff;
  box-sizing: border-box;
  margin: 2px;
}
@media (max-width: 768px) {
  .camp-minecraft .fancy-grid .fancy-block {
    width: 100%;
    height: 100px;
  }
}

.camp-intro .left-part img {
  display: block;
  margin-top: 70px;
}
.camp-intro .right-part {
  font-weight: 900;
}

.camp-image-gallery img {
  height: 200px;
}

.camp-z-map .col-lg-8 {
  transform: translateY(40%);
}
.camp-z-map .col-lg-8 h3, .camp-z-map .col-lg-8 .h3, .camp-z-map .col-lg-8 p {
  font-weight: 900;
}

.endorsement-block {
  background-color: #1360A2;
  color: white;
  padding: 20px 0px;
  margin: 30px 0px;
}
.endorsement-block .review p {
  font-weight: 900;
}
.endorsement-block .highlight {
  color: yellow;
}

#camps-registration .result-detail > .heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  padding-left: 15px;
}
#camps-registration .result-detail > .description {
  line-height: 1.5rem;
  font-size: 16px;
  color: #666;
  padding-left: 15px;
}
#camps-registration .result-detail .students-list {
  margin-top: 10px;
}
#camps-registration .result-detail .students-list table thead {
  background-color: #D9D9D9;
}
#camps-registration .result-detail .students-list table thead th {
  font-size: 16px;
  padding: 5px 15px;
}
#camps-registration .result-detail .students-list table tbody {
  background-color: #F3F3F3;
}
#camps-registration .result-detail .students-list table tbody td {
  padding: 5px 15px;
}
#camps-registration .result-detail .students-list table tbody td .price {
  color: red;
  font-weight: 600;
}

.camps-ai .max-width-860 {
  max-width: 860px;
}
.camps-ai h1, .camps-ai .h1, .camps-ai h2, .camps-ai .h2, .camps-ai h3, .camps-ai .h3 {
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 30px;
}
.camps-ai img {
  width: 100%;
  display: block;
  margin: auto;
}
.camps-ai .px-2 {
  padding-top: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.camps-ai .banner {
  height: 460px;
  position: relative;
}
.camps-ai .banner h1, .camps-ai .banner .h1, .camps-ai .banner h2, .camps-ai .banner .h2 {
  color: #fff;
  position: absolute;
  width: 100%;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
.camps-ai .banner h2, .camps-ai .banner .h2 {
  left: 0px;
  top: 8px;
}
.camps-ai .banner h1, .camps-ai .banner .h1 {
  left: 0px;
  bottom: 10px;
}
.camps-ai .text-green {
  color: #019285;
}
.camps-ai .text-yellow {
  color: #ffa300;
}
.camps-ai .text-blue {
  color: #0b2e44;
}
.camps-ai .bg-green {
  background-color: #e0ebeb;
}
.camps-ai .bg-gray {
  background-color: #eeeeee;
}
.camps-ai .bg-white {
  background-color: #ffffff;
}
.camps-ai .shadow-green {
  box-shadow: 0px 0px 8px rgba(1, 146, 133, 0.3);
}
.camps-ai .five-points img {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: auto;
}
.camps-ai .feature {
  padding: 10px 0px;
}
.camps-ai .feature p {
  padding: 0px 30px;
}
.camps-ai .avatar {
  position: relative;
  margin: 15px 0px;
}
.camps-ai .avatar img {
  width: 170px;
  height: 170px;
  margin: 0;
}
.camps-ai .avatar span {
  position: absolute;
  bottom: 15px;
  left: 175px;
  font-size: 1.142rem;
  font-weight: 900;
}
.camps-ai .avatar-quotation {
  font-size: 1.785rem;
  font-weight: 900;
  position: relative;
}
.camps-ai .avatar-quotation:before {
  background-image: image_url("camps/ai/comma.png");
  background-size: 60px 38px;
  display: inline-block;
  width: 60px;
  height: 38px;
  content: "";
  position: absolute;
  left: 0px;
  top: -40px;
}
.camps-ai .topics {
  width: calc(100% + 60px);
  margin: 30px 0px;
  background-color: rgb(254, 220, 149);
  transform: translateX(-30px);
  max-width: 100vw;
  overflow: hidden;
}
.camps-ai .topics > .row {
  max-width: 950px;
  margin: auto;
}
.camps-ai .topics > .row .red-tag {
  color: #ffffff;
  position: relative;
}
.camps-ai .topics > .row .red-tag::before {
  content: "";
  width: 200%;
  height: calc(100% + 30px);
  transform: translateY(-15px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff5959;
  z-index: -1;
  border-radius: 15px;
}
.camps-ai .topics > .row .portfolio {
  position: relative;
  padding-top: 70%;
}
.camps-ai .topics > .row .portfolio img {
  width: 45%;
  max-width: 45%;
  position: absolute;
}
.camps-ai .topics > .row .portfolio .layer1 {
  top: 10%;
  left: 3%;
}
.camps-ai .topics > .row .portfolio .layer2 {
  top: 6%;
  left: 5%;
  transform: rotate(-9deg) scale(0.81);
  animation: draw-out 4s infinite;
  animation-timing-function: linear;
}
.camps-ai .topics > .row .portfolio .layer3 {
  top: 19%;
  left: 0;
}
.camps-ai .topics > .row .portfolio .shadow {
  width: 55%;
  padding-top: 18%;
  background-color: rgb(245, 207, 131);
  position: absolute;
  top: 85%;
  z-index: -1;
  animation: draw-out-shadow 4s infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}
.camps-ai .fancy-tab {
  width: 100%;
  max-width: 400px;
  margin-top: 25px;
}
.camps-ai .fancy-tab span {
  display: inline-block;
  width: 50%;
  border-top: 2px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px 5px 0px 0px;
  padding: 15px;
  font-size: 1.214rem;
  font-weight: 900;
}
.camps-ai .fancy-tab span.active {
  border-top: 2px solid #019285;
  background-color: #ffffff;
}
.camps-ai .fancy-tab-pane {
  padding: 30px 0px 10px 0px;
}

@keyframes draw-out {
  0% {
    transform: translate(0px, 0px) rotate(-9deg) scale(0.81);
  }
  8% {
    transform: translate(110%, 10%) rotate(12deg) scale(0.81);
  }
  50% {
    transform: translate(110%, 10%) rotate(12deg) scale(0.81);
  }
  58% {
    transform: translate(0px, 0px) rotate(-9deg) scale(0.81);
  }
}
@keyframes draw-out-shadow {
  0% {
    width: 55%;
  }
  8% {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  58% {
    width: 55%;
  }
}
.contact-us {
  background: image_url("contact/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 64px 15px;
}
.contact-us .contact-form {
  max-width: 800px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  margin: 0 auto;
}
.contact-us .contact-form .header, .contact-us .contact-form .contact-form {
  height: 780px;
  padding: 24px;
}
.contact-us .contact-form .header-xs {
  width: 100%;
  height: 126px;
  position: relative;
}
.contact-us .contact-form .header, .contact-us .contact-form .header-xs {
  background-color: orange;
  padding: 30px;
  color: #ffffff;
}
.contact-us .contact-form .header img {
  display: block;
  position: absolute;
  height: 400px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.contact-us .contact-form .header-xs img {
  display: block;
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 126px;
}
@media (max-width: 768px) {
  .contact-us {
    padding: 0;
  }
  .contact-us .contact-form {
    border-radius: 0;
  }
}

.our-vision h1, .our-vision .h1, .our-vision h2, .our-vision .h2, .our-vision h3, .our-vision .h3, .our-vision p, .our-idea h1, .our-idea .h1, .our-idea h2, .our-idea .h2, .our-idea h3, .our-idea .h3, .our-idea p {
  margin: 20px 0px;
}
.our-vision .heading-block, .our-idea .heading-block {
  top: 0;
  bottom: 0;
  left: -100vw;
  right: 0;
  height: 100%;
  background-color: #00c4b3;
}
.our-vision .heading-block.red, .our-idea .heading-block.red {
  background-color: #ff5859;
}
.our-vision .quoted-content, .our-idea .quoted-content {
  margin-top: 24px;
  padding: 30px 15px 15px 15px;
  background-color: #e9e9ea;
  border-radius: 5px;
  line-height: 2rem;
}
.our-vision .quoted-content img, .our-idea .quoted-content img {
  margin-top: 15px;
}
.our-vision .quoted-content:before, .our-idea .quoted-content:before {
  transform: translateY(-48px);
  font-size: 2.5rem;
  position: absolute;
  color: #212529;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f10e";
}

.article-card {
  display: block;
  min-height: 540px;
  border-bottom: 2px solid #ffa400;
  color: #36373a;
  text-decoration: none !important;
}
.article-card .cover-img {
  border: 1px solid #eeeeee;
  width: 100%;
  height: 320px;
  background-size: cover;
  background-position: top center;
}
.article-card .date-block {
  background-color: #ffa400;
  height: 66px;
  width: 66px;
  line-height: 22px;
  text-align: center;
}
.article-card .date-block > .year {
  font-size: 1rem;
  color: #dc8d00;
}
.article-card .date-block > .date {
  font-size: 1.25rem;
  color: #fff;
}
.article-card:hover {
  color: #36373a;
}

.csr-banner-wrapper {
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 100px;
}
.csr-banner-wrapper.csr-index-banner-wrapper {
  background-image: image-url("csr/hole_in_the_wall.jpg");
}
.csr-banner-wrapper.csr-gleaner-banner-wrapper {
  background-image: image-url("csr/picking-seeds.jpg");
}
.csr-banner-wrapper.csr-open-material-banner-wrapper {
  background-image: image-url("csr/holding-books.jpg");
}
.csr-banner-wrapper.csr-training-banner-wrapper {
  background-image: image-url("csr/training-room.jpg");
}

.cert-exam-testing .problem-type {
  font-size: 2.6rem;
}
.cert-exam-testing .problem-description {
  font-weight: 100;
}
.cert-exam-testing .problem-block {
  background-color: #e0ebeb;
  border-radius: 5px;
}
.cert-exam-testing .problem-block .problem-figures {
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
}
.cert-exam-testing .problem-block .problem-figures .problem-figure:not(:last-child) {
  margin-bottom: 1rem;
}
.cert-exam-testing .problem-options input[type=checkbox], .cert-exam-testing .problem-options input[type=radio] {
  vertical-align: top;
  margin-top: 3px;
}

.timeline-left {
  text-align: right;
  position: relative;
}
.timeline-left:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00c4b3;
}
.timeline-left:before {
  position: absolute;
  content: "";
  display: block;
  top: -50px;
  right: -2px;
  width: 4px;
  height: calc(100% + 100px);
  background-color: #00c4b3;
}
@media (max-width: 767.98px) {
  .timeline-left {
    text-align: left;
  }
  .timeline-left:after {
    left: -15px;
  }
  .timeline-left:before {
    left: -2px;
  }
}

.timeline-right {
  position: relative;
}
.timeline-right:before {
  position: absolute;
  content: "";
  display: block;
  top: -50px;
  left: -2px;
  width: 4px;
  height: calc(100% + 100px);
  background-color: #00c4b3;
}

.rating {
  border: none;
  float: left;
}
.rating input {
  display: none;
}
.rating label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f005";
}
.rating .half:before {
  content: "\f089";
  position: absolute;
}
.rating label {
  color: #ddd;
  float: right;
}
.rating input:checked ~ label {
  color: #FFD700;
}
.rating:not(:checked) > label:hover {
  color: #FFD700;
}
.rating:not(:checked) > label:hover ~ label {
  color: #FFD700;
}
.rating input:checked + label:hover, .rating input:checked ~ label:hover {
  color: #FFED85;
}
.rating input:checked label:hover ~ input:checked ~ label, .rating input:checked input:checked ~ label:hover ~ label {
  color: #FFED85;
}

div.bulletin * {
  max-width: 100%;
}
div.bulletin > .card {
  background-color: #f2f2f2;
}

.campaigns-be-smart .header {
  min-height: 500px;
  background-color: #f7b557;
  background-image: image_url("campaigns/be_smart/banner_L.png");
  background-repeat: no-repeat;
  background-position: right center;
}
.campaigns-be-smart .header h1.header-title, .campaigns-be-smart .header .header-title.h1 {
  font-size: 4rem;
  color: #ab191a;
  font-weight: bold;
  margin-bottom: 2rem;
}
.campaigns-be-smart .header p.header-subtitle {
  color: #ab191a;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.campaigns-be-smart .header p.header-description {
  width: 75%;
  color: #36373a;
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .campaigns-be-smart .header {
    padding-top: 1.5rem;
    background-image: none;
  }
  .campaigns-be-smart .header h1.header-title, .campaigns-be-smart .header .header-title.h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .campaigns-be-smart .header p.header-subtitle {
    font-weight: normal;
  }
  .campaigns-be-smart .header p.header-description {
    width: 100%;
  }
}
.campaigns-be-smart .book-quotation {
  margin-top: 3.75rem;
}
.campaigns-be-smart .book-quotation h2.quotation-title, .campaigns-be-smart .book-quotation .quotation-title.h2 {
  padding: 1rem;
  margin: 0;
  background-color: #fcbd4d;
  font-size: 2rem;
}
.campaigns-be-smart .book-quotation p.quotation-content {
  padding: 1.25rem;
  font-size: 1.25rem;
  color: #36373a;
}
@media (max-width: 767px) {
  .campaigns-be-smart .book-quotation {
    margin-top: 1rem;
  }
  .campaigns-be-smart .book-quotation h2.quotation-title, .campaigns-be-smart .book-quotation .quotation-title.h2 {
    font-size: 1.25rem;
    margin-left: -15px;
    margin-right: -15px;
  }
  .campaigns-be-smart .book-quotation p.quotation-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.campaigns-be-smart .grow-ups {
  margin-top: 3rem;
}
.campaigns-be-smart .grow-ups h2.main-title, .campaigns-be-smart .grow-ups .main-title.h2 {
  font-size: 2rem;
  color: #b07203;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5625rem;
}
.campaigns-be-smart .grow-ups h3.grow-up-title, .campaigns-be-smart .grow-ups .grow-up-title.h3 {
  font-size: 1.25rem;
  font-weight: normal;
  color: #36373a;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
}
.campaigns-be-smart .grow-ups p.grow-up-description {
  text-align: center;
  font-size: 1rem;
  color: #b07203;
  line-height: 1.875rem;
}
.campaigns-be-smart .grow-intelligence {
  margin-top: 3rem;
  margin-bottom: 20px;
}
.campaigns-be-smart .grow-intelligence h2.main-title, .campaigns-be-smart .grow-intelligence .main-title.h2 {
  font-size: 2rem;
  color: #b07203;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5625rem;
}
.campaigns-be-smart .grow-intelligence p.grow-intelligence-description {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #36373a;
}
.campaigns-be-smart .grow-intelligence .container > .row:nth-child(2) {
  position: relative;
}
.campaigns-be-smart .grow-intelligence .backdrop {
  position: absolute;
  left: -50vw;
  right: 110px;
  top: 0;
  bottom: -20px;
  background-color: #fef2e1;
}
@media (max-width: 767px) {
  .campaigns-be-smart .grow-intelligence p.grow-intelligence-description {
    padding-top: 2rem;
  }
  .campaigns-be-smart .grow-intelligence .backdrop {
    left: 0;
    right: 0;
    bottom: 170px;
  }
}
.campaigns-be-smart .train-skills {
  margin-top: 4rem;
}
.campaigns-be-smart .train-skills p.train-skills-description {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #36373a;
}
.campaigns-be-smart .train-skills p.train-skills-description > span.highlight {
  font-weight: bold;
  color: #b07203;
}
.campaigns-be-smart .train-skills h3.skill-title, .campaigns-be-smart .train-skills .skill-title.h3 {
  margin-top: 1.5675rem;
  font-size: 1.25rem;
  font-weight: normal;
  color: #b07203;
  text-align: center;
}
@media (max-width: 767px) {
  .campaigns-be-smart .train-skills {
    margin-top: 1.5rem;
  }
  .campaigns-be-smart .train-skills h3.skill-title, .campaigns-be-smart .train-skills .skill-title.h3 {
    margin-top: 1.125rem;
  }
}
.campaigns-be-smart .features {
  margin-top: 4rem;
  background-color: #fef2e1;
}
.campaigns-be-smart .features h2.main-title, .campaigns-be-smart .features .main-title.h2 {
  margin-top: 4rem;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #646569;
}
.campaigns-be-smart .features h3.feature-title, .campaigns-be-smart .features .feature-title.h3 {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  color: #646569;
}
.campaigns-be-smart .features p.feature-subtitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #646569;
}
.campaigns-be-smart .features p.feature-description {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.875rem;
  color: #646569;
}
@media (max-width: 767px) {
  .campaigns-be-smart .features h2.main-title, .campaigns-be-smart .features .main-title.h2 {
    font-size: 2rem;
  }
  .campaigns-be-smart .features h3.feature-title, .campaigns-be-smart .features .feature-title.h3 {
    margin-bottom: 0.75rem;
  }
}
.campaigns-be-smart .get-started {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.campaigns-be-smart .get-started h2.get-started-title, .campaigns-be-smart .get-started .get-started-title.h2 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: bold;
  color: #646569;
}
.campaigns-be-smart .get-started p.get-started-subtitle {
  font-size: 1.25rem;
  color: #646569;
}
.campaigns-be-smart .get-started .form-wrapper {
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 0 8px 5px rgba(170, 170, 170, 0.5);
}
@media (max-width: 767px) {
  .campaigns-be-smart .get-started {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .campaigns-be-smart .get-started .backdrop {
    padding-top: 2rem;
    background-color: #fcbd4d;
  }
  .campaigns-be-smart .get-started h2.get-started-title, .campaigns-be-smart .get-started .get-started-title.h2 {
    font-size: 2rem;
  }
  .campaigns-be-smart .get-started .form-wrapper {
    padding: 2rem 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.header {
  height: 50px;
}
.header nav {
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
  background-color: #fff;
}
.header nav.nav-up {
  top: -200px;
}
.header a.dropdown-item, .header input.dropdown-item {
  color: #ffa400;
}
.header a.dropdown-item:hover, .header input.dropdown-item:hover {
  color: #ffffff;
}

@media (min-width: 1200px) {
  .header {
    height: 120px;
  }
  .header nav {
    height: 120px;
  }
}
.btn-orange {
  color: #fff;
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

footer.footer {
  left: 0px;
  bottom: 0px;
  width: 100%;
}
footer.footer .contact-info, footer.footer .location-info {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
}
footer.footer .contact-info td, footer.footer .location-info td {
  vertical-align: top;
  padding-bottom: 8px;
}
@media (max-width: 992px) {
  footer.footer .location-info {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
footer.footer .orange-line {
  padding-left: 20px;
  position: relative;
}
footer.footer .orange-line::before {
  content: "";
  position: absolute;
  background-color: orange;
  top: 9px;
  left: 0;
  height: calc(100% - 9px);
  width: 2px;
}
footer.footer .orange-line::after {
  content: "";
  position: absolute;
  top: 9px;
  left: -9px;
  width: 20px;
  height: 20px;
  background-image: image-url("footer/orange.png");
  background-size: 20px 20px;
}

#site-loader {
  display: none !important;
}

.fixed-buttons-wrapper {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 1040;
}
.fixed-buttons-wrapper .btn {
  font-size: 18px;
  border-radius: 99999999px 0 0 99999999px;
  box-shadow: 0 0 10px 0 #aaaaaa;
}
@media (max-width: 768px) {
  .fixed-buttons-wrapper .btn {
    border-radius: 99999999px;
  }
}
.fixed-buttons-wrapper .btn-danger {
  background-color: #ff5859;
  border-color: #ff5859;
}
.fixed-buttons-wrapper .btn-danger:hover {
  color: #eee;
}
.fixed-buttons-wrapper .btn-warning {
  background-color: #fea200;
  border-color: #fea200;
}
.fixed-buttons-wrapper .btn-warning:hover {
  color: #eee;
}
.fixed-buttons-wrapper .koding-images {
  position: relative;
}
.fixed-buttons-wrapper .koding-images > .desktop-version {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 100px;
  width: 100px;
  background-image: image-url("koding-flag-left.png");
  background-size: cover;
  background-position: center center;
}
@media (max-width: 767px) {
  .fixed-buttons-wrapper .koding-images > .desktop-version {
    display: none;
  }
}
.fixed-buttons-wrapper .koding-images > .mobile-version {
  height: 100px;
  width: 100px;
  background-image: image-url("koding-point-to-right.png");
  background-size: cover;
  background-position: center center;
  display: none;
  float: left;
}
@media (max-width: 767px) {
  .fixed-buttons-wrapper .koding-images > .mobile-version {
    display: block;
  }
}
@media (max-width: 767px) {
  .fixed-buttons-wrapper {
    width: 100%;
    bottom: 0;
    padding-left: 8px;
    padding-right: 8px;
  }
  .fixed-buttons-wrapper .buttons {
    margin-left: 110px;
  }
}
.fixed-buttons-wrapper:hover .koding-images > .desktop-version {
  animation-name: koding-swing-flag;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(1, end);
}

.parent-image {
  max-width: 555px;
}
@media (max-width: 767px) {
  .parent-image {
    max-width: 100%;
  }
}

.parent-text-wrapper {
  position: absolute;
  bottom: -60px;
  right: 60px;
}
@media (max-width: 767px) {
  .parent-text-wrapper {
    position: relative;
    bottom: 0;
    right: 0;
  }
}

.parent-image-text {
  width: 100%;
  max-width: 555px;
  background-color: gray;
}
@media (max-width: 767px) {
  .parent-image-text {
    max-width: 100%;
  }
}

.courses-stages table th {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-top: 2px solid #ffa400;
  border-bottom: 2px solid #ffa400;
}
.courses-stages table tr td {
  vertical-align: middle;
}
.courses-stages .course-item {
  border-width: 1px 0px;
  border-style: solid;
  border-color: #eeeeee;
}

.news-hidden.bg-light {
  display: block;
}

.select2-container--bootstrap {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.text-orange {
  color: #ffa400 !important;
}

.text-green {
  color: #00c4b3 !important;
}

.text-red {
  color: #ff5959 !important;
}

.bg-red {
  background-color: #ff5959 !important;
}

.bg-orange {
  background-color: #ffa400 !important;
}

.bg-green {
  background-color: #00c4b3 !important;
}

.bg-green-deep {
  background-color: #098a7f !important;
}

.btn.btn-red {
  color: #ffffff;
  background-color: #ff5959;
  border-color: #ff5959;
}
.btn.btn-red:hover {
  color: #ffffff;
  background-color: #ff3333;
  border-color: #ff2626;
}

.btn.btn-orange {
  color: #ffffff;
  background-color: #ffa400;
  border-color: #ffa400;
}
.btn.btn-orange:hover {
  color: #ffffff;
  background-color: #d98b00;
  border-color: #cc8300;
}

.btn.btn-green, .btn-green {
  color: #ffffff;
  background-color: #00c4b3;
  border-color: #00c4b3;
}
.btn.btn-green:hover, .btn-green:hover {
  color: #ffffff;
  background-color: #009e90;
  border-color: #009184;
}

.text-muted {
  color: #6c757d !important;
  text-decoration: none;
}

a.text-muted:hover {
  text-decoration: underline;
}

.text-red-dark {
  color: #ab191a !important;
}

a.text-red-dark:hover, a.text-red-dark:focus {
  color: #680f10 !important;
}

.bg-red-light {
  background-color: #f6e8e9 !important;
}

a.bg-red-light:hover, a.bg-red-light:focus, button.bg-red-light:hover, button.bg-red-light:focus {
  background-color: #e8c3c6 !important;
}

.bg-red-dark {
  background-color: #ab191a !important;
}

a.bg-red-dark:hover, a.bg-red-dark:focus, button.bg-red-dark:hover, button.bg-red-dark:focus {
  background-color: #7f1213 !important;
}

.bg-green-light {
  background-color: #e0ebeb !important;
}

a.bg-green-light:hover, a.bg-green-light:focus, button.bg-green-light:hover, button.bg-green-light:focus {
  background-color: #c1d7d7 !important;
}

.text-green-dark {
  color: #074746 !important;
}

a.text-green-dark:hover, a.text-green-dark:focus {
  color: #000101 !important;
}

.bg-orange-fade {
  background-color: #fcbd4d !important;
}

a.bg-orange-fade:hover, a.bg-orange-fade:focus, button.bg-orange-fade:hover, button.bg-orange-fade:focus {
  background-color: #fbaa1b !important;
}

.text-orange-fade {
  color: #fcbd4d !important;
}

/* line 17, ../../.rvm/gems/ruby-2.5.5/gems/bootstrap-4.5.0/assets/stylesheets/bootstrap/mixins/_hover.scss */
a.text-orange-fade:hover, a.text-orange-fade:focus {
  color: #f8a004 !important;
}

.text-blue-deep {
  color: #2e6587 !important;
}

a.text-blue-deep:hover, a.text-blue-deep:focus {
  color: #1b3a4e !important;
}

.bg-orange-light {
  background-color: #fef2e1 !important;
}

a.bg-orange-light:hover, a.bg-orange-light:focus, button.bg-orange-light:hover, button.bg-orange-light:focus {
  background-color: #fcddb0 !important;
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
}

.hidden {
  display: none;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
/* ============ desktop view .end// ============ */
/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
/* ============ small devices .end// ============ */
@media (max-width: 512px) {
  .navbar-nav.ms-auto {
    overflow: scroll;
  }
}
